import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link }  from 'react-router-dom';
import Lottie from 'react-lottie';
import ScrollAnimation from 'react-animate-on-scroll';
import Ticker from "../common/Ticker";
import { Waypoint } from 'react-waypoint';
import { Carousel } from 'react-bootstrap'
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";

// Animation files
import topAnimation from '../../animation/topAnimation.json';
import bottomAnimationOne from '../../animation/bottomAnimationOne.json';
import bottomAnimationTwo from '../../animation/bottomAnimationTwo.json';
import complianceChecklistAnimation from '../../animation/complianceChecklistAnimation.json';
import appointmentChecklistAnimation from '../../animation/appointmentChecklistAnimation.json';
import copyAnnasHealthAnimation from '../../animation/copyAnnasHealthAnimation.json';
import copyAnnasHealthAnimationTablet from '../../animation/Copy-Chart-Note-Button-Tablet.json';
import copyAnnasHealthAnimationMobile from '../../animation/Copy-Chart-Note-Button-Mobile.json';
import appointmentPlanningAnimation from '../../animation/appointment_planning.json';
import yogaAnimation from '../../animation/yoga.json';
import medicationAnimation from '../../animation/medication.json';
import learnAnimation from '../../animation/learn.json';
import lifestyleAnimation from '../../animation/lifestyle.json';
import messageAnimation from '../../animation/message.json';
import questionnaireAnimation from '../../animation/questionnaires.json';
import symptomAnimation from '../../animation/symptoms.json';
import vitalsAnimation from '../../animation/vitals.json';

@inject('globalStore')

@observer class Orthopedic extends Component {
  constructor(props) {
    super(props);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.state = {
      pageScrollBar: null,
      stickyBar: null,
      month: months[new Date().getMonth()],
      renderComplianceChecklistsLottie: false,
      renderAppoitmentChecklistLottie: false,
      rendercopyButton: false,
      changeStickyBar: true,
      lastId: null,
      curr: [],
      indexAnimationArr: [0,0,0,0,0,0,0,0],
      indexArr: [0,0,0,0,0,0,0,0],
      startBootomLoop: false,
      isStickyOnce: false,
      isScrollDirectionUp: false,
      selectedIndex: 0,
    };
  }

  componentDidMount() {
    document.title= 'Clinical Care Pathways for Orthopedics - DoctorPlan';
    let pageScrollBar = document.getElementById("pageScrollBar");
    this.setState({
      pageScrollBar,
      stickyBar: this.getPositionY(pageScrollBar),
    });  
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() { 
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  // getCalendar(daysAdded) {
  //   var today = new Date();
  //   var newCalendar = new Date(new Date().setDate(today.getDate() + daysAdded));

  //   var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   return {
  //     month: months[newCalendar.getMonth()],
  //     date: newCalendar.getDate(),
  //   };
  // }

  getPositionY(element) {
    var yPosition = 0;
    // add up all parent's offsets to find distance from body
    while(element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }
    return yPosition;
  }


  activeBottomNavigation = () => {
    const { selectedIndex, isScrollDirectionUp } = this.state;
    let mainNavLinks = document.querySelectorAll("#pageScrollBar div a");
    let fromTop = window.scrollY;

    mainNavLinks.forEach((link, index) => {
      let section = document.querySelector(link.hash);
      if (
        section.offsetTop - (section.offsetHeight * 0.005) <= fromTop &&
        section.offsetTop + (section.offsetHeight * 0.995) > fromTop
      ) {
        link.classList.add("active");

        // for active animation index
        const newId = this.state.indexAnimationArr.slice();
        newId[index] = 1;
        this.setState({indexAnimationArr: newId});

        // for all active states
        const activeId = this.state.indexArr.slice();
        for(let i = 0; i <= index; i++) {
          activeId[i] = 1;
        }
        this.setState({indexArr: activeId, selectedIndex: index});
      } else {
        link.classList.remove("active");

        // for active animation index
        const newId = this.state.indexAnimationArr.slice();
        newId[index] = 0;
        this.setState({indexAnimationArr: newId});

        if(!isScrollDirectionUp) {
          // for all active states
          const activeId = this.state.indexArr.slice();
          for(let i = selectedIndex; i <= mainNavLinks.length; i++) {
            activeId[i] = 0;
          }
          this.setState({indexArr: activeId});
        }
      }
    });
  }

  scrollDirection = () => {
    const { scrollPos } = this.state;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > scrollPos)
      this.setState({ isScrollDirectionUp: true });
    else
      this.setState({ isScrollDirectionUp: false });

    this.setState({ scrollPos: scrollTop <= 0 ? 0 : scrollTop }) ;
  }

  handleScroll = () => {
    const { isStickyOnce } = this.state;

    if (!isStickyOnce) {
      const viewpointHeight = document.documentElement.clientHeight;
      const pageScrollBarHeight = this.state.pageScrollBar.getBoundingClientRect().height;
      if (window.pageYOffset <= this.state.stickyBar - viewpointHeight + pageScrollBarHeight) {
        this.state.pageScrollBar.classList.add("stickyBar");
        this.setState({changeStickyBar: true});

      } else {
        this.state.pageScrollBar.classList.remove("stickyBar");
        this.setState({changeStickyBar: false, isStickyOnce: true});
      }
      this.activeBottomNavigation();
      this.scrollDirection();
    }
  }

  onCompleteBottomAnimation = () => {
    this.setState({ startBootomLoop: true });
    console.log('yes');
  }

  render() {
    let {
      renderAppoitmentChecklistLottie,
      changeStickyBar,
      rendercopyButton,
      indexAnimationArr,
      indexArr,
      startBootomLoop,
    } = this.state;
    const topAnimationOptions = {
      loop: true,
      autoplay: true,
      animationData: topAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const bottomAnimationOptionsOne = {
      loop: false,
      autoplay: true,
      animationData: bottomAnimationOne,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const bottomAnimationOptionsTwo = {
      loop: true,
      autoplay: true,
      animationData: bottomAnimationTwo,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const complianceChecklistOptions = {
      loop: false,
      autoplay: true,
      animationData: complianceChecklistAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const appointmentChecklistOptions = {
      loop: false,
      autoplay: true,
      animationData: appointmentChecklistAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationTabletOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimationTablet,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationMobileOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimationMobile,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const appointmentPlanningAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: appointmentPlanningAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const yogaAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: yogaAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const medicationAnimationOptions = {
        loop: false,
        autoplay: true,
        animationData: medicationAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const learnAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: learnAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const lifestyleAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: lifestyleAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const messageAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: messageAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const questionnaireAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: questionnaireAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const symptomAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: symptomAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const vitalsAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: vitalsAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div>
        <section className="timelineHeader">
          <div className="container-lg">
          { window.innerWidth > 767 ?
            <div className="timeline-row row align-items-center justify-content-center mx-5">
            <div className="col-lg-5 col-md-4 p-0">
              <div className="row p-0">
                <div className="col p-0 my-2">
                  <div className="headerCalendar text-center">
                    <h1 className="month">{this.state.month}</h1>
                    <p class="day">25</p>
                  </div>
                </div>
                <div className="col-10 px-2">
                  <h3>New Knee Consult</h3>
                  <p className="heading-desc">DoctorPlan is pre-configured with best practice clinical care pathways - an example for a “New Knee Consult” appointment is below. It includes information sent to the patient, as well as health data collected from the patient prior to the visit.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <Lottie options={topAnimationOptions} />
            </div>
          </div> :
          <>
            <div className="timeline-row row align-items-center justify-content-center mx-5">
              <div className="col-sm-12 px-3">
                <Lottie options={topAnimationOptions} />
              </div>
            </div>
            <div className="timeline-row row align-items-center justify-content-center mx-5 pt-5">
              <div className="col-sm-12 p-0">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-3 p-0 mb-4 mr-20">
                    <div className="headerCalendar text-center">
                      <h1 className="month">{this.state.month}</h1>
                      <p class="day">25</p>
                    </div>
                  </div>
                  <h3 className="col text-left vertical-center p-0 mb-4">New Knee Consult</h3>
                  <p className="heading-desc text-left">DoctorPlan is pre-configured with best practice clinical care pathways - an example for a “New Knee Consult” appointment is below. It includes information sent to the patient, as well as health data collected from the patient prior to the visit.</p>
                </div>
              </div>
            </div>
          </>
          }
          </div>
        </section>

        <section className="timelineBody">
          <div className="ml-sm-4 ml-lg-5">
            <ul class="timeline ml-lg-5">
              <li>
                <div className="oval"></div>
              </li>
              <li id="section-1" className="clinical-care">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">4</p>
                  <p className="time">11:00 AM</p>
                </div>
                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0">
                    <div className="clinicalCare timeline-wrapper">
                      <div class="timeline-badge message"></div>
                      <h4 class="timeline-heading">Clinical Care Pathways</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Make it easy for your patients to be guided along their entire healthcare journey – from the first call to recovery. The DoctorPlan app helps your patients maintain a digital health diary, and share information with you, so you are with them every step of the way.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel">
                      <ScrollAnimation delay={0} duration={0.8} animateOnce={true} animateIn="rotateInUpLeft">
                        <div className="speechBox">
                          <h3 class="timeline-heading">Welcome to the Orthopedic Care Center!</h3>
                          <div class="timeline-body">
                            { window.innerWidth > 991 ?
                              <p className="m-0">You’ve been scheduled for a New Knee Consult. We use DoctorPlan to help you prepare for your consultation and guide you through your knee care journey. Over the next few weeks, you will receive timely information about what to expect, instructions along each step of the care plan, prehab exercises to help you strengthen your knee area, and intake and follow up questionnaires – these help you and your doctor collaborate on your treatment plan and stay on top of your health.</p> :
                              <p className="m-0">You’ve been scheduled for a New Knee Consult. We use DoctorPlan to help you prepare for your consultation and guide you through your knee care journey. Over the next few weeks…</p>
                            }
                          </div>
                        </div>
                        <p className="seenByAnna">Seen by Emma
                          <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-2" className="patient-edu">                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0">
                    <div className="patientEdu timeline-wrapper">
                      <div class="timeline-badge learn"></div>
                      <h4 class="timeline-heading">Patient Education and Engagement</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Educate your patients with the right information at the right time. Automatically deliver easy to read articles on diagnostic imaging, treatment options – surgical, non-surgical; physical therapy, pre operative and post operative care.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel-2">
                      <Carousel interval={3000}>
                        <Carousel.Item>
                          <h3 class="timeline-heading">Knee Arthroscopy</h3>
                          <div>
                            <img src={require("../../img/1-overview-of-knee-arthroscopy-01@3x.jpg")} className="img-fluid" alt="" />
                          </div>
                          <div class="timeline-body">
                            <p>A knee arthroscopy allows your surgeon to examine your knee joint without making a large cut through the skin and soft tissue as they might in other, more invasive, knee...</p>
                          </div>
                          <p className="seenByAnna">Seen by Emma
                            <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                          </p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <h3 class="timeline-heading">Recovery After Knee Arthroscopy</h3>
                            <div>
                              <img src={require("../../img/4-non-surgical-treatment-02@3x.jpg")} className="img-fluid" alt="" />
                            </div>
                            <div class="timeline-body">
                              <p>Recovery from a knee arthroscopy depends on many different factors. While your surgery center or hospital stay may be short, your overall recovery will take time. Although the incisions...</p>
                            </div>
                            <p className="seenByAnna">Seen by Emma
                              <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                            </p>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-3" className="intake-questionnaires">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">11</p>
                  <p className="time">1:00 PM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-10 col-sm-11 p-0">
                    <div className="intakeQuestionnaires timeline-wrapper">
                      <div class="timeline-badge questionnaires"></div>
                      <h4 class="timeline-heading">Intake Questionnaires</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Ask your patients to fill out interactive questionnaires in the comfort of their home – where they have the required information, and time to gather the pieces they don’t. 
                          <br></br><br></br>
                          Leverage best practice questionnaires including Knee Pain Intake, Knee Replacement Follow Up, Medical History, Past Diagnostic and Treatment History, Surgery Follow Up, Post Op, and Review of Systems.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-10 center-col">
                    <div class="timelinePanel-3">
                      <Carousel interval={3000}>
                        <Carousel.Item>
                        <h3><img src={require("../../img/group-71.svg")} className="img-fluid" alt="" />Body Pain Treatments / Diagnosis History</h3>
                          <div class="timeline-body row">
                            <div className="col p-0">
                              <img src={require("../../img/Orthopedics-Diagnosis History-1@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                            <div className="col p-0">
                              <img src={require("../../img/Orthopedics-Diagnosis History-2@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <h3><img src={require("../../img/group-71.svg")} className="img-fluid" alt="" />Knee Condition Intake</h3>
                          <div class="timeline-body row">
                            <div className="col p-0">
                              <img src={require("../../img/Orthopedics-Knee Condition Intake-1@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                            <div className="col p-0">
                              <img src={require("../../img/Orthopedics-Knee Condition Intake-2@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-4" className="pre-post-excercise">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">16</p>
                  <p className="time">11:00 AM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0"> 
                    <div className="prePostExcercise timeline-wrapper">
                    <div class="timeline-badge excercise"></div>
                      <h4 class="timeline-heading">Pre and Post Care Exercises</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Ensure your patients stay on top of their treatment and any post operative care plan they may have, with pre-recorded physical therapy. Each video guides the patient through a different strengthening or mobility exercise including the correct form and movement.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel-10">
                      <h3 class="timeline-heading">Exercise Session</h3>
                      <h4 className="sub-heading">Hamstring Contraction</h4>
                      <div>
                        <img src={require("../../img/exercise-video-image@3x.jpg")} className="img-fluid" alt="" />
                      </div>
                      <div class="timeline-body">
                        <div className="row vertical-center">
                          <div className="col p-0 text-center"><img src={require("../../img/icon-hamstring.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 text-center"><img src={require("../../img/icon-duration.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 text-center"><img src={require("../../img/icon-resting.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 text-center"><img src={require("../../img/icon-low.svg")} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="row vertical-center">
                          <div className="col p-0 text-center"><p>Hamstring</p></div>
                          <div className="col p-0 text-center"><p>2 Min.</p></div>
                          <div className="col p-0 text-center"><p>Resting</p></div>
                          <div className="col p-0 text-center"><p>Low</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="lifestyle-vitals">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">16</p>
                  <p className="time">11:00 AM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="lifestyleVitals timeline-wrapper">
                      <h4 class="timeline-heading">Symptoms, Vitals and Lifestyle Tracking</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Encourage your patients to log their symptoms as they experience them - frequency, severity, and relevant details – to help inform your treatment plan.  Track vitals, mood, sleep and stress for a complete picture.
                          <br></br><br></br>
                          Included is a medication diary with dosage and schedule the patient takes the medication, so you can correlate objective and subjective data to optimize their care plan.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li id="section-5" className="symptom">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header symptoms"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#e28816"}}> <img src={require("../../img/symptomsicon_knee_pain_uni.svg")} className="img-fluid" alt="" />Knee Pain</p>
                      </div>
                      <div class="timeline-body mx-auto comp-1">
                        <p>1. How would you describe it?</p>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Burning</div>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">sharp</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Dull</div>
                          </div>
                          <div className="col center-col p-0">
                              <ScrollAnimation delay={0} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                                <div className="options">Stiffness</div>
                              </ScrollAnimation>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={1000} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">Throbbing</div>
                            </ScrollAnimation>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">Deep Aching</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Sore</div>
                          </div>
                          <div className="col center-col p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#e28816"}}> <img src={require("../../img/symptomsicon_swollen_knee_uni.svg")} className="img-fluid" alt="" />Swelling in Knee</p>
                      </div>
                      <div class="timeline-body mx-auto comp-1">
                        <p>2. What is the pattern of the swelling and how long does it last each time?</p>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Unpredictable</div>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">During Movement</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={0} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">Morning</div>
                            </ScrollAnimation>
                          </div>
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={1000} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">All Day</div>
                            </ScrollAnimation>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Few Hours</div>
                          </div>
                          <div className="col center-col p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-6" className="vital">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header vitals"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#709f34"}}> <img src={require("../../img/group-26.svg")} className="img-fluid" alt="" />Weight</p>
                      </div>
                      <div class="timeline-body row mx-auto vertical-center comp-2 number-counter" >
                        <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                        <div className="col p-0 count">
                          <Ticker start={0} end={180} duration={2}/>
                          <div>lbs</div>
                        </div>
                        <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#709f34"}}> <img src={require("../../img/blood-pressure.svg")} className="img-fluid" alt="" />Blood Pressure</p>
                      </div>
                      <div class="timeline-body mx-auto pb-5">
                        <div className="row mx-auto vertical-center mb-4 comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={120} end={147} duration={2}/>
                            <div>SYS</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="row mx-auto vertical-center mb-4 comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={80} end={95} duration={2}/>
                            <div>DIA</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="row mx-auto vertical-center comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={80} end={72} duration={2}/>
                            <div>bpm</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-7" className="medicationsAdherence">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header medications"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#c45531"}}> <img src={require("../../img/group-133.svg")} className="img-fluid" alt="" />Medications</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-4">
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Lipitor 20 mg -<p className="mx-1">All Days</p></div>
                        </div>
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Plavix 300 mg -<p className="mx-1">All Days</p></div>
                        </div>
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Gabapentin 600 mg -<p className="mx-1">All Days</p></div>
                        </div>
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Iron Supplement 65 mg -<p className="mx-1">As Needed</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#c45531"}}> <img src={require("../../img/group-133.svg")} className="img-fluid" alt="" />Adherence</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-4">
                        <div className="row py-2 text-left vertical-center">
                          <div className="col p-0 meds-description"></div>
                          <div className="col p-0 meds-dosage">
                          <div className="row text-center p-0">
                              <div className="col px-1 meds-label">Always had</div>
                              <div className="col px-1 meds-label">Missed a few</div>
                              <div className="col px-1 meds-label">Never had</div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Lipitor 20 mg, 7 days</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-orange-exclamation.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-red-cross.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Plavix 300 mg, 7 days</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/default-green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/group-111-2.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-red-cross.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Gabapentin 600 mg, 7 days</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/default-green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/group-111-2.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-red-cross.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Iron Supplement 65 mg</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/default-green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-orange-exclamation.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/group-110.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-8" className="appointment-planning">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">23</p>
                  <p className="time">6:00 PM</p>
                </div>                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="appointmentPlanning timeline-wrapper">
                      <div class="timeline-badge appointment"></div>
                      <h4 class="timeline-heading">Appointment Planning</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Guide your patients to prepare for their appointment – with suggested topics of discussion, all specific to their orthopedic care. 
                          <br></br><br></br>
                          Your patients can choose from relevant topics associated with symptoms, causes, diagnosis / treatment, medications, and lifestyle. You are better prepared for the visit and cover areas important to your patients.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-6 mx-auto" style={{ marginTop: "23px"}}>
                      <div class="timeline-heading overlap text-left">
                        <p style={{color:"#e28816"}} className="p-0"> <img src={require("../../img/group.svg")} className="img-fluid" alt="" />Symptoms Summary</p>
                      </div>
                      <div class="timeline-body">
                        <div className="summary">
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/symptomsicon_knee_pain_uni.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Knee Pain</div>
                            <div className="col-4 des">8 times</div>
                          </div>
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/symptomsicon_swollen_knee_uni.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Swelling in Knee</div>
                            <div className="col-4 des">4 times</div>
                          </div>
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/fatigue_tiredness.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Fatigue</div>
                            <div className="col-4 des">2 times</div>
                          </div>
                        </div>
                        <p className="details-head"><span className="bold">Topics</span> (to discuss with doctor)</p>
                        <div className="row vertical-center details border-bottom pb-4">
                          <div className="col p-0"> Which activities should I avoid so I don’t make my condition worse?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                              { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row vertical-center details py-4">
                          <div className="col p-0">Will I require surgery, and if so, how long is the recovery?</div>
                          <div className="col-3">
                            <div className="button vertical-center">SELECT</div>
                          </div>
                        </div>
                        <div className="row vertical-center">
                          <div className="col-lg-10 col-md-9 col-sm-10 p-3 typing vertical-center">
                            <ScrollAnimation delay={0} duration={1.8} animateOnce={true} animateIn="type-steps">
                            How can I manage my pain on a daily basis?
                            </ScrollAnimation>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-2 p-3 typing text-center">ADD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-6 diagnostic mx-auto">
                      <div class="timeline-heading text-center">
                        <p style={{color:"#2d3e4f"}}> <img src={require("../../img/appointment-planning-icon-diagnosis.svg")} className="img-fluid" alt="" />Diagnosis / Treatment</p>
                      </div>
                      <div class="timeline-body"> 
                        <p className="p-0 section-related">This section relates to topics you may want to discuss about diagnostic tests and procedures, as well as the various treatment options.</p>                       
                        <p className="details-head"><span className="bold">Topics</span> (to discuss with doctor)</p>
                        <div className="row details border-bottom py-3">
                          <div className="col p-0">How do I know if I have a sprain or a broken bone?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                                { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row details vertical-center border-bottom py-3">
                          <div className="col p-0">How do I know if I have arthritis?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                                { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row details vertical-center py-3">
                          <div className="col p-0">Is diagnostic testing for an orthopedic injury safe for pregnant women?</div>
                          <div className="col-3">
                            <div className="button vertical-center">SELECT</div>
                          </div>
                        </div>
                        <div className="row vertical-center py-2">
                          <div className="col p-3 typing disabled vertical-center">Type in your own topic</div>
                          <div className="col-lg-3 col-md-3 col-sm-2 p-3 typing disabled text-center">ADD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="pb-0 print-facesheet">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">25</p>
                  <p className="time">11:00 AM</p>
                </div>
                <p className="comment">Appointment Day</p>                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="printFacesheet timeline-wrapper">
                      <div class="timeline-badge facesheet"></div>
                      <h4 class="timeline-heading">Print Facesheet</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Use the 1-page synopsis generated for you prior to the patient visit – almost as if you knew everything they’ve gone through over the last few months. 
                         <br></br><br></br>Quickly review their chief complaints and history of present illness (HPI), along with all the questionnaire responses – Knee Pain Intake, Knee Replacement Follow Up, Medical History, Past Diagnostic and Treatment History, Review of Systems, and much more.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li class="py-5 print-facesheet">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-10">
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={0} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                            <div class="timeline-heading text-left" style={{backgroundColor:"#dcecfa"}}>
                              <div style={{color:"#2d3e4f"}}> <img src={require("../../img/fill-1.svg")} className="img-fluid" alt="" />Appointment Planning</div>
                            </div>
                            <div class="timeline-body appointment-list text-left py-3">
                              <h3>Topics of Discussion</h3>
                              <ul>
                                <li><span>Which activities should I avoid so I don’t make my condition worse?</span></li>
                                <li><span>How do I know if I have a sprain or a broken bone?</span></li>
                                <li><span>How do I know if I have arthritis?</span></li>
                                <li><span>How can I manage my pain on a daily basis?</span></li>
                              </ul>
                            </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={200} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#feeed9"}}>
                            <div style={{color:"#e28816"}}> <img src={require("../../img/fill-1-1.svg")} className="img-fluid" alt="" />Symptoms</div>
                          </div>
                          <div class="timeline-body pt-3">
                            <div className="summary">
                              <div className="row py-1 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/knee-pain-copy.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Knee Pain x 8</div>
                                  <div className="py-1">Severity – 5, Throbbing, Stiffness , Inside Joint Pain, Feels Constant, Trigger – Everyday Activity, Squatting / Kneeling</div>
                                </div>
                              </div>
                              <div className="row py-1 pt-2 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/knee-swelling-copy.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Knee Swelling x 2</div>
                                  <div className="py-1">Severity – 8, Joint Sounds While Moving, Stiffness, Trigger – Physical Activity, Relief through Ice / Heat Therapy, Over Counter Meds, and Inactivity</div>
                                </div>
                              </div>
                              <div className="row py-1 text-left">
                                <div className="col-1 p-0"><img src={require("../../img/fatigue-copy.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Fatigue x 2</div>
                                  <div className="py-1">Severity – Moderate, Comes and Goes, Trigger – Sleep Disturbances, Excessive Physical Activity</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={400} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#edf8df"}}>
                            <div style={{color:"#709f34"}}> <img src={require("../../img/group-29-copy.svg")} className="img-fluid" alt="" />Vitals</div>
                          </div>
                          <div class="timeline-body py-3">
                            <div className="summary">
                              <div className="row py-1 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/weight.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col p-0 px-3">
                                  <div className="heading">Weight   -   180.4 lbs</div>
                                  <div>(Avg. 6 entries - 179.5 lbs - 180.6 lbs)</div>
                                </div>
                              </div>
                              <div className="row py-1 pt-2 text-left">
                                <div className="col-1 p-0"><img src={require("../../img/group-111.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col p-0 px-3">
                                  <div className="heading">Blood Pressure - 147 SYS / 95 DIA</div>
                                  <div >(Avg. 4 entries - 143 - 149 SYS / 94 - 96 DIA)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-10">
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={600} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#faf5fc"}}>
                            <div style={{color:"#833e93"}}> <img src={require("../../img/combined-shape.svg")} className="img-fluid" alt="" />Medical History Questionnaire</div>
                          </div>
                          <div class="timeline-body py-3 summary">
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Pain Since Last Visit</div>
                                <div>Gotten Worse</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">New Treatment</div>
                                <div className="p-0">Physical Therapy</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Treatment Affect on Pain</div>
                                <div>Made a Little Better</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Associated Symptoms</div>
                                <div>Knee Locking, Instability in Knee</div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-md-block d-sm-none scrollBarWrapper">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-8 col-md-10">
                    {/* {changeStickyBar ? } */}
                    <div id="pageScrollBar" className="row vertical-center">
                      <div className="col message">
                        <a href="#section-1">
                          { indexAnimationArr[0] ? 
                            <Lottie options={messageAnimationOptions}/> :
                            indexArr[0] ?
                              <img src={require("../../img/message-icon-active.svg")} className="img-fluid" alt="" /> :
                              <img src={require("../../img/message-icon-default.svg")} className="img-fluid" alt="" />
                          }                        
                        </a>
                      </div>
                      <div className="col learn">
                        <a href="#section-2">
                          { indexAnimationArr[1] ?
                            <Lottie options={learnAnimationOptions}/> :
                            indexArr[1] ?
                              <img src={require("../../img/learn-icon-active.svg")} className="img-fluid" alt="" /> :
                              <img src={require("../../img/learn-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col questionnaire">
                        <a href="#section-3">
                          { indexAnimationArr[2] ?
                            <Lottie options={questionnaireAnimationOptions}/> :
                            indexArr[2] ?
                              <img src={require("../../img/questionnaire-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/questionnaire-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col checklist">
                        <a href="#section-4">
                          { indexAnimationArr[3] ?
                            <Lottie options={yogaAnimationOptions}/> :
                            indexArr[3] ?
                              <img src={require("../../img/yoga-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/yoga-inactive.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col symptoms">
                        <a href="#section-5">
                          { indexAnimationArr[4] ?
                            <Lottie options={symptomAnimationOptions}/> :
                            indexArr[4] ?
                              <img src={require("../../img/symptoms-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/symptoms-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col vitals">
                        <a href="#section-6">
                          { indexAnimationArr[5] ?
                            <Lottie options={vitalsAnimationOptions}/> :
                            indexArr[5] ?
                              <img src={require("../../img/vitals-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/vitals-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col lifestyle">
                        <a href="#section-7">
                          { indexAnimationArr[6] ?
                            <Lottie options={medicationAnimationOptions}/> :
                            indexArr[6] ?
                              <img src={require("../../img/medication-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/medication-inactive.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col appointment">
                        <a href="#section-8">
                          { indexAnimationArr[7] ?
                            <Lottie options={appointmentPlanningAnimationOptions}/> :
                            indexArr[7] ?
                              <img src={require("../../img/appointment-planning-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/appointmentplanning-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="chartNote timeline-wrapper">
                      <div class="timeline-badge note"></div>
                      <h4 class="timeline-heading">Auto Generate Chart Note</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Use natural language processing to automatically generate chart notes and insert them into your electronic health record system. 
                        <br></br><br></br>Customize the chart note to match your style, and include any and all information from the chief complaints, questionnaires, HPI - symptoms, vitals, medications and more. Maintain complete documentation to support your treatment plan.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li className="pt-0">
                <div className="timeline-row row align-items-lg-center justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="timelinePanel-8">
                      <div class="timeline-body align-center">
                        <p>Emma has been experiencing pain in her left knee for 1-2 months. She has had previous knee injuries and has had the following surgery: Meniscus Surgery, November 2018. Emma rated her pain a 6 on a scale from 1-10. She described the pain as throbbing, along the outside of the knee. She is also experiencing stiffness and clicking in the knee. 
                          <br></br><br></br>Emma has been experiencing pain in her left knee for 1-2 month. She has had previous knee injuries and has had the following surgery: Meniscus Surgery, November 2018. Emma rated her pain a 6 on a scale from 1-10. She described the pain as throbbing, along the outside of the knee. She is also experiencing stiffness and clicking in the knee.
                        </p>
                      </div>
                    </div>
                    <div className="success-copy">
                      { window.innerWidth > 991 ?
                        <div className="button">
                          <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                { rendercopyButton && <Lottie options={copyAnnasHealthAnimationOptions}/> }
                        </div> :
                        window.innerWidth > 767 ?
                          <div className="button">
                            <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                  { rendercopyButton && <Lottie options={copyAnnasHealthAnimationTabletOptions}/> }
                          </div> :
                          <div className="button">
                            <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                  { rendercopyButton && <Lottie options={copyAnnasHealthAnimationMobileOptions}/> }
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="dash"></div>
              </li>
            </ul>
          </div>
        </section>

        <section className="timelineFooter">
          <div className="container-lg">
            <div className="timeline-row row align-items-lg-center justify-content-center mx-5 pb-5">
              <div className="col-lg-6 col-md-8 col-sm-9">
                {/* { startBootomLoop ? 
                  <Lottie options={bottomAnimationOptionsTwo}/> :
                  <Lottie options={bottomAnimationOptionsOne} onLoopComplete={this.onCompleteBottomAnimation}/>
                } */}
                <Lottie options={bottomAnimationOptionsTwo}/>
              </div>
              <div className="col-lg-5 col-md-12">
                <h3>DoctorPlan comes pre-configured with clinical care pathways for Orthopedic Surgery including</h3>
                <ul className="footerList text-left">
                  <li>New Patient – Hip, Ankle / Foot</li>
                  <li>New Patient – Shoulder, Hand, Elbow</li>
                  <li>Lower Body Follow Up</li>
                  <li>Upper Body Follow Up</li>
                  <li>Diagnostic / Imaging Review</li>
                </ul>
              </div>
              <Link to="/join-us" className="link-button-image">
                <div className="button-image text-center">Get DoctorPlan for your Practice</div>
              </Link>
            </div>
          </div>
        </section>
      </div>


    )
  }
}

export default Orthopedic;