import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link }  from 'react-router-dom';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";

import VideoModal from '../Home/VideoModal'

var validateEmail = require('../../util/valid-email.js').validateEmail;

@inject("globalStore")
@observer
class AdvertisementLanding extends Component {
  constructor(props) {
    super(props);
    this.renderForms =  this.renderForms.bind(this);

    this.firstForm =  React.createRef();
    this.userSupportForm =  React.createRef();
    this.somethingForm =  React.createRef();
    
    this.submitResponse = this.submitResponse.bind(this);
    this.state = {
      // reason: "schedule_demo",
      scheDuleDemoEmail: "",
      scheDuleDemoName: "",
      scheDuleDemoPhone: "",
      scheDuleDemoMessage: "", 
      scheDuleDemoDoctor: "",
      // userSupportReason: "user_support",
      somethingElseEmail: "",
      somethingElseName: "",
      somethingElsePhone: "",
      somethingElseMessage: "", 
      somethingElseDoctor: "",
      isEmpty: false,

      userSupporName: "",
      userSupporMessage: "", 


      scheDuleDemoStatus: true,
      userSupportStatus: true,
      somethingElseStatus:true,
      errorState: false,
      errorStateScheduleDemo:false,
      errorStateUserSupport:false,
			errorStateSomethingElse:false,

			emailError: "",
			nameError: "",
			doctorNameError: ""
  };
}

  componentDidMount() {
    document.title='Contact DoctorPlan'
  }

               
autosize = () => {
  var el = this;
  setTimeout(function(){
    el.style.cssText = 'height:auto; padding:0';
    // for box-sizing other than "content-box" use:
    // el.style.cssText = '-moz-box-sizing:content-box';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  },0);
}
	clearFormStates = () => {
		this.setState({	
			emailError: "",
			nameError: "",
			doctorNameError: ""
		})
	}

	clearTargetError = (name) => {
		if(name === 'scheDuleDemoName') {
			this.setState({nameError: ""})
		} else if(name === 'userSupporName') {
			this.setState({doctorNameError: ""})
		} else if(name === 'scheDuleDemoEmail') {
			this.setState({emailError: ""})
		}
	}

	setErrorMsg = (name) => {
		if(name === 'scheDuleDemoName') {
			this.setState({nameError: "Required"})
		} else if(name === 'userSupporName') {
			this.setState({doctorNameError: "Required"})
		} else if(name === 'scheDuleDemoEmail') {
			this.setState({emailError: "Required"})
		}
	}

	checkErrors = () => {
		let { 
      scheDuleDemoEmail, 
			scheDuleDemoName,
			userSupporName,
		} = this.state;

		if(!scheDuleDemoEmail || !scheDuleDemoName || !scheDuleDemoName) {
			document.getElementById("contactForm").className="formBody schDemo submitted"

			// Email validation
			if(!scheDuleDemoEmail) {
				this.setState({emailError: "Required" });
			}

			// Name validation	
			if(!scheDuleDemoName) {
				this.setState({nameError: "Required" })
			}

			// Doctor Name validation
			if(!userSupporName) {
				this.setState({doctorNameError: "Required" });
			}
			return true
		}

		if(!validateEmail(scheDuleDemoEmail)) {
			let elem = document.getElementsByName('scheDuleDemoEmail')
			elem[0].classList.add("error");

			this.setState({emailError: "Invalid Email" });
			return true
		}

		return false
	}

  handleChange = (e) =>{

		document.getElementById("contactForm").className="formBody schDemo";

    if(e.target.value === ""){
      let elem = document.getElementsByName(e.target.name)
			elem[0].classList.add("error");

			this.setErrorMsg(e.target.name);
    }else{
      let elem = document.getElementsByName(e.target.name)
			elem[0].classList.remove("error");
			this.clearTargetError(e.target.name);
		}

    this.setState({
      [e.target.name]: e.target.value
    });
	}

  submitResponse = (reason) => {
    
    let { 
      scheDuleDemoEmail, 
			scheDuleDemoName,
			scheDuleDemoPhone,
			scheDuleDemoMessage,
			userSupporName,
		} = this.state;

		// Reset all error states
		this.clearFormStates();

		if(this.checkErrors()) {
			return
		};

    let obj = {
      "reason": "landing_msk",
      "email": scheDuleDemoEmail,
      "name": scheDuleDemoName,
      "phone": scheDuleDemoPhone,
      "message": scheDuleDemoMessage,
      "doctor": userSupporName,
		}
		 
    if(scheDuleDemoEmail !== '' && scheDuleDemoName !==  ''  ) {
      this.props.globalStore.postFormDetails(obj).then((resp) => {
       	if(resp.token) {
					this.setState({scheDuleDemoStatus: false})
					this.firstForm.current.children[3].children[1].lastChild.value = '';
					this.firstForm.current.children[2].children[1].lastChild.value= '';
					this.firstForm.current.children[1].children[1].lastChild.value= '';
					this.firstForm.current.children[0].children[1].lastChild.value= '';
       }	else	{
        this.setState({scheDuleDemoStatus: false});
        this.setState({ errorStateScheduleDemo: true });
       }
    }).catch((e)=>{
      console.log(e);
    });
    }	else {
       let targetName = ''
       if(this.firstForm.current.children[1].children[1].lastChild.value === ''){
        targetName = this.firstForm.current.children[1].children[1].lastChild.name;
        document.getElementsByName(targetName)[0].classList.add('error')
       }
       if(this.firstForm.current.children[0].children[1].lastChild.value === ''){
        targetName = this.firstForm.current.children[0].children[1].lastChild.name;
        document.getElementsByName(targetName)[0].classList.add('error')
       }
    }
  }

  renderForms = () => {
		return <form className="formBody schDemo" id="contactForm" ref={this.firstForm}>
			<h3>Interested in using DoctorPlan in your hospital/practice?</h3>
			<div className="row formRow">
				<div className="col-3 col-lg-3 label">
					<label>Doctor Name*</label>
				</div>
				<div className="col-8 col-lg-9">
					<input className="formField" type="text" name="scheDuleDemoName" placeholder="Jane Donik" onChange={this.handleChange} required/>
					<p className="error-msg">{this.state.nameError}</p>
				</div>
			</div>
			<div className="row formRow">
				<div className="col-3 col-lg-3 label">
					<label>Practice/Hospital Name*</label>
				</div>
				<div className="col-8 col-lg-9">
					<input className="formField"  type="text"  name="userSupporName" onChange={this.handleChange} placeholder="California Health Care"  required/>
					<p className="error-msg">{this.state.doctorNameError}</p>
				</div>
			</div>
			<div className="row formRow">
				<div className="col-3 col-lg-3 label">
					<label>Email Address*</label>
				</div>
				<div className="col-8 col-lg-9">
					<input className="formField" type="email" name="scheDuleDemoEmail" placeholder="Jane@example.com" onChange={this.handleChange} required />
					<p className="error-msg">{this.state.emailError}</p>
				</div>
			</div>
			<div className="row formRow">
				<div className="col-3 col-lg-3 label">
					<label>Phone No.</label>
				</div>
				<div className="col-8 col-lg-9">
					<input className="formField" type="number" name="scheDuleDemoPhone" placeholder="(123) 456-7890"
					
					onChange={this.handleChange} />
				</div>
			</div>
			<div className="row formRow">
				<div className="col-3 col-lg-3 label">
					<label>Message</label>
				</div>
				<div className="col-8 col-lg-9">
					<textarea className="formField" rows="3" placeholder="Hi, I would like to learn more about using DoctorPlan in my practice." 
					name="scheDuleDemoMessage" onChange={this.handleChange}  >
					
					</textarea>
				</div>
			</div>
			<div className="row formRow justify-content-end">
				<div className="col-auto">
					{ (this.state.scheDuleDemoStatus)  ? <button type="button" onClick={() => this.submitResponse()} className="submitBtn">submit</button>
					:  <div className="successMsg">Thank you. We’ll get in touch with you shortly.</div>
				}
				</div>
			</div>
		</form>
  }

  render() {

    return (
      <div>
        <div className="container-lg" style={{ "marginTop": '31px' }}>
			<section style={{ textAlign: 'center' }}>
				<Link class="navbar-brand logo" to="/" >
					<img src={require("../../img/doctorPlan-group@3x.png")} alt="" />
				</Link>
			</section>

			<section className="contactUsFormForAd px-xl-6">
				<div className="row justify-content-center">
					<div className="col-lg-6 col-md-12" style={{"padding": '0'}}>
						<div className="desc">
							<h2 class="fts-42">Precision Healthcare</h2>
							<p className="subHeading">
								Create deep relationships with patients, using a data driven approach to personalize care delivery 
							</p>
							<VideoModal url="https://content.doctorplan.com/video/spine_pain.mp4" poster={require('../../img/thumbnail.png')} button="alt"/>
						</div>
					</div>
					<div className="formSection col-lg-6 col-md-12 px-xl-5 col-sm-10 col-xs-11">
						<div className="formBlock">
							{this.renderForms()}
						</div>
					</div>
				</div>
			</section>

			<section className="intro">
				<div className="row justify-content-center">
					<div className="col-md-2 col-sm-12 intro-block">
						<img src={require("../../img/Group_39@3x.png")} className="img-fluid" alt="" />
						<p>Spine Surgery</p>
					</div>
					<div className="col-md-2 col-sm-12 intro-block">
						<img src={require("../../img/Group_16@3x.png")} className="img-fluid" alt="" />
						<p>Pain Management</p>
					</div>
					<div className="col-md-2 col-sm-12 intro-block">
						<img src={require("../../img/Group 214@3x.png")} className="img-fluid" alt="" />
						<p>Orthopedics</p>
					</div>
				</div>
				<div className="row justify-content-center">
					<p className="col-9 col-sm-10 intro-desc">
						DoctorPlan is a clinical workflow platform for musculoskeletal pain physicians – helping guide patients through their entire care journey, from before the first consult to 2-years post treatment, improving patient experience and outcomes.
						<br></br><br></br>
						With DoctorPlan, precise and comprehensive health information is collected directly from patients, helping focus the patient visit time effectively. DoctorPlan’s technology understands the context of the patient visit,
						and collects relevant health information directly from patients, making it easy for providers to efficiently review, assess the patient, and support clinical decisions.
						<br></br><br></br>
						Our platform sits on top of your existing EHR and automates the creation of the visit chart notes, lessening physician burnout and improving the depth and quality of documentation.
					</p>
				</div>
			</section>

			<section className="questionnaires sections-lg">
				<div className="row">
					<img src={require("../../img/1roup 2@3x@3x.png")} className="img-fluid bg-img-lg image-lg" alt="" />
					<img src={require("../../img/1roup 2@3x@3x copy.png")} className="img-fluid bg-img-lg image-md" alt="" />
				</div>
				<div className="col-lg-4 col-md-6 overlay-text right">
					<h4>Intake, Follow Up and Outcomes Questionnaires</h4>
					<p>Leverage best practice questionnaires for Cervical, Lumbar or Specific Joints - Intake, Follow-up, Post-Op, Prior Diagnostic / Treatments, and Medical History – improving the depth and quality of documentation.
						Automated collection of patient reported outcomes (PRO), functional abilities, and ROM included - all scored and analyzed. Patients use the friendly mobile app, or just their email and a web-browser to participate in their care.
					</p>
				</div>
			</section>

			<section className="questionnaires sections-sm">
				<div className="row">
					<div className="col-12" style={{ "backgroundColor": '#FCF1FF', "textAlign": 'center' }}>
						<img src={require("../../img/Group-16@3x.png")} className="img-fluid bg-img-sm" alt="" />
					</div>
				</div>
				<div className="row">
					<div className="col-12 overlay-text">
						<h4>Intake, Follow Up and Outcomes Questionnaires</h4>
						<p>Leverage best practice questionnaires for Cervical, Lumbar or Specific Joints - Intake, Follow-up, Post-Op, Prior Diagnostic / Treatments, and Medical History – improving the depth and quality of documentation.
							Automated collection of patient reported outcomes (PRO), functional abilities, and ROM included - all scored and analyzed. Patients use the friendly mobile app, or just their email and a web-browser to participate in their care.
						</p>
					</div>
				</div>
			</section>

			<section className="questionnaires sections-lg">
				<div className="row">
					<div className="col-12 px-sm-0">
						<img src={require("../../img/facesheet@2x + copy to chart@2x Mask.png")} className="img-fluid bg-img-lg image-lg" style={{ "float": 'right' }} alt="" />
						<img src={require("../../img/facesheet@2x + copy to chart@2x Mask@3x copy.png")} className="img-fluid bg-img-lg image-md" style={{ "float": 'right' }} alt="" />
					</div>
				</div>
				<div className="col-lg-4 col-md-6 overlay-text left">
					<h4>Synopsis, Progress, and Automated Charting</h4>
					<p>
						DoctorPlan intelligently anticipates the information a provider needs at each visit and presents it to them in a quick 1-page synopsis.
						Providers can use this to efficiently review data, assess the patient, and support clinical decision-making. DoctorPlan also automates the creation of chart notes after each visit, lessening physician burnout.
					</p>
				</div>
			</section>
			
			<section className="questionnaires sections-sm">
				<div className="row">
					<div className="col-12" style={{ "backgroundColor": '#F4FBEB', "textAlign": 'center' }}>
						<img src={require("../../img/facesheet@2x + copy to chart@2x Mask@3x.png")} className="img-fluid bg-img-sm" alt="" />
					</div>
				</div>
				<div className="row">
					<div className="col-12 overlay-text">
						<h4>Synopsis, Progress, and Automated Charting</h4>
						<p>
							DoctorPlan intelligently anticipates the information a provider needs at each visit and presents it to them in a quick 1-page synopsis.
							Providers can use this to efficiently review data, assess the patient, and support clinical decision-making.DoctorPlan also automates the creation of chart notes after each visit, lessening physician burnout.
						</p>
					</div>
				</div>
			</section>

			<section className="questionnaires sections-lg">
				<div className="row">
					<img src={require("../../img/1roup 2@3x@3x 2.png")} className="img-fluid bg-img-lg image-lg" alt="" />
					<img src={require("../../img/1roup 2@3x@3x copy 2.png")} className="img-fluid bg-img-lg image-md" alt="" />
				</div>
				<div className="col-lg-4 col-md-6 overlay-text right">
					<h4>The Right Information, at the Right Time</h4>
					<p>DoctorPlan guides patients through their care journey with important information and timely reminders. Patients receive messages before and after visits and surgeries, as well as bite-sized articles to ensure patients are properly educated about their condition, 
						treatment, preparation, and recovery. Included in the app are guided exercise videos, specifically created for conditioning and rehab, as well as mindfulness lessons for stress relief and pain management.
					</p>
				</div>
			</section>

			<section className="questionnaires sections-sm">
				<div className="row">
					<div className="col-12" style={{ "backgroundColor": '#FCF1FF', "textAlign": 'center' }}>
						<img src={require("../../img/Group 2@3x.png")} className="img-fluid bg-img-sm" alt="" />
					</div>
				</div>
				<div className="row">
					<div className="col-12 overlay-text">
						<h4>The Right Information, at the Right Time</h4>
						<p>DoctorPlan guides patients through their care journey with important information and timely reminders. Patients receive messages before and after visits and surgeries, as well as bite-sized articles to ensure patients are properly educated about their condition, 
							treatment, preparation, and recovery. Included in the app are guided exercise videos, specifically created for conditioning and rehab, as well as mindfulness lessons for stress relief and pain management.
						</p>
					</div>
				</div>
			</section>

			<section className="testimonials">
				<h4>Testimonials</h4>
				<div className="row no-gutters">
					<div className="col-md-6 col-xs-12 test-row border-right-bottom">
						<div className="row">
							<p>
								“DoctorPlan has helped us operate our practice very efficiently. From the <b>first intake forms, to timely reminders and instructions,</b> my patients are guided through their entire diagnostic, treatment, and recovery process. 
								I am better prepared for their visits and use the <b>patient data to maintain complete documentation</b> to support recommended treatment plans. The outcomes analysis clearly shows me how they do before and after, helping us improve our quality of care.”
							</p>
						</div>
						<div className="row doctor-desc">
							<img src={require("../../img/d2b_Dr. Brian-Su@3x.png")} className="img-fluid testimonial-img" alt="" />
							<div className="desc">
								<p className="name">Dr. Brian Su</p>
								<p className="designation">Spine Surgery</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-xs-12 test-row">
						<div className="row">
							<p>
								“With DoctorPlan, I have a clear picture of my patients’ progress – from before the <b>first consultation to post-treatment outcomes.</b> My patients log their pain and associated symptoms, 
								and I can follow up on their treatment effectiveness. DoctorPlan helps guide my patients with the <b>right information at the right time,</b> so they are better informed about their condition and treatment plan.”
							</p>
						</div>
						<div className="row doctor-desc">
							<img src={require("../../img/c3b_Ramana-Naidu@3x.png")} className="img-fluid testimonial-img" alt="" />
							<div className="desc">
								<p className="name">Dr. Ramana Naidu</p>
								<p className="designation">Intervention Pain Management</p>
							</div>
						</div>
					</div>
				</div>
			</section>

        </div>
      </div>
    );
  }
}

export default AdvertisementLanding;