import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";
import { copySync } from "fs-extra";
import Header from '../Header/Header'

@inject("globalStore")
@observer
class JoinUs extends Component {
  constructor(props) {
    super(props);
    this.renderForms =  this.renderForms.bind(this);

    this.firstForm =  React.createRef();
    this.userSupportForm =  React.createRef();
    this.somethingForm =  React.createRef();
    
    this.submitResponse = this.submitResponse.bind(this);
    this.state = {
      activeTab: 1,
      // reason: "schedule_demo",
      scheDuleDemoEmail: "",
      scheDuleDemoName: "",
      scheDuleDemoHospitalName: "",
      scheDuleDemoPhone: "",
      scheDuleDemoMessage: "", 
      scheDuleDemoDoctor: "",
      // userSupportReason: "user_support",
      somethingElseEmail: "",
      somethingElseName: "",
      somethingElsePhone: "",
      somethingElseMessage: "", 
      somethingElseDoctor: "",
      isEmpty: false,

      userSupporName: "",
      userSupporEmail: "",
      userSupporDoctorName: "",
      userSupporMessage: "", 


      scheDuleDemoStatus: true,
      userSupportStatus: true,
      somethingElseStatus:true,
      errorState: false,
      errorStateScheduleDemo:false,
      errorStateUserSupport:false,
      errorStateSomethingElse:false,
  };
}

  componentDidMount() {
    document.title='Contact DoctorPlan'
  }

               
autosize = () => {
  var el = this;
  setTimeout(function(){
    el.style.cssText = 'height:auto; padding:0';
    // for box-sizing other than "content-box" use:
    // el.style.cssText = '-moz-box-sizing:content-box';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  },0);
}


  handleChange = (e) =>{
    if(e.target.value === ""){
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.add("error");
      // elem[0]= elem[0] + '<span [class]="errorTxt">Required</span>'
    }else{
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.remove("error");
      // elem[0]= elem[0]
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  submitResponse = (reason) => {
    
    let { 
      scheDuleDemoEmail, 
      scheDuleDemoName, 
      scheDuleDemoHospitalName,
      scheDuleDemoPhone, 
      scheDuleDemoMessage,
      userSupporName,
      userSupporEmail,
      userSupporMessage,
      userSupporDoctorName, 
      somethingElseName,
      somethingElseEmail,
      somethingElsePhone,
      somethingElseMessage,
      errorStateScheduleDemo,
      errorStateUserSupport,
      errorStateSomethingElse,
    } = this.state;


    if(reason ==='scheduleDemo'){
     let obj = {
      "reason": "schedule_demo",
      "email": this.state.scheDuleDemoEmail,
      "name": this.state.scheDuleDemoName,
      "hospital": this.state.scheDuleDemoHospitalName,
      "phone": this.state.scheDuleDemoPhone,
      "message": this.state.scheDuleDemoMessage,
      "doctor": ""
     }
     if(scheDuleDemoEmail !== '' && scheDuleDemoName !== '' && scheDuleDemoHospitalName !== '' && scheDuleDemoPhone !== '') {
      this.props.globalStore.postFormDetails(obj).then((resp) => {
        this.state.scheDuleDemoEmail = ''
        this.state.scheDuleDemoName = ''
        this.state.scheDuleDemoHospitalName = ''
        this.state.scheDuleDemoPhone = ''
        this.state.scheDuleDemoMessage = ''
       if(resp.token) {
          this.setState({scheDuleDemoStatus: false})
          this.firstForm.current.children[3].children[1].lastChild.value = '';
          this.firstForm.current.children[2].children[1].lastChild.value= '';
          this.firstForm.current.children[1].children[1].lastChild.value= '';
          this.firstForm.current.children[0].children[1].lastChild.value= '';
       }else{
        this.setState({scheDuleDemoStatus: false});
        this.setState({ errorStateScheduleDemo: true });
       }
    }).catch((e)=>{
      console.log(e);
    });
    }else {
       let targetName = ''
      //  if(this.firstForm.current.children[3].children[1].lastChild.value === ''){
      //       targetName = this.firstForm.current.children[3].children[1].lastChild.name;
      //       document.getElementsByName(targetName)[0].classList.add('error')
      //  } 
      //  if(this.firstForm.current.children[2].children[1].lastChild.value === ''){
      //   targetName = this.firstForm.current.children[2].children[1].lastChild.name;
      //   document.getElementsByName(targetName)[0].classList.add('error')
      //  }
       if(this.firstForm.current.children[1].children[1].lastChild.value === ''){
        targetName = this.firstForm.current.children[1].children[1].lastChild.name;
        document.getElementsByName(targetName)[0].classList.add('error')
       }
       if(this.firstForm.current.children[0].children[1].lastChild.value === ''){
        targetName = this.firstForm.current.children[0].children[1].lastChild.name;
        document.getElementsByName(targetName)[0].classList.add('error')
       }
    }
    }
  }

  renderForms = () => {
      if(this.state.activeTab === 1) {
       return <form className="formBody schDemo" ref={this.firstForm}>
        <h3>Interested in using DoctorPlan in your hospital / clinic?</h3>
        <div className="row formRow">
          <div className="col-4 col-lg-3">
            <label>Doctor Name*</label>
          </div>
          <div className="col-8 col-lg-9">
            <input className="formField" type="text" name="scheDuleDemoName" value={this.state.scheDuleDemoName} placeholder="Jane Donik" onChange={this.handleChange} required/>
          </div>
        </div>
        <div className="row formRow">
          <div className="col-4 col-lg-3">
            <label>Practice/Hospital Name*</label>
          </div>
          <div className="col-8 col-lg-9">
            <input className="formField" type="text" name="scheDuleDemoHospitalName" value={this.state.scheDuleDemoHospitalName} placeholder="Dr. Jamie Doe/Critical Care Medical" onChange={this.handleChange} required/>
          </div>
        </div>
        <div className="row formRow">
          <div className="col-4 col-lg-3">
            <label>Email Address*</label>
          </div>
          <div className="col-8 col-lg-9">
            <input className="formField" type="email" name="scheDuleDemoEmail" value={this.state.scheDuleDemoEmail} placeholder="jane@example.com" onChange={this.handleChange} required />
          </div>
        </div>
        <div className="row formRow">
          <div className="col-4 col-lg-3">
            <label>Phone No.*</label>
          </div>
          <div className="col-8 col-lg-9">
            <input className="formField" type="number" name="scheDuleDemoPhone" value={this.state.scheDuleDemoPhone} placeholder="(123) 456-7890"
             
            onChange={this.handleChange} required/>
          </div>
        </div>
        <div className="row formRow">
          <div className="col-4 col-lg-3">
            <label>Message</label>
          </div>
          <div className="col-8 col-lg-9">
            <textarea className="formField" placeholder="Hi, I would love to arrange a product demo for our team." 
            name="scheDuleDemoMessage" maxlength="400" value={this.state.scheDuleDemoMessage} onChange={this.handleChange}  >
            </textarea>
          </div>
        </div>
        <div className="row formRow justify-content-end">
          <div className="col-auto">
            { (this.state.scheDuleDemoStatus)  ? 
              <button type="button" onClick={() => this.submitResponse('scheduleDemo')} className="submitBtn" disabled={!this.state.scheDuleDemoName || !this.state.scheDuleDemoEmail || !this.state.scheDuleDemoHospitalName || !this.state.scheDuleDemoPhone}>submit</button>
            :  <div className="successMsg">Thank you. We’ll get in touch with you shortly.</div>
          }
          </div>
        </div>
      </form>
      }
  }

  render() {

    return (
      <div>
        <Header color="#2D3E4F"/>
        <div className="container-lg">
          <section className="contactUsForm px-xl-5">
              <div className="row justify-content-center">
                <div className="col-md-3 offset-md-1 px-md-0">
                  <h2 class="fts-42">Hi there!</h2>
                  <p className="subHeading">
                    Please provide your contact information, and we'll get back to
                    you soon.
                  </p>
                </div>
                <div className="col-md-8 px-xl-5">
                  <div className="formBlock">
                    <div className="formHeader">
                      <ul>
                        <li>
                          <span><img src={require('../../img/schedule-demo-icon-active@3x.png')} /></span>Schedule Demo
                        </li>
                      </ul>
                    </div>

                    {this.renderForms()}

                  </div>
                </div>
              </div>
          </section>
          <br></br>
          <br></br>
          <div class="spacer"></div>
        </div>
      </div>
    );
  }
}

export default JoinUs;
