import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Swiper from 'react-id-swiper';
import VideoPlayer from 'react-video-js-player';
import Header from '../Header/Header'


import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";
import PersonalizeJourney from '../common/PersonalizeJourney'

@inject('globalStore')
@observer class HowItWorks extends Component {
  constructor(props) {
    super(props);

    this.scrollAction =  this.scrollAction.bind(this)
    this.state = {
      video: {
        src: "https://res.cloudinary.com/doctorplan/video/upload/v1594822173/DoctorPlan.com%20Website/DoctorPlan_-_Jason_Huffman_apdrli.mp4",
        src_bottom: 'https://content.doctorplan.com/video/doctorplan_video.mp4',
        poster: require('../../img/thumbnail.png'),
        posterTop: require('../../img/topthumbnail@3x.png'),
    }
  }
  }
  player = {};
onPlayerReady(player){
    console.log("Player is ready: ", player);
    this.player = player;
}

onVideoPlay(duration){
  console.log("Video play at: ", duration);
}

onVideoPause(duration){
    console.log("Video paused at: ", duration);
}

onVideoTimeUpdate(duration){
    console.log("Time updated: ", duration);
}

onVideoSeeking(duration){
    console.log("Video seeking: ", duration);
}

onVideoSeeked(from, to){
    console.log(`Video seeked from ${from} to ${to}`);
}

onVideoEnd(){
    console.log("Video ended");
}

componentDidMount() {
  document.title = 'How it Works - DoctorPlan'
  window.addEventListener('scroll', this.scrollAction);
}
componentWillUnmount() {
  window.removeEventListener('scroll', this.scrollAction);
}
scrollAction = (e) => {
  this.setState({  scrollPosition: window.pageYOffset },function(){
        if(this.state.scrollPosition > 2){
          this.props.globalStore.blueHeader = false;
       }else {
        this.props.globalStore.blueHeader = true;
       }
  });
}

  
  onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  onSlide(event) {

  }

  render() {
    const params = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination:{
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 2500
      },
    }

    const opts = {
      height: '398',
      width: '1110',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        loop:2,
        rel:0,
      },
    };
    const opts1 = {
      height:'625',
      width: '1110',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        loop:2,
        rel:0,
      },
    };
    return (
      <div>
        <div class="container-lg" onScroll={this.scrollAction}>
          <Header color="#2D3E4F"/>
          <section class="video-block">
            <h2 class="text-md-center" style={{"color": "#333333"}}><div class="d-block d-md-inline" style={{ "fontWeight" : "normal" }}>Personalize <span>Care.</span></div> <div class="d-block d-md-inline">Engage <span>Patients.</span></div> <div class="d-block d-md-inline">Improve <span>Outcomes.</span></div></h2>
            <div class="video-wrap video-large" style={{"display": "flex","margin":"auto", "justify-content": "center"}}>
              <img class="large-image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABQAAAALQAQMAAAD1s08VAAAAA1BMVEX///+nxBvIAAAAi0lEQVR42uzAMQEAAADCIPuntsROGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACpBwAA///E3wABH5dPDwAAAABJRU5ErkJggg==" alt="" />
              <VideoPlayer
                    controls={true}
                    src={this.state.video.src_bottom}
                    poster={this.state.video.poster}
                    onReady={this.onPlayerReady.bind(this)}
                    onPlay={this.onVideoPlay.bind(this)}
                    onPause={this.onVideoPause.bind(this)}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    onSeeking={this.onVideoSeeking.bind(this)}
                    onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={this.onVideoEnd.bind(this)}
                />
            </div>
        </section>
        </div>
        
        <section class="intelligent">
          <br></br>
          <div class="container px-4">
            <h2 class="text-md-center fts-42">Delivering on the Promise of Personalized Healthcare</h2>
            <p class="sub-heading text-md-center">
              DoctorPlan’s innovative technology is making healthcare more empowering for patients, and more efficient for doctors.
            </p>
          </div>
          <div class="container-lg">
            <div class="int-content-wrap">
              <div class="row intake align-items-center justify-content-center">
                <div class="col-md-6 p-0">
                  {/*<!-- sllider -->*/}
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                    <Swiper {...params}>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/pain-condition-intake@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/intake-follow-up-and-outcomes-questionnaires-02@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/intake-follow-up-and-outcomes-questionnaires-03@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      </Swiper>
                    </div>
                    {/*<!-- Add Pagination -->*/}
                    <div class="swiper-pagination"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="contentCol">
                    <h4>Intake, Follow Up and Outcomes Questionnaires</h4>
                    <p>Leverage best practice intake forms for new patient consults, follow-up visits, and post-surgery appointments. Patients focus on providing relevant information in the comfort of their home — where they have access to the right information. </p>
                    <p>The clinical pathways include standard Patient Reported Outcomes (PRO) measures — Functional Health, Mental Health, Pain Scale, Disability Index, and more. Automated scoring and analysis help improve outcomes.</p>
                  </div>
                </div>
              </div>
              <div class="row right-information align-items-center justify-content-center">
                <div class="col-md-6 order-md-1 p-0">
                  {/*<!-- slider -->*/}
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                    <Swiper {...params}>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/01-3-x-copy-2@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/the-right-information-at-the-right-time-02@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/the-right-information-at-the-right-time-03@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                    </Swiper>
                    </div>
                    {/*<!-- Add Pagination -->*/}
                    <div class="swiper-pagination"></div>
                    {/*<!-- Add Pagination -->*/}
                    {/* <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div> */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="contentCol">
                    <h4>The Right Information, at the Right Time</h4>
                    <p>Guide patients with information and reminders — before a consult, procedure, or pre and post-surgery. Ensure patients are educated about their condition, treatment, preparation and recovery — with easy to understand, bite-sized articles.</p>
                    <p>Start with pre-configured clinical care pathways and customize to meet your specific needs. Include guided exercise videos, specifically created for prehab and rehab programs, and mindfulness lessons for stress relief and pain management.</p>
                  </div>
                </div>
              </div>
              <div class="row synopsis align-items-center justify-content-center">
                <div class="col-md-6 p-0">
                  {/*<!-- sllider -->*/}
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                    <Swiper {...params}>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/synopsis-hiw-image-1@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/synopsis-correlations-and-automated-charting-02@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/synopsis-correlations-and-automated-charting-03@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                     </Swiper> 
                    </div>
                    {/*<!-- Add Pagination -->*/}
                    <div class="swiper-pagination"></div>
                    {/*<!-- Add Pagination -->*/}
                    {/* <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div> */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="contentCol">
                    <h4>Synopsis, Correlations, and Automated Charting</h4>
                    <p>Doctors access a 1-page synopsis of patient symptoms, vitals, and lifestyle data — for a quick review prior to patient visit. The health intelligence helps correlate the different aspects of patient data to provide a complete picture. </p>
                    <p>All patient data — symptoms, vitals, questionnaires and communications — are automatically converted into narrative chart notes, uniquely configured for your charting style. Doctors and staff save 5-10 minutes for each patient interaction and ensure documentation compliance.</p>
                  </div>
                </div>
              </div>
              <div class="row symptoms align-items-center justify-content-center">
                <div class="col-md-6 order-md-1 p-0">
                  {/*<!-- slider -->*/}
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                    <Swiper {...params}>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/symptoms-vitals-and-lifestyle-tracking-01@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/symptoms-vitals-and-lifestyle-tracking-02@2x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="slide-content">
                          <img src={require("../../img/symptoms-vitals-and-lifestyle-tracking-03@3x.png")} class="img-fluid" alt="" />
                        </div>
                      </div>
                      </Swiper>
                    </div>
                    {/*<!-- Add Pagination -->*/}
                    <div class="swiper-pagination"></div>
                    {/*<!-- Add Pagination -->*/}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="contentCol">
                    <h4>Symptoms, Vitals, and Lifestyle Tracking</h4>
                    <p>Patients log symptoms as they experience them — with intelligent follow up questions to document relevant details. Correlate with vitals like Blood Pressure, Temperature, Weight, and Blood Sugar.</p>
                    <p>Also included is a sleep diary, mood and stress tracking — to provide a comprehensive picture. Patients can document their food intake (including calorie, protein, carbs and fat), water logs, and bowel movements — all with just a couple of clicks.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-xl"><PersonalizeJourney /></div>
        <div class="spacer"></div>
      </div>


    )
  }
}

export default HowItWorks;