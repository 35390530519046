import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "../../assets/styles/grid.css";
// import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import Swiper from 'react-id-swiper';
import "./style.scss";

@inject('globalStore')
@observer class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    //   var swiper = new Swiper('.swiper-container', {
    //    slidesPerView: 1,
    //    spaceBetween: 10,
    //    // init: false,
    //    pagination: {
    //      el: '.swiper-pagination',
    //      clickable: true,
    //    },
    //    breakpoints: {
    //      '@0.00': {
    //        slidesPerView: 1,
    //        spaceBetween: 10,
    //      },
    //      '@0.75': {
    //        slidesPerView: 2,
    //        spaceBetween: 20,
    //      },
    //      '@1.00': {
    //        slidesPerView: 3,
    //        spaceBetween: 40,
    //      },
    //      '@1.50': {
    //        slidesPerView: 5,
    //        spaceBetween: 50,
    //      },
    //    }
    //  });
  }



  render() {
    const params = {
      slidesPerView: 5,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      }
    }
    return (
      <div>
        <section className="surgery" style={{ marginTop: "32px"}}> 
          <div className="container-lg">
            <h2><img src={require("../../img/group-39.png")} className="img-fluid" alt="" />Spine Surgery</h2>
          </div>
        </section>
        <section className="testimonial01">
          <div className="container-lg">
            <div className="row align-items-lg-end justify-content-center">
              <div className="col-lg-5 col-md-4 text-center">
                <img src={require("../../img/s3.png")} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 col-md-8">
                <p>DoctorPlan has helped our practice so efficiently.
              From the  <span>first intake form</span>,  to  <span>timely reminders</span>  and instructions, my patients are using it to maintain a digital health diary.</p>

                <p>The outcomes analysis clearly shows me how they do before and after, helping us improve our quality of care.</p>

                <h3>Dr. Jason Huffman / Spine Surgeon</h3>
                {/* <a href="#" className="videoPlay"><img src={require("../../img/play-button-blue-see-doctorplan-in-action.png")} alt="" />  Watch Now</a> */}
              </div>
            </div>
          </div>
        </section>

        <div className="care-pathways-link">
          <Link to="/care-pathways/bariatric">Check out Clinical Care Pathways</Link>
        </div>

        <section className="sectionDes container-lg des1">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-5">
                <img src={require("../../img/1-roup-2-3-x.png")} alt="" />
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contentWrap">
                  <h4>Symptoms Recording and Checklists</h4>
                  <p>Ensure patients are educated about their condition, treatment, preparation and recovery - with easy to understand, bite-sized articles.</p>
                  <p>Include guided exercise videos, specifically created for prehab and rehab programs, and mindfulness lessons for stress relief and pain management.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des2">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 order-md-1 text-center text-md-right">
                <img src={require("../../img/rectangle-copy-4.png")} alt="" />
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="contentWrap">
                <h4>Intake, Follow Up and Outcomes Questionnaires</h4>
                <p>Leverage best practice intake forms for Lumbar and Cervical spine , follow-up visits, and post-surgery appointments. Patients focus on providing relevant information in the comfort of their home - where they have access to the right information. </p>

                <p>The clinical pathways include standard Patient Reported Outcomes (PRO) measures - Functional Health, Pain Scale, and Disability Indexes. Automated scoring and analysis help improve outcomes.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des3">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 text-center text-md-left">
                <img src={require("../../img/group-25.png")} alt="" />
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="contentWrap">
                <h4>Intake, Follow Up and Outcomes Questionnaires</h4>
                <p>Ensure patients are educated about their condition, treatment, preparation and recovery - with easy to understand, bite-sized articles. </p>
                <p>Include guided exercise videos, specifically created for prehab and rehab programs, and mindfulness lessons for stress relief and pain management.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des4">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 text-right  order-md-1">
                <img src={require("../../img/r1.png")} alt="" />
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="contentWrap">
                <h4>Synopsis, Correlations, and Automated Charting</h4>
                <p>Doctors access a 1-page synopsis of patient symptoms, vitals, and lifestyle data - for a quick review prior to patient visit. Different aspects of patient data are correlated to provide a complete picture.</p>

                <p> All patient data - symptoms, vitals, questionnaires and communications - are automatically converted into narrative chart notes, uniquely configured for your charting style. Doctors and staff save 5-10 minutes for each patient interaction and ensure documentation compliance.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="care-pathways-link">
          <Link>Check out Clinical Care Pathways</Link>
        </div>
        <section className="docDes">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8 order-2 order-md-0">
                <div className="row">
                  <div className="col-sm-8 col-md-12 p-0"><h2>Thoracic Surgeon Dr. Jason Moy on DoctorPlan</h2></div>
                  <div className="col-sm-4 d-md-none"><img src={require("../../img/s1.png")} className="img-fluid" alt="" /></div>
                </div>
                <p>“DoctorPlan has helped our practice so efficiently. From the first intake form, to timely reminders and instructions, my patients are using it to maintain a digital health diary.</p>

                <p>The outcomes analysis clearly shows me how they do before and after, and where we need to focus to drive improvements.”</p>
                <Link to="/areas-of-medicine/thoriac" className="learn-more">Learn More</Link>
              </div>
              <div className="col-lg-3 col-md-3 d-none d-md-block text-right">
                <img src={require("../../img/s1.png")} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <div className="container-lg">
        <section className="benefitsPlan">
            <h6>Learn more about how other doctors from different areas of medicines are benefiting from DoctorPlan </h6>
            <div className="swiper-container">
              <div className="swiper-wrapper">
              <Swiper {...params}>
                <div className="swiper-slide">
                  <img src={require("../../img/s2.png")} className="img-fluid" alt="" />
                  <h5>Dr. Alexander Fletcher</h5>
                  <Link to="/areas-of-medicine/thoriac">Thoracic Surgery</Link>
                </div>
                <div className="swiper-slide">
                  <img src={require("../../img/s3.png")} className="img-fluid" alt="" />
                  <h5>Dr. Darvin Smith</h5>
                  <Link to="/areas-of-medicine/spine">Spine Surgery</Link>
                </div>
                <div className="swiper-slide">
                  <img src={require("../../img/s4.png")} className="img-fluid" alt="" />
                  <h5>Dr. Anthony Doe</h5>
                  <Link to="/areas-of-medicine/spine">Spine Surgery</Link>
                </div>
                <div className="swiper-slide">
                  <img src={require("../../img/s4.png")} className="img-fluid" alt="" />
                  <h5>Dr. Jason Moy</h5>
                  <Link to="/areas-of-medicine/bariatric">Bariatric Surgery</Link>
                </div>
                <div className="swiper-slide">
                  <img src={require("../../img/s4.png")} className="img-fluid" alt="" />
                  <h5>Dr. Anthony Doe</h5>
                  <Link to="/areas-of-medicine/spine">Spine Surgery</Link>
                </div>
                <div className="swiper-slide">Slide 6</div>
                <div className="swiper-slide">Slide 7</div>
                <div className="swiper-slide">Slide 8</div>
                <div className="swiper-slide">Slide 9</div>
                <div className="swiper-slide">Slide 10</div>
                </Swiper>
              </div>
              {/*<!-- Add Pagination -->*/}
              <div className="swiper-pagination"></div>
            </div>
        </section>
        </div>
      </div>


    )
  }
}

export default Success;