import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";
import { copySync } from "fs-extra";
import Header from '../Header/Header'

@inject("globalStore")
@observer
class VGCC extends Component {
  constructor(props) {
    super(props);
    this.renderForms =  this.renderForms.bind(this);
    this.submitResponse = this.submitResponse.bind(this);

    this.state = {
      buttonDisable: false,
      formSubmittedSuccess: false,
      vgccEmail: "",
      vgccFirstName: "",
      vgccLastName: "",
      vgccPhone: "",
      vgccRefDoc: "",
      vgccTIF: null,
      vgccConsnt: false,
  };
}

  componentDidMount() {
    document.title = 'Virtual GERD Care Coordinator'
  }


  handleChange = (e) =>{
    if(e.target.value === ""){
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.add("error");
      // elem[0]= elem[0] + '<span [class]="errorTxt">Required</span>'
    }else{
      let elem = document.getElementsByName(e.target.name)
      elem[0].classList.remove("error");
      // elem[0]= elem[0]
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onFocusInput = (e) => {
    this.setState({
        formSubmittedSuccess: false
      });
  }

  selectTIF = (e, val) => {
    e.preventDefault()
    this.setState({
        vgccTIF: val
      })
  }

  submitResponse = () => {
    this.setState({
        buttonDisable: true,
    })
    let { 
        vgccEmail, 
        vgccFirstName, 
        vgccLastName,
        vgccPhone, 
        vgccRefDoc,
        vgccTIF,
        vgccConsnt
    } = this.state;

     let obj = {
        "email": vgccEmail,
        "first_name": vgccFirstName,
        "last_name": vgccLastName,
        "phone" : vgccPhone,
        "doctor" : vgccRefDoc,
        "tif_scheduled": vgccTIF
     }
     if(vgccConsnt && vgccEmail !== '' && vgccFirstName !== '' && vgccLastName !== '' && vgccPhone !== '' && vgccRefDoc !== '' && vgccTIF !== null) {
        this.props.globalStore.postVGCCDetails(obj).then((resp) => {
            this.setState({
                vgccEmail: '',
                vgccFirstName: '', 
                vgccLastName: '',
                vgccPhone: '', 
                vgccRefDoc: '',
                vgccTIF: null,
                formSubmittedSuccess: true,
                vgccConsnt: false,
                buttonDisable: false
              });
        }).catch((e)=>{
            console.log(e);
            this.setState({
                buttonDisable: false,
            })
        });
    }else {
        console.log('invalid details')
        this.setState({
            buttonDisable: false,
        })
    }
    
  }

  renderForms = () => {
       return <form className="formBody">
                <div className="formRow">
                    <div className="inp-label-cont">
                        <label className="inp-label">Name*</label>
                    </div>
                    <div className="inp-field-cont">
                        <input className="inp-field fname" type="text" name="vgccFirstName" value={this.state.vgccFirstName} placeholder="First" onChange={this.handleChange} onFocus={this.onFocusInput} required/>
                        <input className="inp-field lname" type="text" name="vgccLastName" value={this.state.vgccLastName} placeholder="Last" onChange={this.handleChange} onFocus={this.onFocusInput} required/>
                    </div>
                </div>
                <div className="formRow">
                    <div className="inp-label-cont">
                        <label className="inp-label">Email Address*</label>
                    </div>
                    <div className="inp-field-cont">
                        <input className="inp-field" type="email" name="vgccEmail" value={this.state.vgccEmail} placeholder="jane@example.com" onChange={this.handleChange} onFocus={this.onFocusInput} required />
                    </div>
                    <div className="inp-field-info">
                        <img src={require('./img/info.svg')} />
                        <span className="input-info">
                            Not shared with anyone. Only used to send care related timely information.
                        </span>
                    </div>
                </div>
                <div className="formRow">
                    <div className="inp-label-cont">
                        <label className="inp-label">Phone (Cell)*</label>
                    </div>
                    <div className="inp-field-cont">
                        <input className="inp-field" type="number" name="vgccPhone" value={this.state.vgccPhone} placeholder="(123) 456-7890"
                        onChange={this.handleChange} onFocus={this.onFocusInput} required/>
                    </div>
                    <div className="inp-field-info">
                        <img src={require('./img/info.svg')} />
                        <span className="input-info">
                            Not shared with anyone. Only used to send care related timely information.
                        </span>
                    </div>
                </div>
                <div className="formRow">
                    <div className="inp-label-cont">
                        <label className="inp-label">Referring Doctor*</label>
                    </div>
                    <div className="inp-field-cont">
                        <input className="inp-field" placeholder="Dr. Jamie Doe/Critical Care Medical" 
                        name="vgccRefDoc" value={this.state.vgccRefDoc} onChange={this.handleChange} onFocus={this.onFocusInput}>
                        </input>
                    </div>
                    <div className="inp-field-info">
                        <img src={require('./img/info.svg')} />
                        <span className="input-info">
                            Name of the Doctor who gave you information about this program – used to coordinate care with their office.
                        </span>
                    </div>
                </div>
                <div className="formRow">
                    <div className="inp-label-cont">
                        <label className="inp-label">Have you already scheduled your TIF procedure? *</label>
                    </div>
                    <div className="btn-field-cont">
                        <button className={this.state.vgccTIF === true ? "tif-selected": ""} onClick={(e) => this.selectTIF(e, true)}>Yes</button>
                        <button className={this.state.vgccTIF === false ? "tif-selected": ""} onClick={(e) => this.selectTIF(e, false)}>No</button>
                    </div>
                </div>
                <div className="formRow chkbx-cont">
                    <div className="inp-filed-checkbx">
                        <input className="inp-field" type="checkbox" name="vgccConsnt" 
                        checked={this.state.vgccConsnt} onChange={this.handleChange}  >
                        </input>
                    </div>
                    <div className="inp-label-cont">
                        <label className="inp-label">By enrolling in the program, I accept the <Link to="/terms" target="_blank" className="link">Terms of Use</Link> and <Link to="/privacy" target="_blank" className="link">Privacy</Link> and agree to be contacted by the Virtual Care Coordinator.</label>
                    </div>
                </div>
                <div className="formRow enroll-btn-cont">
                    <button type="button" onClick={() => this.submitResponse()} className={"enrollBtn"}
                    disabled={this.state.buttonDisable || !this.state.vgccConsnt || this.state.vgccEmail === '' || this.state.vgccFirstName === '' || this.state.vgccLastName === '' || this.state.vgccPhone === '' || this.state.vgccRefDoc === '' || this.state.vgccTIF === null}>ENROLL</button>
                </div>
                <div className="form-submit-success">
                    {this.state.formSubmittedSuccess && <span className="form-success-text">Thank you. We'll get in touch with you shortly.</span> }
                </div>
            </form>
  }

  render() {

    return (
      <div className="row-cont">
        <div className="side-cont">
            <div className="gerdhelp-header">
                <span className="gerd-logo"><img src={require('./img/gerdhelp.svg')} /></span>
                <span className="gerd">GERD</span><span className="help mn-clr">HELP</span><sup className="sup-scr">TM</sup>
            </div>
            <div className="main-header">
                <h2 className="mn-clr">Virtual GERD</h2> 
                <h2 className="scnd-clr"> Care Coordinator</h2>
            </div>
            <div className="content">
                <p>
                    The Virtual GERD Care Coordinator is a free personalized program to guide you through your GERD treatment journey — from diagnosis to post treatment. It is your virtual guide, working for you to help with your care. The app will also help you track the progress of your GERD symptoms, which will help your doctor better manage your care.
                    <br></br>
                    <br></br>
                    With the Virtual GERD Care Coordinator, you’ll be guided through your entire journey with the right information at the right time, helping you understand your treatment options, the benefits of treatment, and specific details about the procedure — so you are better informed and better prepared as you go through the journey. The care coordinator will follow up with you at every step, coordinating care as needed.
                </p>
            </div>
            <div className="foot-info">
                NP02673-03 A
            </div>
        </div>
        <div className="main-cont">
            <h3 className="mn-clr">SIGN UP</h3>
            <h5>for a personalized care journey</h5>

            {this.renderForms()} 
        </div>
      </div>
    );
  }
}

export default VGCC;
