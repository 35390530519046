import React, { Component, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import ImageFade from './ImageFade'
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import { createBrowserHistory } from 'history'

@inject('globalStore')
@observer class PersonalizeJourney extends Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory()
        this.imageList = [
            '../../img/isdoctorplan-bariatric1@3x',
         ]

        this.state = {
            currentArea: '',
            renderBlock: {
                title: window.innerWidth < '767' ? '' : 'Patient Intake',
                imgKey: 0,
            },
            imgKey: 0
            
        }
    }

    componentDidMount() {}

    renderImage() {
        switch (this.state.renderBlock.title) {
            case "Patient Intake":
                return <img src={require("../../img/patient_intake_big.png")} alt="" />
            case "Medical History, Prior Treatments":
                return <img src={require("../../img/medical_history_big.png")} alt="" />
            case "Reminders":
                return <img src={require("../../img/reminders_big.png")} alt="" />
            case "Patient Education":
                return <img src={require("../../img/patient_education_big.png")} alt="" />
            case "Pre and Post - Procedure Instructions":
                return <img src={require("../../img/procedure_instructions_big.png")} alt="" />
            case "Patient Reported Outcomes (PROs)":
                return <img src={require("../../img/reported_outcomes_big.png")} alt="" />
            case "Post - Procedure Check In":
                return <img src={require("../../img/post_procedure_check_in_big.png")} alt="" />
            case "Follow Up Visits":
                return <img src={require("../../img/follow_up_visits_big.png")} alt="" />
            case "Early Interventions":
                return <img src={require("../../img/early_interventions_big.png")} alt="" />
            case "Video Visits":
                return <img src={require("../../img/video_visits_big.png")} alt="" />
            case "Feedback and Reviews":
                return <img src={require("../../img/feedback_and_reviews_big.png")} alt="" />

            default:
                return null;
        }
    }

    switchBlock(area, title) {
        // for toggling in mobile version
        if (window.innerWidth < '767' && this.state.renderBlock.title === title) {
            this.setState({ renderBlock: {
                title: '',
                imgKey: null
            }})
            return
        }

       const newImgKey = this.state.renderBlock.imgKey + 1
       switch(area) {
           case 'patient_intake': 
           this.setState({ renderBlock : {
            title:'Patient Intake',
            imgKey: newImgKey
           }
        })
           break
           case 'medical_history':
           this.setState({ renderBlock : {
            title:'Medical History, Prior Treatments',
            imgKey: newImgKey
           }
         })
         break
         case 'reminders':
          this.setState({ renderBlock : {
           title:'Reminders',
           imgKey: newImgKey
          }
       })
            break
            case 'patient_education':
            this.setState({ renderBlock : {
                title:'Patient Education',
                imgKey: newImgKey
            }
            })
            break
            case 'procedure_instructions':
            this.setState({ renderBlock : {
             title:'Pre and Post - Procedure Instructions',
             imgKey: newImgKey
            }
          })
          break
          case 'reported_outcomes':
          this.setState({ renderBlock : {
           title:'Patient Reported Outcomes (PROs)',
           imgKey: newImgKey
          }
        })
          break
          case 'post_procedure_check_in':
          this.setState({ renderBlock : {
           title:'Post - Procedure Check In',
           imgKey: newImgKey
          }
        })
          break
          case 'follow_up_visits':
          this.setState({ renderBlock : {
           title:'Follow Up Visits',
           imgKey: newImgKey
          }
        })
          break
          case 'early_interventions':
          this.setState({ renderBlock : {
           title:'Early Interventions',
           imgKey: newImgKey
          }
        })
           break
           case 'video_visits':
           this.setState({ renderBlock : {
            title:'Video Visits',
            imgKey: newImgKey
           }
         })
            break
           case 'feedback_and_reviews':
            this.setState({ renderBlock : {
            title:'Feedback and Reviews',
            imgKey: newImgKey
            }
        })
           break
           default:
       }
    }

    render() {
        return (
            <div>
                <section class="practice">
                    <div class="container">
                        <div class="row">
                            <div class="col heading-content">
                                <h2 class="text-md-center fts-42">Personalize the entire patient journey</h2>
                                <p class="text-md-center marginCustom30" >Automate clinical workflows to improve patient outcomes, drive clinical efficiencies and increase patient satisfaction. Configured for each area of medicine, and specific to conditions and treatment plans.</p>
                            </div>
                        </div>
                        <div class="practice-content">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <ul id="tabs" className="isdocplantabs">
                                        <li onClick={(e) => {this.switchBlock('patient_intake', 'Patient Intake')}}>
                                            <img class="category-icon" src={require("../../img/patient_intake.png")} alt="" />
                                            <a  id="tb_1" class="tabmenu" className={(this.state.renderBlock.title === 'Patient Intake') ? 'active': '' }>Patient Intake</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Patient Intake') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Patient Intake') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('medical_history','Medical History, Prior Treatments')}}>
                                            <img class="category-icon" src={require("../../img/medical_history.png")} alt="" /> 
                                            <a  id="tb_2" class="tabmenu" className={(this.state.renderBlock.title === 'Medical History, Prior Treatments') ? 'active': '' }>Medical History, Prior Treatments</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Medical History, Prior Treatments') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Medical History, Prior Treatments') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('reminders','Reminders')}}>
                                            <img class="category-icon" src={require("../../img/reminders.png")} alt="" /> 
                                            <a  id="tb_5" class="tabmenu" className={(this.state.renderBlock.title === 'Reminders') ? 'active': ''}>Reminders </a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Reminders') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Reminders') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('patient_education', 'Patient Education')}}>
                                            <img class="category-icon" src={require("../../img/patient_education.png")} alt="" /> 
                                            <a  id="tb_6" class="tabmenu" className={(this.state.renderBlock.title === 'Patient Education') ? 'active': ''}>Patient Education</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Patient Education') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Patient Education') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('procedure_instructions', 'Pre and Post - Procedure Instructions')}}>
                                            <img class="category-icon" src={require("../../img/procedure_instructions.png")} alt="" /> 
                                            <a  id="tb_3" class="tabmenu" className={(this.state.renderBlock.title === 'Pre and Post - Procedure Instructions') ? 'active': '' }>Pre and Post - Procedure Instructions</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Pre and Post - Procedure Instructions') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Pre and Post - Procedure Instructions') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('reported_outcomes', 'Patient Reported Outcomes (PROs)')}}>
                                            <img class="category-icon" src={require("../../img/reported_outcomes.png")} alt="" /> 
                                            <a id="tb_7" class="tabmenu" className={(this.state.renderBlock.title === 'Patient Reported Outcomes (PROs)') ? 'active': ''}>Patient Reported Outcomes (PROs)</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Patient Reported Outcomes (PROs)') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Patient Reported Outcomes (PROs)') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('post_procedure_check_in', 'Post - Procedure Check In')}}>
                                            <img class="category-icon" src={require("../../img/post_procedure_check_in.png")} alt="" /> 
                                            <a  id="tb_8" class="tabmenu" className={(this.state.renderBlock.title === 'Post - Procedure Check In') ? 'active': ''}>Post - Procedure Check In</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Post - Procedure Check In') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Post - Procedure Check In') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('follow_up_visits', 'Follow Up Visits')}}>
                                            <img class="category-icon" src={require("../../img/follow_up_visits.png")} alt="" /> 
                                            <a  id="tb_9" class="tabmenu" className={(this.state.renderBlock.title === 'Follow Up Visits') ? 'active': ''}>Follow Up Visits</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Follow Up Visits') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Follow Up Visits') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('early_interventions', 'Early Interventions')}}>
                                            <img class="category-icon" src={require("../../img/early_interventions.png")} alt="" /> 
                                            <a id="tb_11" class="tabmenu" className={(this.state.renderBlock.title === 'Early Interventions') ? 'active': ''}>Early Interventions</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Early Interventions') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Early Interventions') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('video_visits', 'Video Visits')}}>
                                            <img class="category-icon" src={require("../../img/video_visits.png")} alt="" /> 
                                            <a  id="tb_4" class="tabmenu" className={(this.state.renderBlock.title === 'Video Visits') ? 'active': '' }>Video Visits</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Video Visits') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Video Visits') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                        <li onClick={(e) => {this.switchBlock('feedback_and_reviews', 'Feedback and Reviews')}}>
                                            <img class="category-icon" src={require("../../img/feedback_and_reviews.png")} alt="" /> 
                                            <a id="tb_10" class="tabmenu" className={(this.state.renderBlock.title === 'Feedback and Reviews') ? 'active': ''}>Feedback and Reviews</a>
                                            { window.innerWidth < '767' ? 
                                                ((this.state.renderBlock.title === 'Feedback and Reviews') ? 
                                                    <img class="plus-minus" src={require("../../img/minus.png")} alt="" /> : 
                                                    <img class="plus-minus" src={require("../../img/plus.png")} alt="" /> 
                                                ) : null
                                            }
                                        </li>
                                        { (window.innerWidth < '767' && this.state.renderBlock.title === 'Feedback and Reviews') ? <div class="mobile-image">{this.renderImage()}</div> : null }
                                    </ul>
                                </div>
                                <div class="col-lg-8 col-md-8 d-none d-sm-block d-md-block d-lg-block" style={{'z-index': '10'}}>
                                    { this.renderImage() }
                                </div>
                            </div>
                            <br></br>
                            <div class="d-flex justify-content-center" style={{'margin-top': '60px'}}>
                                <Link id="schedule-demo-btn" to="/join-us" href="">Schedule Demo</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(PersonalizeJourney);
