import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link, withRouter, NavLink }  from 'react-router-dom';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";

@inject('globalStore')
@observer class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
       activeTab: 'home',
       downLoadText: 'Did your doctor ask you to download the DoctorPlan app? ',
       toggleBlueHeader: true,
       hamburgerClickColor: ''
    }
  }

  componentDidMount() {
    /*header stickey*/
    function throttle(fn, delay) {
      var last = undefined;
      var timer = undefined;
        
      return function () {
        var now = +new Date();

        if (last && now < last + delay) {
        clearTimeout(timer);

        timer = setTimeout(function () {
          last = now;
          fn();
        }, delay);
        } else {
        last = now;
        fn();
        }
      };
    }
    function onScroll() {
      if( window.location.pathname !== '/landing/msk' && window.location.pathname !== '/landing/msk2') {
        var header = document.getElementById("header")
        if (header) {
          if (window.pageYOffset) {
            header.classList.add('is-active');
          } else {
            document.body.style.paddingTop = 0;
            header.classList.remove('is-active');
          }
        }
      }
    }
    var $$header = document.querySelector('.js-header');
    window.addEventListener('scroll', throttle(onScroll, 25));
    /*header stickey*/
    /*mobile contact us btn header*/ 
    function contactUsBtn() {
      var itm = document.getElementById("contactUsBtn");
      var cln = itm.cloneNode(true);
      document.getElementById("menu-contatct-us").appendChild(cln);
    }
  }


  /*menu */
  toggleMenu = () => {
    var element = document.getElementById("hamburger");
    element.classList.toggle("collapsed");
    var element01 = document.getElementById("navbarSupportedContent");
    element01.classList.toggle("show");

    // change color
    if (this.state.hamburgerClickColor && this.state.hamburgerClickColor === '#2d3e50')
      this.setState({hamburgerClickColor: ''})
    else this.setState({hamburgerClickColor: '#2d3e50'})
  }


  hideHeader = () => {
    this.setState({
      toggleBlueHeader: false
    });
  }


  getColor = () => {
    if (this.state.hamburgerClickColor === '#2d3e50') 
      return this.state.hamburgerClickColor
    else
      return this.props.color
  }

  render() {
    const { downLoadText,toggleBlueHeader } = this.state
    const { location } = this.props
    let contactClass = 'btn contact-us-btn '
    if(location.pathname === '/' || location.pathname.includes('/for-patient') || location.pathname.includes('/join-us'))
      contactClass += ' contact-us-btn-bdr'


    return (
      <div>
        { location.pathname === '/landing/msk' || location.pathname === '/landing/msk2' ? <div></div> :
          <div>
              <header id="header" className="header sticky sticky--top js-header">
                
                  <nav className="navbar navbar-expand-xl navbar-light bg-light full-width">
                    <Link className="navbar-brand" to="/" >
                      { this.getColor() === 'white' ? <img src={require("../../img/doctorPlan-white.png")} alt="" /> : <img src={require("../../img/doctorPlan-group@3x.png")} alt="" /> }
                    </Link>

                    <button onClick={this.toggleMenu} id="hamburger" className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span style={{"background-color": this.getColor()}}> </span>
                      <span style={{"background-color": this.getColor()}}> </span>
                      <span style={{"background-color": this.getColor()}}> </span>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item" onClick={this.toggleMenu}>
                          <Link 
                            style={{"color": this.getColor(), "borderColor": this.getColor()}} 
                            to="/" 
                            className={(location.pathname === '/') ? 'nav-link header-active': 'nav-link'}>
                              Home
                          </Link>
                        </li>
                        <li className="nav-item" onClick={this.toggleMenu}>
                          <Link style={{"color": this.getColor(), "borderColor": this.getColor()}} to="/how-it-works" className={(location.pathname === '/how-it-works') ? 'nav-link header-active': 'nav-link'}>How It Works</Link>
                        </li>
                        <li className="nav-item" onClick={this.toggleMenu}>
                          <Link style={{"color": this.getColor(), "borderColor": this.getColor()}} href="#" to="/about-us" className={(location.pathname === '/about-us') ? 'nav-link header-active': 'nav-link'}>About</Link>
                        </li>
                        
                        { window.innerWidth > 1200 ?
                          <li className="nav-item">
                            <Link style={{"color": this.getColor(), "borderColor": this.getColor()}} to="/for-patient" id="forpatientBtn"  className='btn contact-us-btn' className={(location.pathname === '/for-patient') ? 'nav-link header-active': 'nav-link'} className={(this.props.globalStore.showCTA === false) ? (contactClass+' contact-us-btn-bdr') :  contactClass  }>For Patients</Link>
                          </li>
                          :
                          <li className="nav-item" onClick={this.toggleMenu}>
                            <Link style={{"color": this.getColor(), "borderColor": this.getColor()}} href="#" to="/for-patient" className={(location.pathname === '/for-patient') ? 'nav-link header-active': 'nav-link'}>For Patients</Link>
                          </li>
                        }
                      </ul>
                      <div class="d-flex schedule-container">
                          <Link id="schedule-demo-btn" to="/join-us" href="">Schedule Demo</Link>
                      </div>
                    </div>
                    <Link id="contactUsBtn" to="/join-us" className='btn' href=""> { window.innerWidth > 991 ? 'Schedule Demo' : 'Demo'}</Link>
                  </nav>
                
              </header>
            </div>
            }
          </div>
        )
      }
}

const RoutedHeader = withRouter(props => <Header {...props}/>)

export default RoutedHeader;