import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link }  from 'react-router-dom';
import Lottie from 'react-lottie';
import ScrollAnimation from 'react-animate-on-scroll';
import Ticker from "../common/Ticker";
import { Waypoint } from 'react-waypoint';
import { Carousel } from 'react-bootstrap'
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";

// Animation files
import topAnimation from '../../animation/topAnimation.json';
import bottomAnimationOne from '../../animation/bottomAnimationOne.json';
import bottomAnimationTwo from '../../animation/bottomAnimationTwo.json';
import appointmentChecklistAnimation from '../../animation/appointmentChecklistAnimation.json';
import copyAnnasHealthAnimation from '../../animation/copyAnnasHealthAnimation.json';
import copyAnnasHealthAnimationTablet from '../../animation/Copy-Chart-Note-Button-Tablet.json';
import copyAnnasHealthAnimationMobile from '../../animation/Copy-Chart-Note-Button-Mobile.json';
import appointmentPlanningAnimation from '../../animation/appointment_planning.json';
import yogaAnimation from '../../animation/yoga.json';
import learnAnimation from '../../animation/learn.json';
import medicationAnimation from '../../animation/medication.json';
import lifestyleAnimation from '../../animation/lifestyle.json';
import messageAnimation from '../../animation/message.json';
import questionnaireAnimation from '../../animation/questionnaires.json';
import symptomAnimation from '../../animation/symptoms.json';
import vitalsAnimation from '../../animation/vitals.json';

@inject('globalStore')

@observer class Gastroenterology extends Component {
  constructor(props) {
    super(props);

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.state = {
      pageScrollBar: null,
      stickyBar: null,
      month: months[new Date().getMonth()],
      renderComplianceChecklistsLottie: false,
      renderAppoitmentChecklistLottie: false,
      rendercopyButton: false,
      changeStickyBar: true,
      lastId: null,
      curr: [],
      indexAnimationArr: [0,0,0,0,0,0,0,0],
      indexArr: [0,0,0,0,0,0,0,0],
      startBootomLoop: false,
      isStickyOnce: false,
      isScrollDirectionUp: false,
      selectedIndex: 0,
    };
  }

  componentDidMount() {
    document.title= 'Clinical Care Pathways for Gastroenterology - DoctorPlan';
    let pageScrollBar = document.getElementById("pageScrollBar");
    this.setState({
      pageScrollBar,
      stickyBar: this.getPositionY(pageScrollBar),
    });  
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() { 
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  // getCalendar(daysAdded) {
  //   var today = new Date();
  //   var newCalendar = new Date(new Date().setDate(today.getDate() + daysAdded));

  //   var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   return {
  //     month: months[newCalendar.getMonth()],
  //     date: newCalendar.getDate(),
  //   };
  // }

  getPositionY(element) {
    var yPosition = 0;
    // add up all parent's offsets to find distance from body
    while(element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }
    return yPosition;
  }


  activeBottomNavigation = () => {
    const { selectedIndex, isScrollDirectionUp } = this.state;
    let mainNavLinks = document.querySelectorAll("#pageScrollBar div a");
    let fromTop = window.scrollY;

    mainNavLinks.forEach((link, index) => {
      let section = document.querySelector(link.hash);
      if (
        section.offsetTop - (section.offsetHeight * 0.005) <= fromTop &&
        section.offsetTop + (section.offsetHeight * 0.995) > fromTop
      ) {
        link.classList.add("active");

        // for active animation index
        const newId = this.state.indexAnimationArr.slice();
        newId[index] = 1;
        this.setState({indexAnimationArr: newId});

        // for all active states
        const activeId = this.state.indexArr.slice();
        for(let i = 0; i <= index; i++) {
          activeId[i] = 1;
        }
        this.setState({indexArr: activeId, selectedIndex: index});
      } else {
        link.classList.remove("active");

        // for active animation index
        const newId = this.state.indexAnimationArr.slice();
        newId[index] = 0;
        this.setState({indexAnimationArr: newId});

        if(!isScrollDirectionUp) {
          // for all active states
          const activeId = this.state.indexArr.slice();
          for(let i = selectedIndex; i <= mainNavLinks.length; i++) {
            activeId[i] = 0;
          }
          this.setState({indexArr: activeId});
        }
      }
    });
  }

  scrollDirection = () => {
    const { scrollPos } = this.state;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > scrollPos)
      this.setState({ isScrollDirectionUp: true });
    else
      this.setState({ isScrollDirectionUp: false });

    this.setState({ scrollPos: scrollTop <= 0 ? 0 : scrollTop }) ;
  }

  handleScroll = () => {
    const { isStickyOnce } = this.state;

    if (!isStickyOnce) {
      const viewpointHeight = document.documentElement.clientHeight;
      const pageScrollBarHeight = this.state.pageScrollBar.getBoundingClientRect().height;
      if (window.pageYOffset <= this.state.stickyBar - viewpointHeight + pageScrollBarHeight) {
        this.state.pageScrollBar.classList.add("stickyBar");
        this.setState({changeStickyBar: true});

      } else {
        this.state.pageScrollBar.classList.remove("stickyBar");
        this.setState({changeStickyBar: false, isStickyOnce: true});
      }
      this.activeBottomNavigation();
      this.scrollDirection();
    }
  }

  onCompleteBottomAnimation = () => {
    this.setState({ startBootomLoop: true });
    console.log('yes');
  }

  render() {
    let {
      renderAppoitmentChecklistLottie,
      changeStickyBar,
      rendercopyButton,
      indexAnimationArr,
      indexArr,
      startBootomLoop,
    } = this.state;
    const topAnimationOptions = {
      loop: true,
      autoplay: true,
      animationData: topAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const bottomAnimationOptionsOne = {
      loop: false,
      autoplay: true,
      animationData: bottomAnimationOne,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const bottomAnimationOptionsTwo = {
      loop: true,
      autoplay: true,
      animationData: bottomAnimationTwo,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const appointmentChecklistOptions = {
      loop: false,
      autoplay: true,
      animationData: appointmentChecklistAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationTabletOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimationTablet,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const copyAnnasHealthAnimationMobileOptions = {
      loop: false,
      autoplay: true,
      animationData: copyAnnasHealthAnimationMobile,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const appointmentPlanningAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: appointmentPlanningAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const yogaAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: yogaAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const medicationAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: medicationAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const learnAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: learnAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const lifestyleAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: lifestyleAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const messageAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: messageAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const questionnaireAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: questionnaireAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const symptomAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: symptomAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
    const vitalsAnimationOptions = {
      loop: false,
      autoplay: true,
      animationData: vitalsAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div>
        <section className="timelineHeader">
          <div className="container-lg">
          { window.innerWidth > 767 ?
            <div className="timeline-row row align-items-center justify-content-center mx-5">
            <div className="col-lg-5 col-md-4 p-0">
              <div className="row p-0">
                <div className="col p-0 my-2">
                  <div className="headerCalendar text-center">
                    <h1 className="month">{this.state.month}</h1>
                    <p class="day">25</p>
                  </div>
                </div>
                <div className="col-10 px-2">
                  <h3>New Patient Consult</h3>
                  <p className="heading-desc">DoctorPlan is pre-configured with best practice clinical care pathways - an example for a “New Patient Consult” appointment is below. It includes information sent to the patient, as well as health data collected from the patient prior to the visit.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <Lottie options={topAnimationOptions} />
            </div>
          </div> :
          <>
            <div className="timeline-row row align-items-center justify-content-center mx-5">
              <div className="col-sm-12 px-3">
                <Lottie options={topAnimationOptions} />
              </div>
            </div>
            <div className="timeline-row row align-items-center justify-content-center mx-5 pt-5">
              <div className="col-sm-12 p-0">
                <div className="row align-items-center justify-content-center">
                  <div className="col-sm-3 p-0 mb-4 mr-20">
                    <div className="headerCalendar text-center">
                      <h1 className="month">{this.state.month}</h1>
                      <p class="day">25</p>
                    </div>
                  </div>
                  <h3 className="col text-left vertical-center p-0 mb-4">New Patient Consult</h3>
                  <p className="heading-desc text-left">DoctorPlan is pre-configured with best practice clinical care pathways - an example for a “New Patient Consult” appointment is below. It includes information sent to the patient, as well as health data collected from the patient prior to the visit.</p>
                </div>
              </div>
            </div>
          </>
          }
          </div>
        </section>

        <section className="timelineBody">
          <div className="ml-sm-4 ml-lg-5">
            <ul class="timeline ml-lg-5">
              <li>
                <div className="oval"></div>
              </li>
              <li id="section-1" className="clinical-care">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">4</p>
                  <p className="time">11:00 AM</p>
                </div>
                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0">
                    <div className="clinicalCare timeline-wrapper">
                      <div class="timeline-badge message"></div>
                      <h4 class="timeline-heading">Clinical Care Pathways</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Make it easy for your patients to be guided along their entire healthcare journey – from the first call to recovery. The DoctorPlan app helps your patients maintain a digital health diary, and share information with you, so you are with them every step of the way.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel">
                      <ScrollAnimation delay={0} duration={0.8} animateOnce={true} animateIn="rotateInUpLeft">
                        <div className="speechBox">
                          <h3 class="timeline-heading">Welcome to the Digestive Care Center!</h3>
                          <div class="timeline-body">
                            { window.innerWidth > 991 ?
                              <p className="m-0">You’ve been scheduled for an IBD / IBS Consult. We use DoctorPlan to help you prepare for your consultation and guide you through your diagnostic and care plan. Over the next few weeks, you will receive timely information about what to expect at your appointment, more information about IBD and IBS, and intake and check in questionnaires – these help you and your doctor collaborate on your treatment plan and stay on top of your health.</p> :
                              <p className="m-0">You’ve been scheduled for an IBD / IBS Consult. We use DoctorPlan to help you prepare for your consultation and guide you through your diagnostic and care plan. Over the next few weeks…</p>
                            }
                          </div>
                        </div>
                        <p className="seenByAnna">Seen by Emma
                          <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-2" className="patient-edu">                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0">
                    <div className="patientEdu timeline-wrapper">
                      <div class="timeline-badge learn"></div>
                      <h4 class="timeline-heading">Patient Education and Engagement</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Educate your patients with the right information at the right time. Automatically deliver easy to read articles on IBS and IBD; Diagnostic procedures – colonoscopy, sigmoidoscopy, ERCP, PEG Tube; Celiac Disease and Lactose Intolerance; and much more.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel-2">
                      <Carousel interval={3000}>
                        <Carousel.Item>
                          <h3 class="timeline-heading">What is Irritable Bowel Syndrome?</h3>
                          <div>
                            <img src={require("../../img/1-what-is-irritable-bowel-syndrome@3x.jpg")} className="img-fluid" alt="" />
                          </div>
                          <div class="timeline-body">
                            <p>Irritable bowel Syndrome (IBS) is a common chronic disorder that affects the large intestine. This order has a group of symptoms which occur together, including bloating...</p>
                          </div>
                          <p className="seenByAnna">Seen by Emma
                            <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                          </p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <h3 class="timeline-heading">Understanding Flexible Sigmoidoscopy</h3>
                            <div>
                              <img src={require("../../img/during-a-colonoscope-02@3x.jpg")} className="img-fluid" alt="" />
                            </div>
                            <div class="timeline-body">
                              <p>A colonoscopy is a common procedure that helps your doctor examine your entire colon. However, sometimes only the sigmoid colon warrents close inspection.</p>
                            </div>
                            <p className="seenByAnna">Seen by Emma
                              <img src={require("../../img/group-222.svg")} className="img-fluid pl-2" alt="" />
                            </p>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-3" className="intake-questionnaires">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">11</p>
                  <p className="time">1:00 PM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-10 col-sm-11 p-0">
                    <div className="intakeQuestionnaires timeline-wrapper">
                      <div class="timeline-badge questionnaires"></div>
                      <h4 class="timeline-heading">Intake Questionnaires</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Ask your patients to fill out interactive questionnaires in the comfort of their home – where they have the required information, and time to gather the pieces they don’t. 
                          <br></br><br></br>
                          Leverage best practice questionnaires including New Patient GI Intake, IBS/IBD Intake, Review of Systems, and IBS/IBD Monthly Check In.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-10 center-col">
                    <div class="timelinePanel-3">
                      <h3><img src={require("../../img/group-71.svg")} className="img-fluid" alt="" />New Patient GI Intake</h3>
                      <Carousel interval={3000}>
                        <Carousel.Item>
                          <div class="timeline-body row">
                            <div className="col p-0">
                              <img src={require("../../img/Gastro-IBD-IBS-1@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                            <div className="col p-0">
                              <img src={require("../../img/Gastro-IBD-IBS-2@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div class="timeline-body row">
                            <div className="col p-0">
                              <img src={require("../../img/Gastro-Patient-Intake-1@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                            <div className="col p-0">
                              <img src={require("../../img/Gastro-Patient-Intake-2@3x.png")} className="img-fluid px-2" alt="" />
                            </div>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-4" className="mindfulness-excercise">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">14</p>
                  <p className="time">11:00 AM</p>
                </div>
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">16</p>
                  <p className="time">11:00 AM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-5 col-md-6 col-sm-11 p-0"> 
                    <div className="mindfulnessExcercise timeline-wrapper">
                    <div class="timeline-badge mind"></div>
                      <h4 class="timeline-heading">Mindfulness Exercises</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Provide your patients with stress relieving and calming exercises to help them cope with their symptoms at home. DoctorPlan includes guided mindfulness and yoga practices for patients to use at their leisure.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 center-col">
                    <div class="timelinePanel-9">
                      <h3 class="timeline-heading">Mindfulness Session</h3>
                      <div>
                        <img src={require("../../img/mindfulness-image@3x.png")} className="img-fluid" alt="" />
                      </div>
                      <div class="timeline-body">
                        <p className="sub-heading">Basic - Day 1 </p>
                        <p>The Basic 10-day series is designed to introduce you to the practice of mindfulness.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="lifestyle-vitals">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">16</p>
                  <p className="time">11:00 AM</p>
                </div>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="lifestyleVitals timeline-wrapper">
                      <h4 class="timeline-heading">Symptoms, Vitals and Lifestyle Tracking</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Encourage your patients to log their symptoms as they experience them - frequency, severity, and relevant details – to help inform your treatment plan.  Track vitals, mood, sleep and stress for a complete picture.
                          <br></br><br></br>
                          Included is a food diary with macronutrients, water intake and bowel movement – so you can correlate objective and subjective.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li id="section-5" className="symptom">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header symptoms"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#e28816"}}> <img src={require("../../img/group-30.svg")} className="img-fluid" alt="" />Abdominal Pain</p>
                      </div>
                      <div class="timeline-body mx-auto comp-1">
                        <p>1. How would you describe it?</p>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Squeezing</div>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">Cramps</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Gnawing</div>
                          </div>
                          <div className="col center-col p-0">
                              <ScrollAnimation delay={0} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                                <div className="options">Throbbing</div>
                              </ScrollAnimation>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={1000} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">Tender to Touch</div>
                            </ScrollAnimation>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">Pressure</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Choking</div>
                          </div>
                          <div className="col center-col p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#e28816"}}> <img src={require("../../img/bloating.svg")} className="img-fluid" alt="" />Bloating</p>
                      </div>
                      <div class="timeline-body mx-auto comp-1">
                        <p>2. Where is the bloating?</p>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Stomach</div>
                          </div>
                          <div className="col center-col p-0">
                            <div className="options">Upper Abdomen</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={0} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">Lower Abdomen</div>
                            </ScrollAnimation>
                          </div>
                          <div className="col center-col p-0">
                            <ScrollAnimation delay={1000} duration={1} animateOnce={true} initiallyVisible={true} animateIn="selected">
                              <div className="options">Sides</div>
                            </ScrollAnimation>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col center-col p-0">
                            <div className="options">Pelvic Region</div>
                          </div>
                          <div className="col center-col p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-6" className="vital">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header vitals"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#709f34"}}> <img src={require("../../img/group-26.svg")} className="img-fluid" alt="" />Weight</p>
                      </div>
                      <div class="timeline-body row mx-auto vertical-center comp-2 number-counter" >
                        <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                        <div className="col p-0 count">
                          <Ticker start={0} end={292} duration={2}/>
                          <div>lbs</div>
                        </div>
                        <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#709f34"}}> <img src={require("../../img/blood-pressure.svg")} className="img-fluid" alt="" />Blood Pressure</p>
                      </div>
                      <div class="timeline-body mx-auto pb-5">
                        <div className="row mx-auto vertical-center mb-4 comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={120} end={143} duration={2}/>
                            <div>SYS</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="row mx-auto vertical-center mb-4 comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={80} end={95} duration={2}/>
                            <div>DIA</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="row mx-auto vertical-center comp-2 number-counter">
                          <div className="col p-0"><img src={require("../../img/group-155.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 count">
                            <Ticker start={80} end={72} duration={2}/>
                            <div>bpm</div>
                          </div>
                          <div className="col p-0"><img src={require("../../img/group-161.svg")} className="img-fluid" alt="" /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-7" className="food">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header lifestyle"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#2d3e4f"}}> <img src={require("../../img/group-96.svg")} className="img-fluid" alt="" />Food</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-3">
                        <p style={{backgroundColor: "#F3F9FD"}} className="mx-0">Mon, Jun 14</p>
                        <div className="row py-4 text-left log">
                          <div className="col p-0"><img src={require("../../img/group-49.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                          <div className="col-8 px-2">
                            <div className="label">Breakfast</div>
                            <div class="content">Bagel / Cream Cheese</div>
                          </div>
                            <div className="value col-lg-2 col-md-3 col-sm-3 p-0">                              
                              <Ticker start={100} end={210} duration={2} suffix=" cal"/>
                            </div>
                        </div>
                        <div className="row py-lg-4 py-md-3 py-sm-3 text-left">
                          <div className="col p-0"><img src={require("../../img/group-40.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                          <div className="col-8 px-2">
                            <div className="label">Lunch</div>
                            { window.innerWidth > 991 ?
                              <div class="content">Chicken Salad, 1 bowel, 2 servings</div> :
                              <div class="content">Chicken Salad, 1 bowel</div> 
                            }
                          </div>
                          <div className="value col-lg-2 col-md-3 col-sm-3 p-0">
                            <Ticker start={200} end={360} duration={2} suffix=" cal"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 d-md-none"></div>
                  <div className="col-lg-6 col-md-6">
                  <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#2d3e4f"}}> <img src={require("../../img/group-96-1.svg")} className="img-fluid" alt="" />Bowel Movement</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-3">
                        <p style={{backgroundColor: "#F3F9FD"}} className="mx-0">Jun 8 - Jun 14</p>
                        <div className="row log">
                          <div class="col p-0">
                            <div class="row text-left vertical-center py-1">
                              <div className="col-lg-3 col-md-4 col-sm-4 p-0 bar-name">Brown</div>
                              <div className="col-lg-7 col-md-6 col-sm-6 px-1">
                                <ScrollAnimation delay={0} duration={1} animateOnce={true} animateIn="bar-1"></ScrollAnimation>
                              </div>
                              <div className="col p-0 bar-tag">4 times</div>
                            </div>
                            <div class="row text-left vertical-center py-1">
                              <div className="col-lg-3 col-md-4 col-sm-4 p-0 bar-name">Dark Green</div>
                              <div className="col-lg-7 col-md-6 col-sm-6 px-1">                                
                                <ScrollAnimation delay={0} duration={1} animateOnce={true} animateIn="bar-2"></ScrollAnimation>
                              </div>
                              <div className="col p-0 bar-tag">2 times</div>
                            </div>
                            <div class="row text-left vertical-center py-1">
                              <div className="col-lg-3 col-md-4 col-sm-4 p-0 bar-name">Red</div>
                              <div className="col-lg-7 col-md-6 col-sm-6 px-1">  
                                <ScrollAnimation delay={0} duration={1} animateOnce={true} animateIn="bar-3"></ScrollAnimation>
                              </div>
                              <div className="col p-0 bar-tag">1 time</div>
                            </div>
                          </div>
                        </div>
                        <div className="row py-4">
                          <div class="col p-0">
                            <div class="row text-left vertical-center">
                              <div className="col p-0"><img src={require("../../img/poop-yellow-type-6-copy-2.svg")} className="img-fluid p-0 bowel-img-2" style={{width: "60%"}} alt="" /></div>
                              <div className="col-8 p-0 bar-name">Type 6</div>
                              <div className="col p-0 bar-tag">4 times</div>
                            </div>
                            <div class="row text-left vertical-center">
                              <div className="col p-0"><img src={require("../../img/group-13.svg")} className="img-fluid p-0 bowel-img-2" alt="" /></div>
                              <div className="col-8 p-0 bar-name">Type 5</div>
                              <div className="col p-0 bar-tag">1 time</div>
                            </div>
                            <div class="row text-left vertical-center">
                              <div className="col p-0"><img src={require("../../img/poop-yellow-type-4-copy-2.svg")} className="img-fluid p-0 bowel-img-2" alt="" /></div>
                              <div className="col-8 p-0 bar-name">Type 4</div>
                              <div className="col p-0 bar-tag">1 time</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-8" className="medicationsAdherence">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5">
                      <div class="timeline-badge no-header medications"></div>
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#c45531"}}> <img src={require("../../img/group-133.svg")} className="img-fluid" alt="" />Medications</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-4">
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Prevacid 30 mg -<p className="mx-1">All Days</p></div>
                        </div>
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Delzicol 400 mg x 2 -<p className="mx-1">All Days</p></div>
                        </div>
                        <div className="row py-0 text-left vertical-baseline">
                          <div className="col-2 p-0"><img src={require("../../img/group-28.svg")} className="img-fluid" alt="" /></div>
                          <div className="col p-0 medication meds-description">Plavix 300 mg -<p className="mx-1">As Needed</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-5 second">
                      <div class="timeline-heading text-center py-1">
                        <p style={{color:"#c45531"}}> <img src={require("../../img/group-133.svg")} className="img-fluid" alt="" />Adherence</p>
                      </div>
                      <div class="timeline-body text-center mx-auto comp-4">
                        <div className="row py-2 text-left vertical-center">
                          <div className="col p-0 meds-description"></div>
                          <div className="col p-0 meds-dosage">
                          <div className="row text-center p-0">
                              <div className="col px-1 meds-label">Always had</div>
                              <div className="col px-1 meds-label">Missed a few</div>
                              <div className="col px-1 meds-label">Never had</div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Prevacid 30 mg</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-orange-exclamation.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-red-cross.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Delzicol 400 mg</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/default-green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/group-111-2.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-red-cross.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 text-left vertical-center">
                          <div className="col p-0 meds-description">Plavix 300 mg</div>
                          <div className="col p-0 meds-dosage">
                            <div className="row text-center p-0">
                              <div className="col px-1"><img src={require("../../img/default-green-check.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/default-orange-exclamation.svg")} className="img-fluid" alt="" /></div>
                              <div className="col px-1"><img src={require("../../img/group-110.svg")} className="img-fluid" alt="" /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="section-9" className="appointment-planning">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">23</p>
                  <p className="time">6:00 PM</p>
                </div>                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="appointmentPlanning timeline-wrapper">
                      <div class="timeline-badge appointment"></div>
                      <h4 class="timeline-heading">Appointment Planning</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Guide your patients to prepare for their appointment – with suggested topics of discussion, all specific to their care timeline, and history of present illness.
                          <br></br><br></br>
                          Your patients can choose from relevant topics associated with symptoms, causes / risk factors, diagnosis / treatment, coping / living with IBS/IBD, Celiac, GERD or Lactose Intolerance as well as medications and lifestyle. You are better prepared for the visit and cover areas important to your patients.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-6 mx-auto" style={{ marginTop: "23px"}}>
                      <div class="timeline-heading overlap text-left">
                        <p style={{color:"#e28816"}} className="p-0"> <img src={require("../../img/group.svg")} className="img-fluid" alt="" />Symptoms Summary</p>
                      </div>
                      <div class="timeline-body">
                        <div className="summary">
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/group-2.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Abdominal Pain</div>
                            <div className="col-4 des">8 times</div>
                          </div>
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/group-81.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Bloating</div>
                            <div className="col-4 des">4 times</div>
                          </div>
                          <div className="row text-left">
                            <div className="col-2 p-0 text-center"><img src={require("../../img/heartburn.svg")} className="img-fluid" style={{padding: "0px", height: "30px"}} alt="" /></div>
                            <div className="col-6 p-0">Heartburn</div>
                            <div className="col-4 des">2 times</div>
                          </div>
                        </div>
                        <p className="details-head"><span className="bold">Topics</span> (to discuss with doctor)</p>
                        <div className="row vertical-center details py-4">
                          <div className="col p-0">How do I cope with an IBS/IBD flare up?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                              { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row vertical-center details border-bottom pb-4">
                          <div className="col p-0">Why am I experiencing excessive gas or bloating?</div>
                          <div className="col-3">
                            <div className="button vertical-center">SELECT</div>
                          </div>
                        </div>
                        <div className="row vertical-center details py-4">
                          <div className="col p-0">Why do I keep having constipation and/or diarrhea?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                              { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row vertical-center">
                          <div className="col-lg-10 col-md-9 col-sm-10 p-3 typing vertical-center">
                            <ScrollAnimation delay={0} duration={1.8} animateOnce={true} animateIn="type-steps">
                              Should I be concerned with Abdominal Pain?
                            </ScrollAnimation>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-2 p-3 typing text-center">ADD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div class="timelinePanel-6 diagnostic mx-auto">
                      <div class="timeline-heading text-center">
                        <p style={{color:"#2d3e4f"}}> <img src={require("../../img/appointment-planning-icon-diagnosis.svg")} className="img-fluid" alt="" />Diagnosis / Treatment</p>
                      </div>
                      <div class="timeline-body"> 
                        <p className="p-0 section-related">This section relates to topics you may want to discuss about diagnostic tests and procedures, as well as the various treatment options.</p>                       
                        <p className="details-head"><span className="bold">Topics</span> (to discuss with doctor)</p>
                        <div className="row details vertical-center border-bottom py-3">
                          <div className="col p-0">Do I need any tests or scans to confirm my diagnosis? If so, which one(s)?</div>
                          <div className="col-3">
                            <div className="button vertical-center">SELECT</div>
                          </div>
                        </div>
                        <div className="row details vertical-center border-bottom py-3">
                          <div className="col p-0">Should my family members or I get tested for possible hereditary issues?</div>
                          <div className="col-3">
                            <div className="button vertical-center">SELECT</div>
                          </div>
                        </div>
                        <div className="row details vertical-center py-3">
                          <div className="col p-0">Do you need to perform any endoscopic or invasive tests?</div>
                          <div className="col-3">
                            <Waypoint onEnter={()=>this.setState({ renderAppoitmentChecklistLottie: true }) } />
                                { renderAppoitmentChecklistLottie && <Lottie width={24} height={24} options={appointmentChecklistOptions}/> }
                          </div>
                        </div>
                        <div className="row vertical-center py-2">
                          <div className="col p-3 typing disabled vertical-center">Type in your own topic</div>
                          <div className="col-lg-3 col-md-3 col-sm-2 p-3 typing disabled text-center">ADD</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="pb-0 print-facesheet">
                <div className="calendar text-center">
                  <h1 className="month">{this.state.month}</h1>
                  <p class="day">25</p>
                  <p className="time">11:00 AM</p>
                </div>
                <p className="comment">Appointment Day</p>                
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="printFacesheet timeline-wrapper">
                      <div class="timeline-badge facesheet"></div>
                      <h4 class="timeline-heading">Print Facesheet</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Use the 1-page synopsis generated for you prior to the patient visit – almost as if you knew everything they’ve gone through over the last few months.
                        <br></br><br></br>Quickly review their chief complaints and history of present illness (HPI), along with all the questionnaire responses – prior gastroenterology issues, eating behavior and overall lifestyle, graphs for bowel movements, food intake, and much more.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li class="py-5 print-facesheet">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-6 col-md-10">
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={0} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                            <div class="timeline-heading text-left" style={{backgroundColor:"#dcecfa"}}>
                              <div style={{color:"#2d3e4f"}}> <img src={require("../../img/fill-1.svg")} className="img-fluid" alt="" />Appointment Planning</div>
                            </div>
                            <div class="timeline-body appointment-list text-left py-3">
                              <h3>Topics of Discussion</h3>
                              <ul>
                                <li><span>How do I cope with an IBS/IBD flare up?</span></li>
                                <li><span>Why do I keep having constipation and/or diarrhea?</span></li>
                                <li><span>Do you need to perform any endoscopic or invasive tests?</span></li>
                                <li><span>Should I be concerned with abdominal pain?</span></li>
                              </ul>
                            </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={200} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#feeed9"}}>
                            <div style={{color:"#e28816"}}> <img src={require("../../img/fill-1-1.svg")} className="img-fluid" alt="" />Symptoms</div>
                          </div>
                          <div class="timeline-body pt-3">
                            <div className="summary">
                              <div className="row py-1 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/abdominal-pain.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Abdominal Pain x 8</div>
                                  <div className="py-1">Severity – 8, Throbbing, Tender to Touch, Upper Abdomen, Lower Abdomen, Fatigue, Joint Pain, Trigger – Food Allergy / Intolerance</div>
                                </div>
                              </div>
                              <div className="row py-1 pt-2 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/bloating-copy.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Bloating x 4</div>
                                  <div className="py-1">Severity – 6 , Swollen and Increased Burping, Lower Abdomen and Sides, Trigger – Overeating, Menstruation, and Certain Foods.</div>
                                </div>
                              </div>
                              <div className="row py-1 pt-2 text-left">
                                <div className="col-1 p-0"><img src={require("../../img/heartburn-copy.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col px-3">
                                  <div className="heading">Heartburn x 2</div>
                                  <div className="py-1">Severity – 3, Burning and Stomach Ache, Nausea and Bloating, Trigger – Fatty / Spicy Foods, Chocolate / Peppermint </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={400} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#edf8df"}}>
                            <div style={{color:"#709f34"}}> <img src={require("../../img/group-29-copy.svg")} className="img-fluid" alt="" />Vitals</div>
                          </div>
                          <div class="timeline-body py-3">
                            <div className="summary">
                              <div className="row py-1 text-left border-bottom">
                                <div className="col-1 p-0"><img src={require("../../img/weight.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col p-0 px-3">
                                  <div className="heading">Weight   -   292.6 lbs</div>
                                  <div>(Avg. 6 entries - 285 lbs - 294 lbs)</div>
                                </div>
                              </div>
                              <div className="row py-1 pt-2 text-left">
                                <div className="col-1 p-0"><img src={require("../../img/group-111.svg")} className="img-fluid" style={{padding: "0px"}} alt="" /></div>
                                <div className="col p-0 px-3">
                                  <div className="heading">Blood Pressure - 147 SYS / 95 DIA</div>
                                  <div>(Avg. 4 entries - 143 – 149 SYS / 94 – 96 DIA)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-10">
                    <div className="row py-2">
                      <div class="timelinePanel-7">
                        <ScrollAnimation delay={600} duration={0.8} animateOnce={true} animateIn="fadeInUp">
                          <div class="timeline-heading text-left" style={{backgroundColor:"#faf5fc"}}>
                            <div style={{color:"#833e93"}}> <img src={require("../../img/combined-shape.svg")} className="img-fluid" alt="" />Medical History Questionnaire</div>
                          </div>
                          <div class="timeline-body py-3 summary">
                            <div className="row border-bottom py-2 vertical-center">
                              <div className="col p-0">
                                <div className="p-0 heading">Average Sleep Per Night</div>
                                <div>7 Hours</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Liquid Stools Per Day</div>
                                <div>2</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Recent Symptoms</div>
                                <div className="p-0">Abdominal Pain, Bloating, Joint Pain, and Fatigue</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Recently Eaten Trigger Foods</div>
                                <div>Chocolate, Spicy Food, Greasy Food</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Recently Drank Trigger Beverages</div>
                                <div>None</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Current Stress Level</div>
                                <div>Medium</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Average Physical Activity Per Day</div>
                                <div>30 Minutes</div>
                              </div>
                            </div>
                            <div className="row vertical-center border-bottom py-2">
                              <div className="col p-0">
                                <div className="p-0 heading">Physical Activity Intensity</div>
                                <div>Light Sweating</div>
                              </div>
                            </div>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-md-block d-sm-none scrollBarWrapper">
                <div className="timeline-row row justify-content-center">
                  <div className="col-lg-8 col-md-10">
                    {/* {changeStickyBar ? } */}
                    <div id="pageScrollBar" className="row vertical-center section-item-9">
                      <div className="col message">
                        <a href="#section-1">
                          { indexAnimationArr[0] ? 
                            <Lottie options={messageAnimationOptions}/> :
                            indexArr[0] ?
                              <img src={require("../../img/message-icon-active.svg")} className="img-fluid" alt="" /> :
                              <img src={require("../../img/message-icon-default.svg")} className="img-fluid" alt="" />
                          }                        
                        </a>
                      </div>
                      <div className="col learn">
                        <a href="#section-2">
                          { indexAnimationArr[1] ?
                            <Lottie options={learnAnimationOptions}/> :
                            indexArr[1] ?
                              <img src={require("../../img/learn-icon-active.svg")} className="img-fluid" alt="" /> :
                              <img src={require("../../img/learn-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col questionnaire">
                        <a href="#section-3">
                          { indexAnimationArr[2] ?
                            <Lottie options={questionnaireAnimationOptions}/> :
                            indexArr[2] ?
                              <img src={require("../../img/questionnaire-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/questionnaire-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col checklist">
                        <a href="#section-4">
                          { indexAnimationArr[3] ?
                            <Lottie options={yogaAnimationOptions}/> :
                            indexArr[3] ?
                              <img src={require("../../img/yoga-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/yoga-inactive.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col symptoms">
                        <a href="#section-5">
                          { indexAnimationArr[4] ?
                            <Lottie options={symptomAnimationOptions}/> :
                            indexArr[4] ?
                              <img src={require("../../img/symptoms-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/symptoms-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col vitals">
                        <a href="#section-6">
                          { indexAnimationArr[5] ?
                            <Lottie options={vitalsAnimationOptions}/> :
                            indexArr[5] ?
                              <img src={require("../../img/vitals-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/vitals-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col lifestyle">
                        <a href="#section-7">
                          { indexAnimationArr[6] ?
                            <Lottie options={lifestyleAnimationOptions}/> :
                            indexArr[6] ?
                              <img src={require("../../img/lifestyle-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/lifestyle-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col lifestyle">
                        <a href="#section-8">
                          { indexAnimationArr[7] ?
                            <Lottie options={medicationAnimationOptions}/> :
                            indexArr[7] ?
                              <img src={require("../../img/medication-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/medication-inactive.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                      <div className="col appointment">
                        <a href="#section-9">
                          { indexAnimationArr[8] ?
                            <Lottie options={appointmentPlanningAnimationOptions}/> :
                            indexArr[8] ?
                              <img src={require("../../img/appointment-planning-icon-active.svg")} className="img-fluid" alt="" />:
                              <img src={require("../../img/appointmentplanning-icon-default.svg")} className="img-fluid" alt="" />
                          }
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-row row align-items-lg-top justify-content-left">
                  <div className="col-lg-8 col-md-10 col-sm-11 p-0">
                    <div className="chartNote timeline-wrapper">
                      <div class="timeline-badge note"></div>
                      <h4 class="timeline-heading">Auto Generate Chart Note</h4>
                      <div class="timeline-body">
                        <p className="mb-0">Use natural language processing to automatically generate chart notes and insert them into your electronic health record system.  
                        <br></br><br></br>Customize the chart note to match your style, and include any and all information from the chief complaints, questionnaires, symptoms, vitals, medications and more. Maintain complete documentation to support your treatment plan.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              </li>
              <li className="pt-0">
                <div className="timeline-row row align-items-lg-center justify-content-center">
                  <div className="col-lg-12 col-md-12">
                    <div className="timelinePanel-8">
                      <div class="timeline-body align-center">
                        <p>In the past week, Emma slept 7 hours on average. She experienced an average of 2 liquid stools per day as well as abdominal pain, bloating, joint pain, and fatigue. Emma recently ate the following trigger foods: chocolate, greasy food, and spicy food. She has not recently drank any triggering beverages. Emma’s current stress level is medium. 
                          <br></br><br></br>In the past month, Emma exercised an average of 30 minutes per day with an intensity that caused light sweating. Her body reacted with rapid breathing and muscular soreness.
                          <br></br><br></br>In general, Emma feels her disease is somewhat under control and is a little uncomfortable on a daily basis.
                        </p>
                      </div>
                    </div>
                    <div className="success-copy">
                      { window.innerWidth > 991 ?
                        <div className="button">
                          <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                { rendercopyButton && <Lottie options={copyAnnasHealthAnimationOptions}/> }
                        </div> :
                        window.innerWidth > 767 ?
                          <div className="button">
                            <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                  { rendercopyButton && <Lottie options={copyAnnasHealthAnimationTabletOptions}/> }
                          </div> :
                          <div className="button">
                            <Waypoint onEnter={()=>this.setState({ rendercopyButton: true }) } />
                                  { rendercopyButton && <Lottie options={copyAnnasHealthAnimationMobileOptions}/> }
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="dash"></div>
              </li>
            </ul>
          </div>
        </section>

        <section className="timelineFooter">
          <div className="container-lg">
            <div className="timeline-row row align-items-lg-center justify-content-center mx-5 pb-5">
              <div className="col-lg-6 col-md-8 col-sm-9">
                {/* { startBootomLoop ? 
                  <Lottie options={bottomAnimationOptionsTwo}/> :
                  <Lottie options={bottomAnimationOptionsOne} onLoopComplete={this.onCompleteBottomAnimation}/>
                } */}
                <Lottie options={bottomAnimationOptionsTwo}/>
              </div>
              <div className="col-lg-5 col-md-12">
                <h3>DoctorPlan comes pre-configured with clinical care pathways for Gastroenterology including</h3>
                <ul className="footerList text-left">
                  <li>New GI Patient Consult</li>
                  <li>New IBS/IBD Patient Consult</li>
                  <li>Follow Up Appointment</li>
                  <li>Cancer Screening</li>
                  <li>Colonoscopy</li>
                  <li>Endoscopy</li>
                </ul>
              </div>
              <Link to="/join-us" className="link-button-image">
                <div className="button-image text-center">Get DoctorPlan for your Practice</div>
              </Link>
            </div>
          </div>
        </section>
      </div>


    )
  }
}

export default Gastroenterology;