import React, { Component, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import ImageFade from './ImageFade'
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import { createBrowserHistory } from 'history'

/**
 * Map of area tag with respective link
 */
const AreaLink = {
    'bariartric': '/areas-of-medicine/bariatric-surgery',
    'general': '/areas-of-medicine/general-surgery',
    'obgyn': '/areas-of-medicine/obstetrics-and-gynecology',
    'oncology': '/areas-of-medicine/oncology',
    'ent': '/areas-of-medicine/ear-nose-throat',
    'orthopedics': '/areas-of-medicine/orthopedic-surgery',
    'painmanagement': '/areas-of-medicine/pain-management',
    'pulmonology': '/areas-of-medicine/pulmonology',
    'thoracicsurgery': '/areas-of-medicine/thoracic-surgery',
    'gastroenterology':'/areas-of-medicine/gastroenterology'

}

@inject('globalStore')
@observer class IsDoctorPlanForYou extends Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory()
        // this.imageList = [
        //     '../../img/isdoctorplan-bariatric1@3x',
        //  ]
        // preloadImageUrl()

        this.state = {
            currentArea: '',
            renderBlock: {
                title:'Bariatric Surgery',
                link:'/areas-of-medicine/bariatric-surgery',
                carePathwaylink:'/care-pathways/bariatric-surgery',
                img1:'isdoctorplan-bariatric1@3x',img2:'isdoctorplan-bariatric2@3x',img3:'isdoctorplan-bariatric3@3x', imgKey: 0,
                subTxt:'Automate patient intake, educate patients about treatments and surgical procedures, track symptoms, vitals, food / fluids consumption, and follow up / check-in periodically.'
            },
            col1Class: 'imgcol pinkBackground',
            col2Class: 'imgcol orangeBackground',
            col3Class: 'imgcol pinkBackground',
            imgKey: 0
            
        }
    }

    componentDidMount() {
        const ImageList = [
            'isdoctorplan-bariatric1@3x',
            'isdoctorplan-bariatric2@3x',
            'isdoctorplan-bariatric3@3x',
            'isdoctorplan-general1@3x',
            'isdoctorplan-general2@3x',
            'isdoctorplan-general3@3x',
            'isdoctorplan-gastro-1@3x',
            'isdoctorplan-gastro2@3x',
            'isdoctorplan-gastro3@3x',
            'isdoctorplan-obgyn-1@3x',
            'isdoctorplan-obgyn2@3x',
            'isdoctorplan-obgyn3@3x',
            'isdoctorplan-oncology1@3x',
            'isdoctorplan-oncology2@3x',
            'isdoctorplan-oncology3@3x',
            'isdoctorplan-ent1@3x',
            'isdoctorplan-ent2@3x',
            'isdoctorplan-ent3@3x',
            'isdoctorplan-orthopedic1@3x',
            'isdoctorplan-orthopedic2@3x',
            'isdoctorplan-orthopedic3@3x',
            'isdoctorplan-spine-pain1@3x',
            'isdoctorplan-spine-pain2@3x',
            'isdoctorplan-spine-pain3@3x',
            'isdoctorplan-spine-pulmonology1@3x',
            'isdoctorplan-spine-pulmonology2@3x',
            'isdoctorplan-spine-pulmonology3@3x',
            'isdoctorplan-spine-thoracic1@3x',
            'isdoctorplan-spine-thoracic2@3x',
            'isdoctorplan-spine-thoracic3@3x'
        ]
        ImageList.forEach((image) => {
            const img = new Image();
            img.src = require(`../../img/${image}.png`);
        });
    }
    preloadImageUrl(url){
       var image = new Image(); 
       image.src = url
    }

    switchBlock(area){
        // Crude way to enforce tablet and mobile
       if(window.innerWidth < 991)
         this.props.history.push(AreaLink[area])
       const newImgKey = this.state.renderBlock.imgKey + 1
       switch(area) {
           case 'bariartric': 
           this.setState({ renderBlock : {
            title:'Bariatric Surgery',
            link:'/areas-of-medicine/bariatric-surgery',
            carePathwaylink:'/care-pathways/bariatric-surgery',
            img1:'isdoctorplan-bariatric1@3x',img2:'isdoctorplan-bariatric2@3x',img3:'isdoctorplan-bariatric3@3x', imgKey: newImgKey,
            subTxt:'Automate patient intake, educate patients about treatments and surgical procedures, track symptoms, vitals, food / fluids consumption, and follow up / check-in periodically.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
           })
           break
           case 'general':
           this.setState({ renderBlock : {
            title:'General Surgery',
            link:'/areas-of-medicine/general-surgery',
            carePathwaylink:'/care-pathways/general-surgery',
            img1:'isdoctorplan-general1@3x',img2:'isdoctorplan-general2@3x',img3:'isdoctorplan-general3@3x', imgKey: newImgKey,
            subTxt:'From initial consult to post-op follow through, automate patient education and all intake and follow-up questionnaires. Collect patient reported outcomes.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
         })
           break
           case 'gastroenterology':
           this.setState({ renderBlock : {
            title:'Gastroenterology',
            link:'/areas-of-medicine/gastroenterology',
            carePathwaylink:'/care-pathways/gastroenterology',
            img1:'isdoctorplan-gastro-1@3x',img2:'isdoctorplan-gastro2@3x',img3:'isdoctorplan-gastro3@3x', imgKey: newImgKey,
            subTxt:'From screenings to IBS / IBD, personalize intake for patients, send timely information for procedure preparedness, track symptoms and bowel movements, and follow-up periodically.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
         })
           break
           case 'obgyn':
            this.setState({ renderBlock : {
             title:'Obstetrics  & Gynecology',
             link:'/areas-of-medicine/obstetrics-and-gynecology',
             carePathwaylink:'/care-pathways/obstetrics-and-gynecology',
             img1:'isdoctorplan-obgyn-1@3x',img2:'isdoctorplan-obgyn2@3x',img3:'isdoctorplan-obgyn3@3x', imgKey: newImgKey,
             subTxt:'Stay connected with patients on the entire care journey. Share informational articles, exercise videos and mindfulness lessons. Automate intake and follow-up forms and enable symptom / vital tracking.'
            },
            col1Class: 'imgcol pinkBackground',
            col2Class: 'imgcol blueBackground',
            col3Class: 'imgcol pinkBackground',
         })
            break
           case 'oncology':
           this.setState({ renderBlock : {
            title:'Oncology',
            link:'/areas-of-medicine/oncology',
            carePathwaylink:'/care-pathways/oncology',  
            img1:'isdoctorplan-oncology1@3x',img2:'isdoctorplan-oncology2@3x',img3:'isdoctorplan-oncology3@3x',img4:'img04', imgKey: newImgKey,
            subTxt:'Guide patients through entire diagnosis and treatment journey – with informational articles, intake, history, and genetic questionnaires, and periodic follow-throughs. Track symptoms, vitals, and side effects – for effective care.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
         })
           break
           case 'ent':
           this.setState({ renderBlock : {
            title:'Ear Nose and Throat',
            link:'/areas-of-medicine/ear-nose-throat',
            carePathwaylink:'/care-pathways/ear-nose-throat',  
            img1:'isdoctorplan-ent1@3x',img2:'isdoctorplan-ent2@3x',img3:'isdoctorplan-ent3@3x', imgKey: newImgKey,
            subTxt:'Automate condition specific intake for ear, nose, and throat, track symptoms, document medications, follow-up periodically. Correlate health data for improved quality of care.'
           },
           col1Class: 'imgcol orangeBackground',
           col2Class: 'imgcol pinkBackground',
           col3Class: 'imgcol peachBackground',
         })
           break
           case 'orthopedics':
           this.setState({ renderBlock : {
            title:'Orthopedic Surgery',
            link:'/areas-of-medicine/orthopedic-surgery',
            carePathwaylink:'/care-pathways/orthopedic-sugery', 
            img1:'isdoctorplan-orthopedic1@3x',img2:'isdoctorplan-orthopedic2@3x',img3:'isdoctorplan-orthopedic3@3x', imgKey: newImgKey,
            subTxt:'Automate patient intake and follow throughs post-surgery. Guide patients through surgical journey with procedure information, prehab and rehab exercise videos, and instructions for better recovery.'
           },
            col1Class: 'imgcol orangeBackground',
            col2Class: 'imgcol pinkBackground',
            col3Class: 'imgcol blueBackground',
         })
           break
           case 'painmanagement':
           this.setState({ renderBlock : {
            title:'Spine / Pain Surgery',
            link:'/areas-of-medicine/pain-management',
            carePathwaylink:'/care-pathways/pain-management', 
            img1:'isdoctorplan-spine-pain1@3x',img2:'isdoctorplan-spine-pain2@3x',img3:'isdoctorplan-spine-pain3@3x', imgKey: newImgKey,
            subTxt:'Guide patients through procedures with timely information and instructions. Follow patients from diagnosis to recovery with comprehensive intake, follow up, treatment history questionnaires and patient reported outcomes.'
           },
           col1Class: 'imgcol orangeBackground',
           col2Class: 'imgcol pinkBackground',
           col3Class: 'imgcol blueBackground',
         })
           break
           case 'pulmonology':
           this.setState({ renderBlock : {
            title:'Pulmonology',
            link:'/areas-of-medicine/pulmonology',
            carePathwaylink:'/care-pathways/pulmonology', 
            img1:'isdoctorplan-spine-pulmonology1@3x',img2:'isdoctorplan-spine-pulmonology2@3x',img3:'isdoctorplan-spine-pulmonology3@3x', imgKey: newImgKey,
            subTxt:'Ensure patients are educated about Asthma, COPD, and Lung Disorders. Automate intake, follow-up and sleep / wake questionnaires. Enable symptoms, vitals, and sleep tracking. Correlate health data for improved care.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
         })
           break
           case 'thoracicsurgery':
           this.setState({ renderBlock : {
            title:'Thoracic Surgery',
            link:'/areas-of-medicine/thoracic-surgery',
            carePathwaylink:'/care-pathways/thoracic-surgery', 
            img1:'isdoctorplan-spine-thoracic1@3x',img2:'isdoctorplan-spine-thoracic2@3x',img3:'isdoctorplan-spine-thoracic3@3x', imgKey: newImgKey,
            subTxt:'Automate patient intake and educate patients about diagnosis and surgical procedures. Track symptoms, vitals, food / fluids intake, and follow up / check-in periodically. Automate patient reported outcomes for improved care.'
           },
           col1Class: 'imgcol pinkBackground',
           col2Class: 'imgcol orangeBackground',
           col3Class: 'imgcol pinkBackground',
         })
           break
           default:
       }
    }

    render() {
        return (
            <div>
                {/* <div>
                    <img src={require(`../../img/isdoctorplan-bariatric1@3x.png`)} />
                    <img src={require(`../../img/isdoctorplan-bariatric2@3x.png`)}  />
                    <img src={require(`../../img/isdoctorplan-bariatric2@3x.png`)}  />
                    <img src={require(`../../img/isdoctorplan-general1@3x.png`)}  />
                    <img src={require(`../../img/isdoctorplan-general2@3x.png`)}  />
                    <img src={require(`../../img/isdoctorplan-general3@3x.png`)}  />

                </div> */}
                <section class="practice">
                    <div class="container">
                        <div class="row">
                            <div class="col heading-content">
                                <h2 class="text-md-center fts-42">Is DoctorPlan for your practice?</h2>
                                <p class="text-md-center marginCustom30" >Specialty physicians across different areas of medicine are using the DoctorPlan Precision Health platform to deliver a personalized experience patients expect. <Link to="/join-us" href="#" class="contact-us-link"><strong>Contact Us</strong></Link>.</p>
                            </div>
                        </div>
                        <div class="practice-content">
                            <div class="row">
                                <div class="col-lg-4 col-md-12" style={{paddingTop: '12px'}}>
                                    <ul id="tabs" className="isdocplantabs">
                                        <li onClick={(e) => {this.switchBlock('bariartric')}}><img src={require("../../img/bariatirc-surgery-icon@3x.png")} alt="" /> <a  id="tb_1" class="tabmenu" className={(this.state.renderBlock.title === 'Bariatric Surgery') ? 'active': '' }>Bariatric Surgery</a></li>
                                        <li onClick={(e) => {this.switchBlock('ent')}}><img src={require("../../img/ear-nose-throat-icon@3x.png")} alt="" /> <a  id="tb_3" class="tabmenu" className={(this.state.renderBlock.title === 'Ear Nose and Throat') ? 'active': '' }>Ear Nose and Throat</a></li>
                                        <li onClick={(e) => {this.switchBlock('gastroenterology')}}><img src={require("../../img/gastroenterology-icon@3x.png")} alt="" /> <a  id="tb_4" class="tabmenu" className={(this.state.renderBlock.title === 'Gastroenterology') ? 'active': '' }>Gastroenterology</a></li>
                                        <li onClick={(e) => {this.switchBlock('general')}}><img src={require("../../img/general-surgery-icon@3x.png")} alt="" /> <a  id="tb_2" class="tabmenu" className={(this.state.renderBlock.title === 'General Surgery') ? 'active': '' }>General Surgery</a></li>
                                        <li onClick={(e) => {this.switchBlock('obgyn')}}><img src={require("../../img/obgyn-icon@3x.png")} alt="" /> <a  id="tb_5" class="tabmenu" className={(this.state.renderBlock.title === 'Obstetrics  & Gynecology') ? 'active': ''}>Obstetrics  & Gynecology </a></li>
                                        <li onClick={(e) => {this.switchBlock('oncology')}}><img src={require("../../img/oncology-icon@3x.png")} alt="" /> <a  id="tb_6" class="tabmenu" className={(this.state.renderBlock.title === 'Oncology') ? 'active': ''}>Oncology</a></li>
                                        <li onClick={(e) => {this.switchBlock('orthopedics')}}><img src={require("../../img/orthopedics-icon@3x.png")} alt="" /> <a id="tb_7" class="tabmenu" className={(this.state.renderBlock.title === 'Orthopedic Surgery') ? 'active': ''}>Orthopedic Surgery</a></li>
                                        <li onClick={(e) => {this.switchBlock('painmanagement')}}><img src={require("../../img/pain-management-icon@3x.png")} alt="" /> <a  id="tb_8" class="tabmenu" className={(this.state.renderBlock.title === 'Spine / Pain Surgery') ? 'active': ''}>Spine / Pain Surgery</a></li>
                                        <li onClick={(e) => {this.switchBlock('pulmonology')}}><img src={require("../../img/pulmonology-icon@3x.png")} alt="" /> <a  id="tb_9" class="tabmenu" className={(this.state.renderBlock.title === 'Pulmonology') ? 'active': ''}>Pulmonology</a></li>
                                        {/* <li onClick={(e) => {this.switchBlock('spinesurgery')}}><img src={require("../../img/spine-surgery-icon@3x.png")} alt="" /> <a  id="tb_10" class="tabmenu" className={(this.state.renderBlock.title === 'Spine Surgery') ? 'active': ''}>Spine Surgery</a></li> */}
                                        <li onClick={(e) => {this.switchBlock('thoracicsurgery')}}><img src={require("../../img/thoracic-surgery-icon@3x.png")} alt="" /> <a id="tb_11" class="tabmenu" className={(this.state.renderBlock.title === 'Thoracic Surgery') ? 'active': ''}>Thoracic Surgery</a></li>
                                    </ul>
                                </div>
                                <div class="col-lg-8 d-none d-lg-block">
                                    <div class="tabChangingImage">
                                        <div class="contentWrap">
                                            <div id="content_1" class="tabcontent">
                                                <div className="shadow">
                                                <div className="tabContentHeader">
                                                    <h2>{this.state.renderBlock.title}</h2>
                                                    <p>{this.state.renderBlock.subTxt} </p>
                                                </div>
                                                <Link  to={this.state.renderBlock.link}>
                                                <div class="imageContent">
                                                    <div class="imgcol" className={this.state.col1Class}  >
                                                        <ImageFade key={this.state.renderBlock.imgKey} src={require(`../../img/${this.state.renderBlock.img1}.png`)} alt="" />
                                                    </div>
                                                    <div class="imgcol" className={this.state.col2Class}>
                                                        <ImageFade key={this.state.renderBlock.imgKey} src={require(`../../img/${this.state.renderBlock.img2}.png`)} alt="" delay={200} />
                                                    </div>
                                                    <div class="imgcol" className={this.state.col3Class}>
                                                        <ImageFade key={this.state.renderBlock.imgKey} src={require(`../../img/${this.state.renderBlock.img3}.png`)} alt="" delay={400} />
                                                    </div>
                                                </div>
                                                </Link>
                                                </div>
                                                <div className="button-group-info">
                                                <Link to={this.state.renderBlock.link} className="learnMoreTo">
                                                Learn More
                                                </Link>
                                                <Link to={this.state.renderBlock.carePathwaylink} className="carepathway">
                                                 View Care Pathway
                                                </Link>
                                                </div>
                                            </div>
                                            {/* <div id="content_2" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_3" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_4" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_5" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_6" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_7" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_8" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_9" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_10" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div>
                                            <div id="content_11" class="tabcontent" style={{ "visibility": "hidden" }}>
                                                <h2></h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(IsDoctorPlanForYou);
