import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Lottie from 'react-lottie';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import "./style.scss";
import aboutAnimation from '../../animation/About-Page-Animation.json';
import Header from '../Header/Header'

@inject("globalStore")
@observer
class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'About - DoctorPlan'
  }

  render() {
    const aboutAnimationOptions = {
      loop: true,
      autoplay: true,
      animationData: aboutAnimation,
      // rendererSettings: {
      //   preserveAspectRatio: 'xMidYMid slice'
      // }
    }
    return (
      <div>
        <Header color="#2D3E4F"/>
        <section class="aboutBanner">
          <div class="container">
            <Lottie
               style={{display:"flex", maxWidth:'764px', justifyContent:"center"}}
               options={aboutAnimationOptions}
               class="d-block mx-auto about-image"
            />
          </div>
        </section>
        
        <div class="container-xl">
          <section class="aboutText">
            
            <h2>About <span className="docplantilte">DoctorPlan</span></h2>
            <p>
            DoctorPlan helps healthcare organizations create deep relationships with patients, using a data-driven approach to personalize care delivery. 
            Our precision health platform helps them deliver the best outcomes for patients – with extended care, 
            access to timely information, and personalized follow ups, between visits.

            </p>
            <div>
              <p>At DoctorPlan, we believe that improving clinical outcomes requires:</p>
             
            <ul className="about-top-list">
               <li> <img src={require('../../img/about-us-list-icon-new1@3x.png')} /> <p><strong>An informed patient,</strong> who understands their role in managing their health, what is expected of them, and most importantly, why.</p></li>
  
               <li><img src={require('../../img/about-us-list-icon-new2@3x.png')} style={{ "width": "41px","height": "41px", "left": "-8px"}} /><p><strong>An evidence-based care plan,</strong> personalized for each patient - based on their present condition and medical history.</p></li>
 
               <li> <img src={require('../../img/about-us-list-icon-new3@3x.png')} style={{ "width": "44px","height": "42px", "left": "-8px"}} /><p><strong>A prepared doctor,</strong> who is presented with the patient’s precise health information in a structured way, including progress between visits.</p></li>
 
               <li> <img src={require('../../img/about-us-list-icon-new4@3x.png')} style={{ "width": "33px","height": "24px"}} /><p><strong>A precision health platform,</strong> which empowers doctors to focus their valuable time on personalizing care, and less on administrative documentation.</p></li>
             </ul>
            </div>
            <p>
            We are on a mission to help doctors improve clinical outcomes for 50 million patients over the next 10 years. We’ve assembled a team of serial entrepreneurs, 
            doctors and technologists, who work together to harness the power of medicine and technology.
            </p>          
          </section>
          </div>
        
          <div class="container-xl customHeight">
          <section class="core">
            <div class="row">
              <div class="col-md-12">
              <h2>Our Core Tenets</h2>
              </div>
            </div>
            <div class="row content">
              <div class="col-lg-4" style={{"paddingLeft":"0px"}}>
                <div class="row">
                  <div class="col-lg-12 col-md-3 col-sm-3">
                    <img
                      src={require("../../img/group-26@3x.png")}
                      class="img-fluid coreTenets"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-12 col-md-9 col-sm-9">
                    <h2>Security and Privacy</h2>
                    <p>
                    We value security and privacy over everything else. All health data is encrypted and secured using HIPAA compliant technology. 
                    No health data ever leaves our system or is shared with anyone, except the patient’s healthcare teams.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4" style={{"paddingLeft":"0px"}}>
                <div class="row">
                  <div class="col-lg-12 col-md-3 col-sm-3">
                    <img
                      src={require("../../img/group-20@3x.png")}
                      class="img-fluid coreTenets"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-12 col-md-9 col-sm-9">
                    <h2>Decision Support</h2>
                    <p>
                    The role of our health intelligence service is to assist clinicians make better informed decisions, based on patient reported data. 
                    We help by collecting, correlating and presenting data and analysis, 
                    and do not make healthcare diagnosis or treatment recommendations directly to patients.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4" style={{"paddingLeft":"0px"}}>
                <div class="row">
                  <div class="col-lg-12 col-md-3 col-sm-3">
                    <img
                      src={require("../../img/group-24@3x.png")}
                      class="img-fluid coreTenets"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-12 col-md-9 col-sm-9">
                    <h2>Innovation</h2>
                    <p>
                    We innovate on behalf of our customers, and not to prove technology leadership.
                    Patients' ease of use and experience is paramount to us, as clinicians benefit from health information only if patients report it. 
                    We prioritize information security, data integrity, and platform stability over agility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </section>
          </div>
        

        <section class="team">
          <div class="container-lg px-0">
            <h4>Guided by</h4>
            <h2>A Team of Experts</h2>
            <div className="teamBlock">
              <div className="desktop">
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/Aileen.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Aileen Murphy</h5>
                    <p>Bariatric Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/ArunRaghupati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arun Raghupathy</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/AnandSoni.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Anand Soni</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Salvador.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Salvador Guevara</h5>
                    <p>Colorectal Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/JasonMoy.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Moy</h5>
                    <p>General Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Arati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arati Pratap</h5>
                    <p>Gastroenterology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Rebecca.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Rebecca Parish</h5>
                    <p>Internal Medicine</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/MichaelSherman.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Michael Sherman</h5>
                    <p>Oncology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                      <a >
                        <img
                          src={require("../../img/UmaSwamy.png")}
                          alt=""
                          class="img-fluid"
                        />
                      </a>
                      <h5>Dr. Uma Swamy</h5>
                      <p>Oncology</p>
                  </li>
                <li>
                    <a >
                      <img
                        src={require("../../img/BenjaminBusfield.png")}
                        alt=""
                        class="img-fluid"
                        style={{"maxWidth":"200px"}}
                      />
                    </a>
                    <h5>Dr. Benjamin Busfield </h5>
                    <p>Orthopedic Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/RamanaNaidu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Ramana Naidu</h5>
                    <p>Pain Management</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/KarinCheung.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Karin Cheung</h5>
                    <p>Pulmonology</p>
                  </li>
                </ul>
                <ul class="teamList">
                <li>
                    <a >
                      <img
                        src={require("../../img/SidPrakash.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sid Prakash</h5>
                    <p>Radiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/BrianSu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Brian Su</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/s3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr.  Jason Huffman</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/WilsonTsai.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Wilson Tsai</h5>
                    <p>Thoracic Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/VijayMittal.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Vijay Mittal</h5>
                    <p>Artificial Intelligence</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/TomErickson.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Tom Erickson</h5>
                    <p>Strategic Growth</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/DjamelAgaoua.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Djamel Agaoua</h5>
                    <p>Privacy and Security</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/GregorFreund.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Gregor Freund</h5>
                    <p>Technology</p>
                  </li>
                </ul>
              </div>
              {/*desktop team list*/}

              <div className="tablet">
              <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/Aileen.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Aileen Murphy</h5>
                    <p>Bariatric Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/ArunRaghupati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arun Raghupathy</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/AnandSoni.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Anand Soni</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Salvador.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Salvador Guevara</h5>
                    <p>Colorectal Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/JasonMoy.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Moy</h5>
                    <p>General Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Arati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arati Pratap</h5>
                    <p>Gastroenterology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Rebecca.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Rebecca Parish</h5>
                    <p>Internal Medicine</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/MichaelSherman.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Michael Sherman</h5>
                    <p>Oncology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                      <a >
                        <img
                          src={require("../../img/UmaSwamy.png")}
                          alt=""
                          class="img-fluid"
                        />
                      </a>
                      <h5>Dr. Uma Swamy</h5>
                      <p>Oncology</p>
                  </li>
                <li>
                    <a >
                      <img
                        src={require("../../img/BenjaminBusfield.png")}
                        alt=""
                        class="img-fluid"
                        style={{"maxWidth":"200px"}}
                      />
                    </a>
                    <h5>Dr. Benjamin Busfield </h5>
                    <p>Orthopedic Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/RamanaNaidu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Ramana Naidu</h5>
                    <p>Pain Management</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/KarinCheung.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Karin Cheung</h5>
                    <p>Pulmonology</p>
                  </li>
                </ul>
                <ul class="teamList">
                <li>
                    <a >
                      <img
                        src={require("../../img/SidPrakash.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sid Prakash</h5>
                    <p>Radiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/BrianSu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Brian Su</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/s3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr.  Jason Huffman</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/WilsonTsai.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Wilson Tsai</h5>
                    <p>Thoracic Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/VijayMittal.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Vijay Mittal</h5>
                    <p>Artificial Intelligence</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/TomErickson.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Tom Erickson</h5>
                    <p>Strategic Growth</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/DjamelAgaoua.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Djamel Agaoua</h5>
                    <p>Privacy and Security</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/GregorFreund.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Gregor Freund</h5>
                    <p>Technology</p>
                  </li>
                </ul>
                {/* <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Moy</h5>
                    <p>Bariatric Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arun Raghupathy</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Anand Soni</h5>
                    <p>Cardiology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sujoya Dey</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Aileen Murphy</h5>
                    <p>General Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Irene Lo</h5>
                    <p>General Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arati Pratap</h5>
                    <p>Gastroenterology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Rebecca Parish</h5>
                    <p>Internal Medicine</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Michael Sherman</h5>
                    <p>Oncology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Ramana Naidu</h5>
                    <p>Pain Management</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Karin Cheung</h5>
                    <p>Pulmonology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sid Prakash</h5>
                    <p>Radiology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Brian Su</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Huffman</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Wilson Tsai</h5>
                    <p>Thoracic Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Vijay Mital</h5>
                    <p>Artificial Intelligence</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>David Schummers</h5>
                    <p>Medical Devices</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Djamel Agaoua</h5>
                    <p>Privacy and Security</p>
                  </li>
                </ul>
                <ul class="teamList"> */}
                  {/* <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Gregor Freund</h5>
                    <p>Technology</p>
                  </li>
                </ul> */}
              </div>
              {/*tablet*/}
              <div className="mobile">
                {/* <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Moy</h5>
                    <p>Bariatric Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arun Raghupathy</h5>
                    <p>Cardiology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Anand Soni</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sujoya Dey</h5>
                    <p>Cardiology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Aileen Murphy</h5>
                    <p>General Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Irene Lo</h5>
                    <p>General Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arati Pratap</h5>
                    <p>Gastroenterology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Rebecca Parish</h5>
                    <p>Internal Medicine</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Michael Sherman</h5>
                    <p>Oncology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Ramana Naidu</h5>
                    <p>Pain Management</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Karin Cheung</h5>
                    <p>Pulmonology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sid Prakash</h5>
                    <p>Radiology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Brian Su</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Huffman</h5>
                    <p>Spine Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Wilson Tsai</h5>
                    <p>Thoracic Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t4.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Vijay Mital</h5>
                    <p>Artificial Intelligence</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/t1.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>David Schummers</h5>
                    <p>Medical Devices</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/t2.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Djamel Agaoua</h5>
                    <p>Privacy and Security</p>
                  </li>
                </ul>
                <ul class="teamList"> 
                  <li>
                    <a >
                      <img
                        src={require("../../img/t3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Gregor Freund</h5>
                    <p>Technology</p>
                  </li>
                </ul> */}

<ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/Aileen.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Aileen Murphy</h5>
                    <p>Bariatric Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/ArunRaghupati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arun Raghupathy</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/AnandSoni.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Anand Soni</h5>
                    <p>Cardiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Salvador.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Salvador Guevara</h5>
                    <p>Colorectal Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/JasonMoy.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Jason Moy</h5>
                    <p>General Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Arati.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Arati Pratap</h5>
                    <p>Gastroenterology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/Rebecca.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Rebecca Parish</h5>
                    <p>Internal Medicine</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/MichaelSherman.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Michael Sherman</h5>
                    <p>Oncology</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                      <a >
                        <img
                          src={require("../../img/UmaSwamy.png")}
                          alt=""
                          class="img-fluid"
                        />
                      </a>
                      <h5>Dr. Uma Swamy</h5>
                      <p>Oncology</p>
                  </li>
                <li>
                    <a >
                      <img
                        src={require("../../img/BenjaminBusfield.png")}
                        alt=""
                        class="img-fluid"
                        style={{"maxWidth":"200px"}}
                      />
                    </a>
                    <h5>Dr. Benjamin Busfield </h5>
                    <p>Orthopedic Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/RamanaNaidu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Ramana Naidu</h5>
                    <p>Pain Management</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/KarinCheung.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Karin Cheung</h5>
                    <p>Pulmonology</p>
                  </li>
                </ul>
                <ul class="teamList">
                <li>
                    <a >
                      <img
                        src={require("../../img/SidPrakash.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Sid Prakash</h5>
                    <p>Radiology</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/BrianSu.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Brian Su</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/s3.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr.  Jason Huffman</h5>
                    <p>Spine Surgery</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/WilsonTsai.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Dr. Wilson Tsai</h5>
                    <p>Thoracic Surgery</p>
                  </li>
                </ul>
                <ul class="teamList">
                  <li>
                    <a >
                      <img
                        src={require("../../img/VijayMittal.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Vijay Mittal</h5>
                    <p>Artificial Intelligence</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/TomErickson.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Tom Erickson</h5>
                    <p>Strategic Growth</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/DjamelAgaoua.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Djamel Agaoua</h5>
                    <p>Privacy and Security</p>
                  </li>
                  <li>
                    <a >
                      <img
                        src={require("../../img/GregorFreund.png")}
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                    <h5>Gregor Freund</h5>
                    <p>Technology</p>
                  </li>
                </ul>
              </div>
              {/*mobile*/}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AboutUs;
