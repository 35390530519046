import { observable, action } from "mobx";

let ROOT_URL = 'https://doctorboxapi.com'
if (process.env.NODE_ENV === 'development') 
  ROOT_URL =  'https://dev.doctorboxapi.com'
  
export default {
  
    // getDetails(id){
    //     return fetch(`${ROOT_URL}products/${id}`);
    // },
    async sendFormDetails(details){
        const response = await fetch(`${ROOT_URL}/auth/contact`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(details)
          });
          return response.json();
    },

    async sendFormDetailsNewsLetter(details){
        
        let emailobj =  {
          email: details
        }
        const response = await fetch(`${ROOT_URL}/auth/contact/newsletter`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            // headers: {
            //   'Content-Type': 'application/json'
            //   // 'Content-Type': 'application/x-www-form-urlencoded',
            // },
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(emailobj)
          });
          return response.json();
    },

    async sendVGCCDetails(details){
      const response = await fetch(`${ROOT_URL}/auth/contact/gerdcc`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(details)
      });
      return response.json();
  }
}