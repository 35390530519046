import { observable, action } from "mobx";
import datafetcher from '../Fetchers/DataFetcher'

class GlobalStore {

    @observable showCTA = false;
    @observable blueHeader = true;
    @observable showForPatient = false;

    // @action
    // fetchDetails(id){
    //      return datafetcher.getDetails(id);
    // }

    @action
    postFormDetails(contactDetails) {
        return datafetcher.sendFormDetails(contactDetails);
    }

    @action
    postNewsLetter(contactDetails) {
        return datafetcher.sendFormDetailsNewsLetter(contactDetails)
    }

    @action
    postVGCCDetails(contactDetails) {
        return datafetcher.sendVGCCDetails(contactDetails);
    }

}

export default GlobalStore;