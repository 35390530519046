import { observable, action } from "mobx";
import datafetcher from '../Fetchers/DataFetcher'

class SuccessStore {
    
    constructor(){
    }

    @observable successData = {
        heading:'',
        name:"",
        quote:{
           main: '',
           sub:''
        },
        specialization: '',
        topSection: {
            title:'',
            description:''
        },
        midSection: {
           title:'',
           description:''
       },
       lastSection: {
           title:'',
           description:''
       },
       otherSection: {
           title:"",
           main:"",
           sub:"",
           link:""
       },
        
       }

    @action
    fetchBannerText(successArea){
        alert(successArea)
        if(successArea === 'spine') {
        this.successData = {
        heading:'Spine Surgery',
        name:"Dr. Jason Huffman",
        quote:{
           main: 'DoctorPlan has helped our practice so efficiently. From the first intake form, to timely reminders and instructions, my patients are using it to maintain a digital health diary.',
           sub:'The outcomes analysis clearly shows me how they do before and after, helping us improve our quality of care.'
        },
        specialization: 'Spine Surgeon',
        topSection: {
            title:'Symptoms Recording and Checklists',
            description: {
                main:'Ensure patients are educated about their condition, treatment, preparation and recovery - with easy to understand, bite-sized articles.',
                sub:'Include guided exercise videos, specifically created for prehab and rehab programs, and mindfulness lessons for stress relief and pain management.'
            }
        },
        midSection: {
           title:'',
           description:''
       },
       lastSection: {
           title:'',
           description:''
       },
       otherSection: {
           title:"",
           main:"",
           sub:"",
           link:""
       }
      }
     }
     return this.successData;
    }

}

export default SuccessStore;