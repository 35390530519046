import React, { Component } from "react";
import Header from '../Header/Header'
import "./style.scss";


class AccountDeletion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  
  componentDidMount() {
    document.title = 'Account Deletion - DoctorPlan'
  }
  
  render() {
    return (
      <div>
      <Header color="#2D3E4F"/ >
      <section class="deletion-section">
        <div class="container-xl">
          <h2> Close your Doctorplan Account</h2>
          <div class="line-hr"></div>
          <p>
            Closing your account would mean permanently deleting your account and all the data associated with it (Questionnaires, Vitals, etc) . This action cannot be undone.
          </p>
          <p>
            If you are sure you want to close your account, you can follow either of the two ways -
          </p>
          <ul>
            <li>
              Send a mail to <a href="mailto:support@doctorplan.comm">support@doctorplan.com</a>  with subject “Delete Request” and share email and phone number in the mail body.
            </li>
            <li>
              Log into Android app &gt; Go to user page &gt; click delete account
            </li>
          </ul>
        </div>
      </section>
      </div>
      
    )
  }
}

export default AccountDeletion;
