import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from './routing/routes'
import Header from './components/Header/Header'
import ScrollToTop from './components/common/ScrollToTop'
import Footer from './components/Footer/Footer'
import { createBrowserHistory } from 'history'


function App() {
  
  const history = createBrowserHistory();
  return (
    <div className="App">
      <BrowserRouter >
      <ScrollToTop/>
      {/* <Header/> */}
      <Switch>
        {routes.map((route, i) => (
            <Route key={i} {...route} history={history} />
        ))}
      </Switch>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
