
import Home from '../components/Home/Home';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import AboutUs from '../components/AboutUs/AboutUs';
import Success from '../components/Success/Success';
import JoinUs from '../components/JoinUs/JoinUs';
import VGCC from '../components/VGCC/VGCC';
import AdvertisementLanding from '../components/AdvertisementLanding/AdvertisementLanding';
import AdvertisementLandingNew from '../components/AdvertisementLandingNew/AdvertisementLandingNew';
import Privacy  from '../components/Privacy/Privacy';
import Terms from '../components/Terms/Terms';
import ForPatient from '../components/ForPatient/ForPatient';

/*  Areas Of Medicine */
import BariatricSurgery from '../components/CarePathways/BariatricSurgery';
import Orthopedics from '../components/CarePathways/Orthopedics';
import ColorectalSurgery from '../components/CarePathways/ColorectalSurgery';
import PainManagement from '../components/CarePathways/PainManagement';
import EarNoseThroat from '../components/CarePathways/EarNoseThroat';
import Pulmonology from '../components/CarePathways/Pulmonology';
import Gastroenterology from '../components/CarePathways/Gastroenterology';
import GeneralSurgery from '../components/CarePathways/GeneralSurgery';
import GERD from '../components/CarePathways/GERD';
import OBGYN from '../components/CarePathways/OBGYN';
import SpineSurgery from '../components/CarePathways/SpineSurgery';
import BreastSurgery from '../components/CarePathways/BreastSurgery';
import InternalMedicine from '../components/CarePathways/InternalMedicine';
import ThoracicSurgery from '../components/CarePathways/ThoracicSurgery';
import Oncology from '../components/CarePathways/Oncology';

/* Areas Of Medicine */
import BariatricSurgeryAOM from '../components/Success/BariatricSurgeryAreaOfMedicine';
import OrthopedicsAOM from '../components/Success/OrthopedicsAreaOfMedicine';
import ColorectalSurgeryAOM from '../components/Success/ColorectalSurgeryAreaOfMedicine';
import PainManagementAOM from '../components/Success/PainManagementAreaOfMedicine';
import EarNoseThroatAOM from '../components/Success/EarNoseThroatAreaOfMedicine';
import PulmonologyAOM from '../components/Success/PulmonologyAreaOfMedicine';
import GastroenterologyAOM from '../components/Success/GastroenterologyAreaOfMedicine';
import SpineSurgeryAOM from '../components/Success/SpineSurgeryAreaOfMedicine';
import ThoracicSurgeryAOM from '../components/Success/ThoracicSurgeryAreaOfMedicine';
import OncologyAOM from '../components/Success/OncologyAreaOfMedicine';
import GerdAOM from '../components/Success/GerdAreaOfMedicine';
import OBGYNAAOM from '../components/Success/OBGYNAreaOfMedcine';
import BreastSurgeryAOM from '../components/Success/BreastSurgeryAreaOfMedicine';
import GeneralSurgeryAOM from '../components/Success/GeneralSurgeryAreaOfMedicine';
import InternalMedicineAOM from '../components/Success/InternalMedicineAreaOfMedicine';
import AccountDeletion from '../components/AccountDeletion/AccountDeletion';


const routes = [
    {
        path: "/",
        exact: true,
        component: Home
    },
    {
        path: "/how-it-works",
        exact: true,
        component: HowItWorks,
        title: 'How it Works - DoctorPlan'
    },
    {
        path: "/about-us",
        exact: true,
        component: AboutUs,
        title: 'About Us - DoctorPlan'
    },
    {
        path: "/vgcc",
        exact: true,
        component: VGCC,
        title: 'Vertual Gerd Care Coordinator'
    },
    // {
    //     path: "/areas-of-medicine/:successarea",
    //     exact: true,
    //     component: Success,
    //     title:''
    // },

    {
        path: "/areas-of-medicine/bariatric-surgery",
        exact: true,
        component: BariatricSurgeryAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/colorectal-surgery",
        exact: true,
        component: ColorectalSurgeryAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/pulmonology",
        exact: true,
        component: PulmonologyAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/oncology",
        exact: true,
        component: OncologyAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/spine-surgery",
        exact: true,
        component: SpineSurgeryAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/ear-nose-throat",
        exact: true,
        component: EarNoseThroatAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/orthopedic-surgery",
        exact: true,
        component: OrthopedicsAOM,
        title:''
    },

    {
        path: "/areas-of-medicine/thoracic-surgery",
        exact: true,
        component: ThoracicSurgeryAOM,
        title:''
    },

    {
        path: "/areas-of-medicine/gastroenterology",
        exact: true,
        component: GastroenterologyAOM,
        title:''
    },
    
    {
        path: "/areas-of-medicine/gastroenterology",
        exact: true,
        component: GastroenterologyAOM,
        title:''
    },
    
    {
        path: "/areas-of-medicine/pain-management",
        exact: true,
        component: PainManagementAOM,
        title:''
    },

    {
        path: "/areas-of-medicine/foregut-surgery",
        exact: true,
        component: GerdAOM,
        title:''
    },

    {
        path: "/areas-of-medicine/obstetrics-and-gynecology",
        exact: true,
        component:  OBGYNAAOM,
        title:''
    },

    {
        path: "/areas-of-medicine/breast-surgery",
        exact: true,
        component:  BreastSurgeryAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/general-surgery",
        exact: true,
        component:  GeneralSurgeryAOM,
        title:''
    },
    {
        path: "/areas-of-medicine/internal-medicine",
        exact: true,
        component:  InternalMedicineAOM,
        title:''
    },


    // {
    //     path: "/care-pathways/:carepathways",
    //     exact: true,
    //     component: BariatricSurgery
    // },
    {
        path: "/care-pathways/bariatric-surgery",
        exact: true,
        component: BariatricSurgery
    },
    {
        path: "/care-pathways/orthopedic-sugery",
        exact: true,
        component: Orthopedics
    },
    {
        path: "/care-pathways/colorectal-surgery",
        exact: true,
        component: ColorectalSurgery
    },
    {
        path: "/care-pathways/pain-management",
        exact: true,
        component: PainManagement
    },
    {
        path: "/care-pathways/ear-nose-throat",
        exact: true,
        component: EarNoseThroat
    },
    {
        path: "/care-pathways/pulmonology",
        exact: true,
        component: Pulmonology
    },
    {
        path: "/care-pathways/gastroenterology",
        exact: true,
        component: Gastroenterology
    },
    {
        path: "/care-pathways/general-surgery",
        exact: true,
        component: GeneralSurgery
    },
    {
        path: "/care-pathways/foregut-surgery",
        exact: true,
        component: GERD
    },
    {
        path: "/care-pathways/obstetrics-and-gynecology",
        exact: true,
        component: OBGYN
    },
    {
        path: "/care-pathways/spine-surgery",
        exact: true,
        component: SpineSurgery
    },
    {
        path: "/care-pathways/breast-surgery",
        exact: true,
        component: BreastSurgery
    },
    {
        path: "/care-pathways/internal-medicine",
        exact: true,
        component: InternalMedicine
    },
    {
        path: "/care-pathways/thoracic-surgery",
        exact: true,
        component: ThoracicSurgery
    },
    {
        path: "/care-pathways/oncology",
        exact: true,
        component: Oncology
    },
    // {
    //     path: "/care-pathways/:carepathways",
    //     exact: true,
    //     component: BariatricSurgery
    // },
    {
        path: "/join-us",
        exact: true,
        component: JoinUs,
        title: 'Contact DoctorPlan'
    },
    {
        path: "/for-patient",
        exact: true,
        component: ForPatient,
        title: 'For Patient'
    },
    {
        path:'/privacy',
        exact:true,
        component: Privacy,
        title: 'Privacy Doctorplan'
    },
    {
        path:'/terms',
        exact:true,
        component: Terms,
        title: 'Terms Doctorplan'
    },
    {
        path: "/landing/msk",
        exact: true,
        component: AdvertisementLanding,
        title: 'Advertisement DoctorPlan'
    },
    {
        path: "/landing/msk2",
        exact: true,
        component: AdvertisementLandingNew,
        title: 'Advertisement DoctorPlan'
    },
    {
        path: "/account-deletion-steps",
        exact: true,
        component: AccountDeletion,
        title: 'Account Deletion Steps'
    }
    
    // {
    //     path: "/*",
    //     component: NotFound
    // }
];

export default routes;
