import React, { Component } from 'react';
import { Link }  from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Swiper from 'react-id-swiper';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import PersonalizeJourney from '../common/PersonalizeJourney'
import VideoModal from './VideoModal'
import ImageFade from '../common/ImageFade'
// import Modal from 'react-modal';
import VideoPlayer from 'react-video-js-player';
import "./style.scss";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Header from '../Header/Header'

var parse = require('html-react-parser');

const HomeBanner = {
  'desktop': require('../../img/Banner_Video_desk.mp4'),
  'tablet': require('../../img/landing-image-tablet@3x.png'),
  'mobile': require('../../img/landing-image-mobile@3x.jpg')
}



// import Player from '../common/ReactPlayer'
// import ModalVideo from 'react-modal-videojs'
@inject('homeStore','globalStore')
@observer class Home extends Component {
    constructor(props) {
        super(props);
        let url = "../../img/Aileen@2x.png"
        this.preloadImageUrl(url)
        this.headerColor = "white"
        this.scrollAction =  this.scrollAction.bind(this)
        this.state = {
          open: false,
          open_sec: false,
          bannerData: {},
          heading:'Precision Healthcare ',
          subheading: 'Create deep relationships with patients, using a data-driven approach to personalize care delivery',
          videobutton:'See DoctorPlan in Action',
          doctorDetails: {
            name: 'Dr. Wilson Tsai',
            areaOfMedicine: 'Thoracic Surgeon',
            content: "DoctorPlan has my patients <b>better informed and better prepared</b> - from diagnostic procedures through surgery and recovery. </p>" +
              "<p> My patient visits are <b>10x more productive</b>. I get a complete picture what they’ve experienced, the frequency and severity - so the patient visit time is spent on deeper analysis and discussing options, not on recalling from memory.</p>",
            link:'/areas-of-medicine/thoracic-surgery/',
            doctorImage: require("../../img/wilson-tsai.png"),
          },
          src:"https://cdn.doctorbox.com/video/doctorplan.mp4",
          customUrl:"https://content.doctorplan.com/video/doctorplan_video.mp4",
          scrollPosition:'',
          posterSrc: require('../../img/thumbnail.png'),
          posterTop: require('../../img/topthumbnail.png'),
          windowWidth: window.innerWidth
        }
    }


    /**
     * Handles resizes of windows to set state of window width
     *
     * @memberof Home
     */
    handleResize = (e) => {
      this.setState({ windowWidth: window.innerWidth });
     }

    /**
     * Gets main banner image by window width
     *
     * @memberof Home
     */
    getMainBanner = () => {
      return HomeBanner.desktop
    }
    readMore = () => {
      document.getElementById("readmore").scrollIntoView({behavior: 'smooth'});
    }
    // renderPlayer = () => {
    //   return <Player open={this.state.open} toggleModal={this.onOpenModal} />
    // }
    toggleDoctor = (docname) => {
      
      const newImgKey = this.state.doctorDetails.imgKey + 1
      switch(docname) {
        case 'wilson':
        this.setState({
          doctorDetails: {
            name: 'Dr. Wilson Tsai',
            areaOfMedicine: 'Thoracic Surgeon',
            content: "<p>DoctorPlan has my patients <b>better informed and better prepared</b> - from diagnostic procedures through surgery and recovery. </p>" +
              "<p> My patient visits are <b>10x more productive</b>. I get a complete picture what they’ve experienced, the frequency and severity - so the patient visit time is spent on deeper analysis and discussing options, not on recalling from memory.</p>",
            link:'/areas-of-medicine/thoracic-surgery/',
            doctorImage: require("../../img/wilson-tsai.png"),
            imgKey: newImgKey,
          },
        })
        break
        case 'brian':
          this.setState({ 
            doctorDetails: {
              name: 'Dr. Brian Su',
              areaOfMedicine: 'Spine Surgeon',
              content: "<p>DoctorPlan has helped us operate our practice very efficiently. From the <b>first intake forms, to timely reminders and instructions,</b>"+
              "my patients are guided through their entire diagnostic, treatment, and recovery process.</p>" +
              "<p>I am better prepared for their visits and use the <b> patient data to maintain complete documentation </b>  to support recommended treatment plans. " +
              "The outcomes analysis clearly shows me how they do before and after, helping us improve our quality of care.</p>",
              link:'/areas-of-medicine/spine-surgery/',
              doctorImage: require("../../img/BrianSu@2x.png"),
              imgKey: newImgKey,
            },
           })
        break
        case 'raman':
          this.setState({ 
            doctorDetails: {
              name: 'Dr. Ramana Naidu',
              areaOfMedicine: 'Pain Management',
              content: "<p>With DoctorPlan, I have a clear picture of my patients’ progress – from before the <b> first consultation to post-treatment outcomes.</b> " + 
              "My patients log their pain and associated symptoms, and I can follow up on their treatment effectiveness.</p><p> DoctorPlan helps guide my patients with the <b>right information at the right time,</b>"+
              "so they are better informed about their condition and treatment plan.</p>",
              link:'/areas-of-medicine/pain-management/',
              doctorImage: require("../../img/RamanaNaidu@2x.png"),
              imgKey: newImgKey,
            },
           })
        break
        case 'chris':
        this.setState({ 
          doctorDetails: {
            name: 'Chris Scott',
            areaOfMedicine: 'CEO Spine Colorado',
            content: "<p>DoctorPlan guides patients, automates intake, saves my providers 5-15 minutes per patient visit, and increases customer satisfaction.",
            link:'/areas-of-medicine/oncology/',
            doctorImage: require("../../img/ChrisScott.png")
          },
      
        })
        break
        default: 
        
      }
    }
    componentDidMount() {
      document.title= 'DoctorPlan | Precision Health Management Platform'
      window.addEventListener('scroll', this.scrollAction);
      window.addEventListener("resize", this.handleResize);

      const ImageList = [
        'wilson-tsai',
        'BrianSu@2x',
        'RamanaNaidu@2x',
        'ChrisScott',
      ]
      ImageList.forEach((image) => {
          const img = new Image();
          img.src = require(`../../img/${image}.png`);
      });
    }
    componentWillUnmount() {
      window.removeEventListener('scroll', this.scrollAction);
      window.addEventListener("resize", this.handleResize);
    }
    
    scrollAction = (e) => {
      if (window.pageYOffset < 2) 
        this.headerColor = "white"
      else
        this.headerColor = "#2d3e50"
        this.setState({  scrollPosition: window.pageYOffset },function(){
              if(this.state.scrollPosition > 345){
                this.props.globalStore.showCTA = true;
             }else {
              this.props.globalStore.showCTA = false;
             }
        });
    }

    showModal= () => {
      this.setState({ open:true  })
    }
    closeModal = () => {
      this.setState({ open:false  })
    }
    showModalSec = () => {
      this.setState({ open_sec:true  })
    }
    closeModalSec = () => {
      this.setState({ open_sec:false  })
    }

    preloadImageUrl(url){
      var image = new Image(); 
      image.src = url
   }

    render() {
      const params = {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true
        },
        autoplay: {
          delay: 2500
        },
      }
    return (
    <div onScroll={this.scrollAction}>
    <section class="banner">
      <Header color={this.headerColor}/>
      <div className="container-lg bannerVid"
          dangerouslySetInnerHTML={{
            __html: 
            `<video autoplay loop muted playsinline id="landing_video">
              <source src=${this.getMainBanner()} type="video/mp4"/>
            </video>`
          }}
      />

      <span class="big-text">What would your<br></br>providers do with<br></br>an extra hour a day?</span>
      <span class="small-text">Only digital health solution that improves the entire care<br></br>experience so providers spend their valuable time<br></br>practicing medicine, not on paperwork</span>
      <div class="read-more" id="readmore" onClick={this.readMore}>{(window.innerWidth > 770 && window.innerHeight > 600) ? 'Read More' : ''}<img src={require("../../img/DownArrowWhite.png")} alt=""/></div>
      
    </section>
    <section id="outcome" class="outcome">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-12 padLeftDeliver">
              <h3 class="text-md-center">Providers see increased patient engagement through<br></br>the care journey, improved clinical efficiencies, and<br></br>proof of treatment outcomes
              </h3>
          </div>
        </div>
        <div class="row marginBottom" style={{"justify-content": "space-evenly"}}>
          <div class="col-md-3 text-center card">
            <div class="row justify-content-center">
              <div class="col-md-12 col-sm-12 p-0">
                <img src={require("../../img/patient-interactions@3x.png")} alt=""/>            
              </div>
              <div class="col-md-12 col-sm-12 p-0">
                <div class="number">85%</div>
                <p>patients engaged</p>
                <div class="blue-separator"></div>
                <span class="understatement">Pre-Visit Questionnaires<br></br>Completed</span>
              </div>
            </div>
          </div>
          <div class="col-md-3 text-center card">
              <div class="row justify-content-center">
                  <div class="col-md-12 col-sm-12 p-0">
                      <img src={require("../../img/patient-engagement@3x.png")} alt=""/>
                    </div>
                    <div class="col-md-12 col-sm-12 p-0">
                      <div class="number">5-15 mins</div>
                      <p>saved / visit</p>
                      <div class="blue-separator"></div>
                      <span class="understatement">Personalized Intake,<br></br>Automated Charting</span>
                    </div>
              </div>
          </div>
          <div class="col-md-3 text-center card">
              <div class="row justify-content-center">
                <div class="col-md-12 col-sm-12 p-0  center-text">
                    <img src={require("../../img/average-savings@3x.png")} alt=""/>
                  </div>
                  <div class="col-md-12 col-sm-12 p-0 center-text">
                    <div class="number">70%</div>
                    <p>improvement</p>
                    <div class="blue-separator"></div>
                    <span class="understatement">Documentation for<br></br>Reimbursements</span>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
    <section class="timeline">
      <div class="container-lg">
        <div class="row justify-content-center">
          <div class="col-md-12">
              <h3 class="text-md-center">
                <span>Deliver a seamless patient experience —</span>
                <br></br>
                <span>from 
                  <span style={{ "font-weight": "600", "color": "#3572B9"}}> first interaction </span> 
                  through 
                  <span style={{ "font-weight": "600", "color": "#3572B9"}}> recovery</span>
                </span>
              </h3>
            </div>
        </div>
        <div class="row steps-container justify-content-around">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <span class="step">Before Visit</span>
            <ul>
              <li>Condition Specific Clinical Intake</li>
              <li>Baseline Patient Reported Outcomes</li>
              <li>Medical and Treatment History</li>
              <li>Demographic and Insurance Capture</li>
              <li>Consent Forms, E-Signatures</li>
              <li>Appointment Reminders</li>
              <li>Real Time Sync with EMR</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          <span class="step">During Visit</span>
          <ul>
              <li>In Office Clinical Intake, Medical & Treatment History</li>
              <li>Comprehensive Visual Synopsis for Provider</li>
              <li>Automated Chart Note Generation</li>
              <li>Chart Notes Pushed into EMR</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          <span class="step">After Visit</span>
          <ul>
              <li>Precise Care Instructions / Next Steps</li>
              <li>Treatment Specific Patient Education</li>
              <li>Exercise / Therapy / Mindfulness Lessons</li>
              <li>Patient Satisfaction Survey</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          <span class="step">Between Visits</span>
          <ul>
              <li>Condition Specific Follow Up Questionnaires</li>
              <li>Identify Early Intervention Candidates</li>
              <li>Symptoms and Vitals Tracking</li>
              <li>Guidance for Continuity of Care</li>
              <li>Proactive Passive Patient Outreach</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          <span class="step">Pre Procedure</span>
          <ul>
              <li>Pre-Op Instructions</li>
              <li>Procedure Education</li>
              <li>Procedure Consents</li>
              <li>Surgery Center Intake</li>
              <li>Automated Chart Note Generation</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          <span class="step">Post Procedure</span>
          <ul>
              <li>24-Hr Post-Op Questionnaires</li>
              <li>Post-Op Wound and Progress Check</li>
              <li>Identify Readmission Candidates</li>
              <li>Rehab and Recovery Guidance</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div class="container-lg">
      <section class="trusted-by">
          <h4 class="text-center trusted-by-text">Trusted by </h4>
          <div class="row">
            <div class="col-12 text-center">
              <ul>
                <li style={{"maxWidth":"80px"}}><img src={require("../../img/spine-colorado.png")} alt=""/></li>
                <li style={{"maxWidth":"110px"}}><img src={require("../../img/texas-spine-logo@3x.png")} alt=""/></li>
                <li style={{"maxWidth":"215px"}}><img src={require("../../img/california-ortho-and-spine-logo-new@3x.png")} alt=""/></li>
                <li style={{"maxWidth":"140px"}}><img src={require("../../img/bass-logo-new@3x.png")} alt=""/></li>
                <li style={{"maxWidth":"119px"}}><img src={require("../../img/epiccare-logo-new@3x.png")} alt=""/></li>
              </ul>
            </div>
          </div>      
      </section>
    </div>
    <div className="container-xl"><PersonalizeJourney /></div>
    <div class="container-lg">
      <section class="about">
        <h2 class="text-center fts-42 d-none d-md-block">What do care providers say about DoctorPlan?</h2>
        <div class="row">
          <div class="col-md-12 d-none d-md-block order-1">
            <ul class="timeline no-pad"> 
              <li>
                  <a  onClick={(e)=> this.toggleDoctor('wilson')} id="tb_12" class="tabmenu" className={(this.state.doctorDetails.name === 'Dr. Wilson Tsai') ? 'active' : ''}>
                    <h6>Dr. Wilson Tsai</h6>
                    <p>Thoracic Surgeon</p>
                  </a>
              </li>
              <li>
                  <a  onClick={(e)=> this.toggleDoctor('brian')} id="tb_14" class="tabmenu" className={(this.state.doctorDetails.name === 'Dr. Brian Su') ? 'active' : ''}>
                    <h6>Dr. Brian Su</h6>
                    <p>Spine Surgeon</p>
                  </a>
              </li>
              <li>
                  <a onClick={(e)=> this.toggleDoctor('chris')} id="tb_16" class="tabmenu" className={(this.state.doctorDetails.name === 'Chris Scott') ? 'active' : ''}>
                    <h6>Chris Scott</h6>
                    <p>CEO Spine Colorado</p>
                  </a>
              </li>
              <li>
                  <a onClick={(e)=> this.toggleDoctor('raman')} id="tb_15" class="tabmenu" className={(this.state.doctorDetails.name === 'Dr. Ramana Naidu') ? 'active' : ''}>
                    <h6>Dr. Ramana Naidu</h6>
                    <p>Pain Management</p>
                  </a>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
              <div id="content_12" class="tabcontent">
                  <div class="row d-none justify-content-center d-md-flex">
                      <div class="col-lg-3 col-md-4">
                        <ImageFade key={this.state.doctorDetails.imgKey}  src={this.state.doctorDetails.doctorImage} class="img-fluid" alt=""/>
                        <div class="doc-name d-block d-lg-none mt-3 text-center">
                            <h4 class="mb-0">{this.state.doctorDetails.name}</h4>
                            <p>{this.state.doctorDetails.areaOfMedicine}</p>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-8 homeSectionLast">
                            { 
                                parse (this.state.doctorDetails.content)
                            }
                          <br></br>  
                          <div class="doc-name d-lg-block">
                              <h4 class="mb-0">{this.state.doctorDetails.name}</h4>
                              <p>{this.state.doctorDetails.areaOfMedicine}</p>
                          </div>   
                          <br></br>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-12 d-block d-md-none">
              <Swiper {...params}>
                <div class="swiper-slide">
                    <div class="slide-content">
                        <img src={require("../../img/wilson-tsai.png")} class="img-fluid doc-img" alt=""/>
                        <h6 className="smallDocName">Dr. Wilson Tsai</h6>
                        <p className="smallDocAOM">Thoracic Surgeon</p>
                        <p className="smallDocQuote">“DoctorPlan has my patients better informed and better prepared - from diagnostic procedures through surgery and recovery…”</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-content">
                        <img src={require("../../img/BrianSu.png")} class="img-fluid doc-img" alt=""/>
                        <h6 className="smallDocName">Dr. Brian Su</h6>
                        <p className="smallDocAOM">Spine Surgeon</p>
                        <p className="smallDocQuote">“DoctorPlan has helped us operate our practice very efficiently…”</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-content">
                        <img src={require("../../img/ChrisScott.png")} class="img-fluid doc-img" alt=""/>
                        <h6 className="smallDocName">Chris Scott</h6>
                        <p className="smallDocAOM">CEO Spine Colorado</p>
                        <p className="smallDocQuote">“DoctorPlan guides patients, automates intake, saves my providers 5-15 minutes per patient visit, and increases customer satisfaction.”</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slide-content">
                        <img src={require("../../img/RamanaNaidu.png")} class="img-fluid doc-img" alt=""/>
                        <h6 className="smallDocName">Dr. Ramana Naidu</h6>
                        <p className="smallDocAOM">Pain Management</p>
                        <p className="smallDocQuote">“With DoctorPlan, I have a clear picture of my patients’ progress – from before the irst consultation to…”</p>
                    </div>
                </div>
              </Swiper>
          </div>
        </div>
      </section>
    </div>
    
  </div>
      )
    }
}

export default Home;