import React, { Component } from 'react';
import { Link }  from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Swiper from 'react-id-swiper';
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import IsDoctorPlanForYou from '../common/IsDoctorplanForYou'
// import Modal from 'react-modal';
import VideoPlayer from 'react-video-js-player';
import "./style.scss";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'


@observer class VideoModal extends React.Component {
   
    constructor(props){
        super(props)
        this.modalRef = React.createRef()
        this.state = {
            open_sec: false,
        }
    }

    onPlayerReady(player){
      this.player = player;
      this.player.on('fullscreenchange', () => {
        if(!this.player.isFullscreen())
          this.player.pause();
      })
    }

    showModalSec = (e) => {
        if(window.innerWidth < 576) {
          this.player.play();
          this.player.requestFullscreen();
        }
        else
          this.setState({ open_sec:true  })
      }
      closeModalSec = () => {
        this.setState({ open_sec:false  })
      }
    render(){
        return (
            <div style={{marginTop: '40px'}}>
            <Modal open={this.state.open_sec} onClose={this.closeModalSec} container={this.modalRef.current}>
                         <div className="">
                          <VideoPlayer
                                controls={true}
                                src={this.props.url}
                                data-setup='{"fluid": true}'
                                width="985"
                                height="554"
                                autoplay={true}
                                poster={this.props.poster}
                          /> 
                          </div>
            </Modal>
            <div ref={this.modalRef}></div>
                {(this.props.button === 'alt') ? (
                  <div style={{ "cursor":"pointer"  }} onClick={(e) => {this.showModalSec()}}><a  class="see-plan"><img  src={require("../../img/triangle@2x.png")} alt=""/>See DoctorPlan in Action</a></div>
                ) : (
                  <div class="row healthRow">
                    <div class="col-12 text-center buttonWrapperfold2">
                      <Link href="#" class="blue-btn" onClick={this.showModalSec} >
                        <img class="mr-3" src={require("../../img/triangle@2x.png")} alt="Play" />
                        See DoctorPlan in Action
                      </Link>
                    </div>
                    </div>
                )}
            <div >
                <VideoPlayer
                  className="invisible-player"
                  controls={true}
                  width="0"
                  src={this.props.url}
                  onReady={this.onPlayerReady.bind(this)}
                  poster={this.props.poster}
                />
              </div>
        </div>
        )
    }
}

export default VideoModal;