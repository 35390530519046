import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import "../../assets/styles/grid.css";
import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";

@inject("globalStore")
@observer
class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
     
  };
}

  componentDidMount() {
    document.title='Privacy DoctorPlan'
  }
  

  render() {

    return (
        // <div style={{padding :"0px 10px",textAlign:"justify", fontFamily:"&#39;Helvetica Neue&#39;,Helvetica,Arial,sans-serif", fontSize: "14px"}}>
        <div  className="container" style={{padding :"0px 10px", fontSize: "14px"}}>
        <h3 align="left">DOCTORBOX HEALTH, INC.</h3>
        <h3 align="left">PRIVACY POLICY</h3>
        <p>Last Updated: April 12, 2024</p>
        <p>This Privacy Policy explains how DoctorBox Health, Inc. (“DoctorPlan”, “we” or “us”) collects, uses, and discloses information about you when you access or use our websites, mobile applications, and other online products and services that link to this Privacy Policy (collectively, the “Service”), shop in our stores, contact our customer service team, engage with us on social media, or otherwise interact with us.</p>

        <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Service or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>

        <div class="row">
            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Collection of Information</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p><strong>Information You Provide to Us</strong></p>
                    <p>We collect information you provide directly to us. For example, if you create an account, make a referral, fill out a form, participate in any interactive features of the Service, complete a survey or questionnaire, request customer support, send a message, or otherwise communicate with us or with other users, we may collect information such as your name, contact information (including your email address, postal address, and phone number) and other information you choose to provide. In addition, we may also collect health and wellness information and data that you choose to enter, download or access via the Service, such as information about your medical conditions, associated symptoms, physical activities, vital signs, medications, treatment, diagnostic and other questionnaires, food and fluid intake, and medical appointments.</p>
                    <p>
                        <strong>Protection Disclaimer.</strong> DoctorPlan is not a Covered Entity, as defined under the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), and we do not intend to collect Protected Health Information (“PHI”) as defined by HIPAA.  However, DoctorPlan takes patient privacy and security very seriously. DoctorPlan complies with all of the HIPAA guidelines that apply to business associates (as that term is defined by HIPAA), including data security, encryption, and transmission - when it comes to protecting PHI and electronic PHI.
                    </p>
                    <p>
                        <strong>Automatically Collected Information</strong>
                    </p>
                    <p>
                        When you access or use our Service, we automatically collect information about you, including:
                    </p>
                    <ul>
                        <li>
                            <strong>Log Information:</strong> We collect information related to your access to and use of the Service, including the type of browser you use, app version, access times, pages viewed, your IP address, and the page you visited before navigating to our Service.
                        </li>
                        <li>
                            <strong>Device Information:</strong> We collect information about the computer or mobile device you use to access our Service, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
                        </li>
                        <li>
                            <strong>Third-Party Apps and Devices:</strong> The Service may also ask you to authorize the collection of information through interaction with third-party devices or apps such as mobile phones, fitness trackers (e.g., Fitbit or Apple Watch) mobile health tracking apps (e.g., Apple Health), or medical devices (e.g., blood pressure cuffs or glucometers). This information may include estimates of a variety of metrics such as the number of steps you take, distance traveled, calories burned, vitals (including weight, heart rate, blood pressure, and pulse), sleep stages, and activity level.
                        </li>
                    </ul>
                    <p>
                        <strong>Information We Collect from Other Sources</strong>
                        We may obtain information from other sources and combine that with information we collect directly from you. For example, we may collect information about you from third parties, including other users, and from publicly available sources.
                    </p>
                </div>
            </div>

            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Use of Information</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>
                        We use the information we collect to provide, maintain, support, and improve our Service. We may also use the information we collect to:
                    </p>
                    <ul>
                        <li>
                            Send you technical notices, updates, security alerts, and support and administrative messages and to provide customer service and respond to your comments, questions, and requests;
                        </li>
                        <li>
                            Communicate with you about products, services, offers, and events offered by DoctorPlan and others, and provide news and information we think will be of interest to you (see Your Choices below for information about how to opt out of these communications at any time);
                        </li>
                        <li>
                            Monitor and analyze trends, usage, and activities in connection with our Service;
                        </li>
                        <li>
                            Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of DoctorPlan and others;
                        </li>
                        <li>
                            Personalize the Service and provide content, features, offers, messages, or advertising that match your profile, interests, or activities;
                        </li>
                        <li>
                            Test, develop, train, and improve our Service and our technology; and
                        </li>
                        <li>
                            Carry out any other purpose described to you at the time the information was collected.
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Sharing of Information</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>The Service is intended as a tool to assist users in gathering, recording, organizing, understanding, managing, sharing, and communicating health and wellness information, and in allowing authorized healthcare and wellness professional users to access and use information that an individual user has chosen to share. If you consent and direct the Service to share your healthcare-related information and data with a health care provider, any shared healthcare-related information and data that constitutes PHI under HIPAA will be protected accordingly.</p>

                    <p>We may also share information about you as follows or as otherwise described in this Privacy Policy:</p>
                    <ul>
                        <li>
                            <p>With vendors, service providers, and consultants that perform services for us;</p>
                        </li>
                        <li>
                            <p>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements;</p>
                        </li>
                        <li>
                            <p>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of DoctorPlan or others;</p>
                        </li>
                        <li>
                            <p>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</p>
                        </li>
                        <li>
                            <p>Between and among DoctorPlan and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership; and</p>
                        </li>
                        <li>
                            <p>With your consent or at your direction, and consistent with applicable law, including if you permit the sharing of certain information through the Service with family, friends or other third parties.</p>
                        </li>
                    </ul>
                    <p>If you choose to share information with other users through the Service, such as in a message, discussion board, chat room, public or private group, or other interactive features of the Service, you understand that such information will be available to such other users, in accordance with the settings of such feature, and we are not responsible for the actions of any third parties with whom you share such information.</p>
                    <p>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you</p>
                </div>
            </div>

            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Analytics Services </strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>We may use technology from others to provide analytics services. This may involve the use of cookies, web beacons, device identifiers and other technologies to collect information about your use of the Service and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information. This information may be used, among other things, to analyze and track data, determine the popularity of certain content, provide support, deliver customized content on our Service and other websites, and better understand your online activity.</p>
                </div>
            </div>

            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Data Retention</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>We store the information we collect on you for as long as is necessary for the purpose(s) for which we originally collected it, or for other legitimate business purposes, including to meet our legal, regulatory, or other compliance obligations.</p>
                </div>
            </div>

            <div class="col-md-12 padLeftNone">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase" , margin:"5px 0px",textAlign:"left"}}><strong>Transfer of Information to the U.S. and Other Countries</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>DoctorPlan is based in the United States and we process and store information in the United States. We will take steps to ensure that your personal data receives an adequate level of protection in the jurisdictions in which we process it.</p>
                </div>
            </div>

            <div class="col-md-12 padLeftNone padBottom">
                <div class="col-md-3  padLeftNone" style={{textTransform:"uppercase", margin:"5px 0px"}}><strong>Your Choices</strong></div>
                <div class="col-md-9  padLeftNone">
                    <p>
                        <strong>Account Information</strong>
                    </p>
                    <p>You may update, correct, or delete your information or your account at any time by logging into your account or emailing us at support@doctorplan.com. Note, however, that we may retain certain information as required by law or for legitimate business purposes.</p>
                    <p>
                        <strong>Cookies</strong>
                    </p>
                    <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Service.</p>
                    <p>
                        <strong>Communications</strong>
                    </p>
                    <p>You may opt out of receiving promotional emails from DoctorPlan by following the instructions in those emails or by changing the notification settings in your account. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations or related to your use of the Service.</p>
                    <p>
                        <strong>Mobile Push Notifications/Alerts</strong>
                    </p>
                    <p>With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>
                </div>
            </div>
        </div>
        </div>
    );
  }
}

export default Privacy;
