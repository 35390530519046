import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "../../assets/styles/grid.css";
// import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import Swiper from 'react-id-swiper';
import "./style.scss";

@inject('globalStore')
@observer class PainManagementAreaOfMedicine extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    document.title= "Pain Management - DoctorPlan"
    //   var swiper = new Swiper('.swiper-container', {
    //    slidesPerView: 1,
    //    spaceBetween: 10,
    //    // init: false,
    //    pagination: {
    //      el: '.swiper-pagination',
    //      clickable: true,
    //    },
    //    breakpoints: {
    //      '@0.00': {
    //        slidesPerView: 1,
    //        spaceBetween: 10,
    //      },
    //      '@0.75': {
    //        slidesPerView: 2,
    //        spaceBetween: 20,
    //      },
    //      '@1.00': {
    //        slidesPerView: 3,
    //        spaceBetween: 40,
    //      },
    //      '@1.50': {
    //        slidesPerView: 5,
    //        spaceBetween: 50,
    //      },
    //    }
    //  });
  }



  render() {
    const params = {
      slidesPerView: 5,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      }
    }
    return (
      <div>
        <section className="surgery" style={{ marginTop: "32px"}}> 
          <div className="container-lg">
            <h2><img src={require("../../img/pain-management-icon.svg")} className="img-fluid" alt="" />Pain Management</h2>
          </div>
        </section>
        <section className="testimonial01">
          <div className="container-lg">
            <div className="row align-items-lg-end justify-content-center">
              <div className="col-lg-5 col-md-4 text-center">
                <img src={require("../../img/RamanaNaidu@2x.png")} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 col-md-8">
                <p>“With DoctorPlan, I have a clear picture of my patients’ progress – from before the <span> first consultation to post-treatment outcomes.</span>
                   My patients log their pain and associated symptoms, and I can follow up on their treatment effectiveness. DoctorPlan helps guide my patients with the <span>right information at the right time, </span>
                   so they are better informed about their condition and treatment plan.”
                </p>
                {/* <p>DoctorPlan has helped our practice run so efficiently.
              From the  <span>first intake form</span>,  to  <span>timely reminders</span>  and instructions, my patients are using it to maintain a digital health diary.</p>

                <p>The outcomes analysis clearly shows me how they do before and after, helping us improve our quality of care.</p> */}

                <h3> <span className="docName"> Dr. Ramana Naidu </span> / Intervention Pain Management</h3>
                {/* <a href="#" className="videoPlay"><img src={require("../../img/play-button-blue-see-doctorplan-in-action.png")} alt="" />  Watch Now</a> */}
              </div>
            </div>
          </div>
        </section>

        <div className="care-pathways-link">
          <Link to="/care-pathways/pain-management">Check out Clinical Care Pathways</Link>
        </div>

        <section className="sectionDes container-lg des1">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-5">
                <img src={require("../../img/painManagementImage1@3x.png")} alt="" className="screenShotImages"/>
              </div>
              <div className="col-lg-4 col-md-6">
              <div className="contentWrap">
                  <h4>The Right Information, at the Right Time</h4>
                  <p>Ensure patients are educated and guided along their healthcare journey - from first call to recovery - with easy to understand, bite-sized articles.</p>
                  <p>Provide your patients with stress relieving and calming exercises to help them cope with their symptoms at home. DoctorPlan includes guided mindfulness and yoga practices for patients to perform at their leisure.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des3">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 order-md-1 text-center text-md-right">
                <img src={require("../../img/painManagementImage2@3x.png")} alt=""  className="screenShotImages"/>
              </div>
              <div className="col-md-6 col-lg-4 customBgWhite">
                <div className="contentWrap">
                <h4>Symptoms and Vitals Tracking</h4>
                <p>Patients log symptoms as they experience them – Lower Backpain and Headache - with intelligent follow up questions to document relevant details. Correlate with vitals like Blood Pressure and Temperature, and medications. </p>

                <p>Also included is a sleep diary, mood and stress tracking – to provide a comprehensive picture.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des2">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 text-center text-md-left">
                <img src={require("../../img/painManagementImage3@3x.png")} alt=""  className="screenShotImages"/>
              </div>
              <div className="col-md-6 col-lg-4 customBgWhite">
                <div className="contentWrap">
                <h4>Intake, Follow Up and Outcomes Questionnaires</h4>
                <p>Leverage best practice questionnaires for Pain Condition Intake, Diagnosis and Treatment History, and Employment and Lifestyle History. Patients focus on providing relevant information in the comfort of their home - where they have access to the right information.</p>
                <p>The clinical pathways include standard Patient Reported Outcomes (PRO) measures and Review of Systems. Automated scoring and analysis help improve outcomes.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionDes container-lg des4">
          <div className="">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-5 col-lg-6 text-right  order-md-1">
                <img src={require("../../img/ctc-fs-new-lumbar@3x.png")} alt="" />
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="contentWrap">
                <h4>Synopsis, Correlations, and Automated Charting</h4>
                <p>Doctors access a 1-page synopsis of patient symptoms, vitals, and lifestyle data - for a quick review prior to patient visit. Different aspects of patient data are correlated to provide a complete picture.</p>

                <p> All patient data - symptoms, vitals, questionnaires and communications - are automatically converted into narrative chart notes, uniquely configured for your charting style. Doctors and staff save 5-10 minutes for each patient interaction and ensure documentation compliance.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="care-pathways-link">
          <Link to="/care-pathways/pain-management">Check out Clinical Care Pathways</Link>
        </div>
        <section className="docDes">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8 order-2 order-md-0">
                <div className="row">
                  <div className="col-sm-8 col-md-12 p-0"><h2>Pulmonologist Dr. Karin Cheung on DoctorPlan</h2></div>
                  <div className="col-sm-4 d-md-none"><img src={require("../../img/KarinCheung@2x.png")} className="img-fluid" alt="" /></div>
                </div>
                <p>“Whether it’s Asthma, COPD, or Sleep Disorders, I am able to better understand how my patients are doing between visits.
                   I can follow their progress, monitor issues with CPAP, and be better prepared for follow-up visits.
                   My patients like being able to maintain their health and sleep diaries, leading to improved care and outcomes.”</p>
                <Link to="/areas-of-medicine/pulmonology" className="learn-more-aom">Learn More</Link>
              </div>
              <div className="col-lg-3 col-md-3 d-none d-md-block text-right">
                <img src={require("../../img/KarinCheung@2x.png")} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <div className="container-lg">
        <section className="benefitsPlan">
            <h6>Learn more about how other doctors from different areas of medicines are benefiting from DoctorPlan </h6>
            <div className="swiper-container">
              <div className="swiper-wrapper">
              <Swiper {...params}>
                <div className="swiper-slide ">
                <Link to="/areas-of-medicine/spine-surgery">
                  <img src={require("../../img/BrianSu.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Brian Su</h5>
                  Spine Surgery</Link>
                </div>
                <div className="swiper-slide ">
                <Link to="/areas-of-medicine/thoracic-surgery">
                  <img src={require("../../img/WilsonTsai.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Wilson Tsai</h5>
                  Thoracic Surgery</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/bariatric-surgery">
                  <img src={require("../../img/Aileen.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Aileen Murphy</h5>
                  Bariatric Surgery</Link>
                </div>
               {/* <div className="swiper-slide">
                <Link to="/areas-of-medicine/breast-surgery">
                  <img src={require("../../img/VivianLeTran.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Vivian Le-Tran</h5>
                  Breast Surgery</Link>
                </div> */}
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/colorectal-surgery">
                  <img src={require("../../img/Salvador.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Salvador Guevara</h5>
                  Colorectal</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/ear-nose-throat">
                  <img src={require("../../img/Inez-Wondeh.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Inez Wondeh</h5>
                  BASS Medical CEO</Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/areas-of-medicine/foregut-surgery">
                  <img src={require("../../img/PeterJanu.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Peter Janu</h5>
                  Foregut Surgery</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/gastroenterology">
                  <img src={require("../../img/RishiSharma.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Rishi Sharma</h5>
                  Gastroenterology</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/general-surgery">
                  <img src={require("../../img/JasonMoy.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Jason Moy</h5>
                  General Surgery</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/internal-medicine">
                  <img src={require("../../img/Rebecca.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Rebecca Parish</h5>
                  Internal Medicine</Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/areas-of-medicine/obstetrics-and-gynecology">
                    <img src={require("../../img/PatriciaGeraghty.png")} className="img-fluid sliderImg" alt="" />
                    <h5>Patricia Geraghty</h5>
                    Obstetrics & Gynecology</Link>
                 </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/oncology">
                  <img src={require("../../img/MichaelSherman.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Michael Sherman</h5>
                  Oncology</Link>
                </div>
                <div className="swiper-slide">
                <Link to="/areas-of-medicine/orthopedic-surgery">
                  <img src={require("../../img/BenjaminBusfield.png")} className="img-fluid sliderImg" alt="" />
                  <h5>Dr. Benjamin Busfield</h5>
                  Orthopedic Surgery</Link>
                </div>
                </Swiper>
              </div>
              {/*<!-- Add Pagination -->*/}
              <div className="swiper-pagination"></div>
            </div>
        </section>
        </div>
      </div>


    )
  }
}

export default PainManagementAreaOfMedicine;