import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import "../../assets/styles/grid.css";
// import "../../assets/styles/swiper.css";
import "../../assets/styles/style.css";
import "../../assets/styles/responsive.css";
import Swiper from 'react-id-swiper';
import "./style.scss";
import Header from '../Header/Header'

@inject('globalStore')
@observer class ForPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    document.title= "For Patients"
  }



  render() {
  
    return (
      <div>
        <Header color="#2D3E4F"/>
        <section className="surgery title"> 
          <div className="container-lg">
            <p className="patient-download-title">Received an email or SMS from your doctor? Download the app.</p>
          </div>
        </section>
        <section className="surgery subtitle"> 
          <div className="container-lg">
            <p className="patient-download-subtitle">If you are a patient who received an email or SMS from your doctor asking to download the app, then follow the instructions below.</p>
          </div>
        </section>

        <section className="forpatientblock container-lg ">  {/* forpatientdes4 */}
          <div className="">
            <div className="row blockUserInfo">
              <div className="col-md-5 col-lg-6 text-right  order-md-1 imgwrap">
                <img className="forPatientImg" src={require("../../img/forpatient1@3x.png")} alt="" />
              </div>
              <div className="col-md-6 col-lg-4 appleUserTxt blockWrap">
                <div className="contentWrap userblock">
                <h1>Apple User</h1>
                <p>If you have an iOS device — an iPhone or an iPad, click on the button below to download the app. You can also search for “DoctorPlan” (one word, no space) on the App Store as well.</p>
                <a href="https://apps.apple.com/us/app/doctorbox/id1437144807" target="_blank" ><img src={require("../../img/appleStore@3x.png")} alt="" /> </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="forpatientblock container-lg"> {/* forpatientdes1 */}
          <div className="">
            <div className="row blockUserInfo">
              <div className="col-lg-6 col-md-5 imgwrap" style={{ 'padding-left': '0'}}>
                <img className="forPatientImg" src={require("../../img/forpatient2@3x.png")} alt="" />
              </div>
              <div className="col-lg-4 col-md-6 blockWrap">
                  <div className="contentWrap userblock">
                    <h1>Android User</h1>
                    <p>If you have an android device — Google Pixel, Samsung Galaxy, OnePlus, etc., click on the button below. You can also search for “DoctorPlan” (one word, no space) on the Google Play Store as well.</p>
                    <a href="https://play.google.com/store/apps/details?id=com.doctorbox.patient" target="_blank"><img src={require("../../img/androidStore@3x.png")} alt="" /></a>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <section className="surgery"> 
          <div className="container-lg assistance">
          <p>For any further assistance, reach out at
            <b> <a href = "mailto: support@doctorplan.com">support@doctorplan.com</a></b>
          </p>
          </div>
        </section>

        <div className="container-lg">
       </div>
      </div>


    )
  }
}

export default ForPatient;