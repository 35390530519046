import React, { Component } from 'react';
import {Spring} from 'react-spring/renderprops'

class ImageFade extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         }
    }


    render() { 
        return (
            <Spring
                config={{delay: this.props.delay ? this.props.delay  : 0, clamp: true, friction: 30}}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props =>  <img  style={props} src={this.props.src} alt={this.props.alt} />}
            </Spring>
         );
    }
}
 
export default ImageFade;