import { observable, action } from "mobx";
import datafetcher from '../Fetchers/DataFetcher'

class HomeStore {
    
    constructor(){
    }

    @observable  bannerImageText = {}

    @action
    fetchBannerText(){
         this.bannerImageText = {
         heading:'Intelligent Healthcare ',
         subheading: 'Create deep relationships with patients, between visits',
         videobutton:'See DoctorPlan in action'
        }
         return this.bannerImageText;
    }

}

export default HomeStore;